<ng-container [formGroup]="item">
  <td class="cell action">
    <span class="action-panel">
      <span style="dispaly: inline-block; width: 18px; height: 18px;"></span>
      <button mat-icon-button type="button"
        matTooltip="Insert Below"
        (click)="toggleInlet(InletType.BELOW)">
        <mat-icon class="inlet step-over" svgIcon="step_over"
          [ngClass]="inletOpen(InletType.BELOW) ? 'insert-at-active' : 'insert-at'"></mat-icon>
      </button>
      <button mat-icon-button type="button"
        (click)="toggleInlet(InletType.ABOVE)"
        matTooltip="Insert Above">
        <mat-icon class="inlet step-out" svgIcon="step_out"
          [ngClass]="inletOpen(InletType.ABOVE) ? 'insert-at-active' : 'insert-at'"></mat-icon>
      </button>
    </span>
    <span class="action-panel line-number">
      <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
      <span class="placeholder"></span>
      <span class="number">{{ item.value.item_line_number }}</span>
    </span>
    <span class="action-panel">
      <span class="placeholder"></span>
      <span class="placeholder"></span>
      <button mat-icon-button type="button"
        (click)="deleteItemAt()">
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </td>
  <td class="cell description" colspan="4">
    <span *ngIf="!errorState">Nett Price for {{ strParts() }}: {{ total() }}</span>
    <span *ngIf="errorState" style="display: flex; align-items: center; gap: 0.5em; font-weight: bold;" class="error">
      <mat-icon>error</mat-icon>Error: invalid placement - no parts</span>
  </td>
  <td class="cell right-align total"></td>
  <td class="cell right-align total"></td>
</ng-container>
