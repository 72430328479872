export interface DataCollationEvent {
  collation: string;
}

export interface ResetCollationEvent extends DataCollationEvent {
  reset: boolean;
}

export interface Filter {
  name: string;
  value: string;
}

export interface Sorter {
  name: string;
  order: SortOrder;
}

export interface FilterCollectionItem {
  name: string;
  value: string[];
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
  OFF = 'off'
}
