import { CommonModule } from '@angular/common';
import { Component, forwardRef, OnInit, output } from '@angular/core';
import { PriceAdjustmentType, PriceAdjustmentTypeValueChangeEvent } from '../../plm/quotes/shared/interfaces';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-price-adjustment-type-button',
  standalone: true,
  imports: [
    CommonModule
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PriceAdjustmentTypeButtonComponent),
    multi: true
  }],
  templateUrl: './price-adjustment-type-button.component.html',
  styleUrl: './price-adjustment-type-button.component.scss'
})
export class PriceAdjustmentTypeButtonComponent implements OnInit, ControlValueAccessor {
  get lump_sum(): boolean {
    return this.price_adjustment_type == PriceAdjustmentType.FIXED;
  }
  valueChanged = output<PriceAdjustmentTypeValueChangeEvent>();
  price_adjustment_type: PriceAdjustmentType = PriceAdjustmentType.PERCENTAGE;

  protected _onChange: (obj: any) => void = (_: any) => {};
  protected _onTouched: () => void = () => {};


  ngOnInit(): void {
    this.onChange();
  }

  toggle() {
    if (this.price_adjustment_type == PriceAdjustmentType.PERCENTAGE) {
      this.price_adjustment_type = PriceAdjustmentType.FIXED;
    } else {
      this.price_adjustment_type = PriceAdjustmentType.PERCENTAGE;
    }

    if (!this.lump_sum) this.price_adjustment_type = PriceAdjustmentType.PERCENTAGE;

    this.onChange();
  }

  onChange() {
    this.valueChanged.emit({
      priceAdjustmentType: this.price_adjustment_type
    });
    this._onChange(this.price_adjustment_type);
  }

  writeValue(adjustment_type: PriceAdjustmentType): void {
    this.price_adjustment_type = adjustment_type;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
