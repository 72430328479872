<div class="standard-editor-grid-container">
<app-quote-header></app-quote-header>
<form [formGroup]="form_quotetemplate" class="standard-editor-grid" (ngSubmit)="save_template()">
  <div class="sticky-column">
    <div id="template_details">
      <div class="section-header">
        <h4>Template Details</h4>
      </div>
      <mat-form-field class="full-width-form-control" appearance="outline">
        <mat-label>Template Name</mat-label>
        <input matInput type="text"
          formControlName="template_name" />
      </mat-form-field>
      <mat-form-field class="full-width-form-control" appearance="outline">
        <mat-label>Select Category</mat-label>
        <app-select-document-category formControlName="document_type" [required]="true" />
      </mat-form-field>
    </div>

    <div id="section-list">
      <div class="section-header">
        <h4>Sections</h4>
      </div>
      <div class="section-list">
        <div class="section-container">
          @for (section of sections; track section.section_template_id) {
            <div class="export-section">
              <h4>{{ section.section_name }}</h4>
              <button mat-icon-button type="button"
                (click)="add_section_template(section.section_template_id)">
                <mat-icon>add</mat-icon></button>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

  <div class="editor-pane">
    <div class="section-header">
      <h4>{{ title }}</h4>
      <button mat-icon-button>
        <mat-icon>save</mat-icon>
      </button>
    </div>
    <div class="template-sections">
      @for (template_section of farray_sections.controls; track template_section.controls.section_template_id; let idx = $index ) {
        <div class="template-section">
          <mat-icon>drag_indicator</mat-icon>
          <button mat-icon-button
                  type="button"
                  (click)="delete(idx)">
            <mat-icon>delete</mat-icon>
          </button>
          <h4 style="flex-grow: 1;">{{ template_section.controls.section_number.value }}. {{ template_section.controls.section_name.value }}</h4>
        </div>
      }
    </div>
  </div>
</form>
</div>
