<div id="configurator">
  <div id="toolbar" *ngIf="showToolbar">
    <div class="section-header toolbar">
      <button mat-icon-button
        (click)="selectPart($event, makeFinishedGood())"
        [disabled]="toolbarButtonDisabledState.finished_good"
        matTooltip="Finished Good">
        <mat-icon class="material-symbols-outlined">manufacturing</mat-icon>
      </button>
      <button mat-icon-button
        (click)="selectPart($event, makeNettPrice())"
        [disabled]="toolbarButtonDisabledState.nett_price"
        matTooltip="Nett Price">
        <mat-icon class="material-symbols-outlined">payments</mat-icon>
      </button>
      <button mat-icon-button
        [disabled]="toolbarButtonDisabledState.paste"
        matTooltip="Paste">
        <mat-icon>content_paste</mat-icon>
      </button>
    </div>
  </div>

  <div class="aggregate-section" *ngFor="let agg of activeAggregates">
    <h4 class="section-header">{{ agg.type }}</h4>
    <div class="configurator-aggregate">
      <mat-slide-toggle
          [disabled]="!agg.isActive"
          [checked]="true"
          (change)="toggleAggEdit($event)">
        <em>Editting: {{ getAggregateDescription(agg.internal_id) }}</em>
      </mat-slide-toggle>
    </div>
  </div>

  <div id="part-configuration" *ngIf="showPartSelector">
    <div class="section-header section-header-with-action">
      <h4>Part Selector</h4>
      <button mat-icon-button
        type="button" (click)="clearall()">
        <mat-icon>cleaning_services</mat-icon>
      </button>
    </div>
    <div id="search-console">
      <form>
        <div class="input-container console-row">
          <div class="console-row-item search-input">
            <input type="text" [(ngModel)]="searchQuery" name="search_query" (keyup.enter)="search()"/>
          </div>
          <div>
            <button type="button" (click)="search()">Search</button>
          </div>
        </div>

        <div id="facets" class="console-row">
          <div id="group" class="console-row-item">
            <select [(ngModel)]="filterGroup" (change)="filterByGroup()" name="part_group">
              <option value="" selected="selected">Select Group ...</option>
              @for (p of partGroups().sort(); track p) {
               <option value="{{ p }}">{{ p }}</option>
              }
            </select>
          </div>

          <div id="manufacturer" class="console-row-item">
            <select [(ngModel)]="filterManufacturer" (change)="filterByManufacturer()" name="part_manufacturer">
              <option value="" selected="selected">Manufacturer</option>
              @for (p of partManufacturers().sort(); track p) {
               <option value="{{ p }}">{{ p }}</option>
              }
            </select>
          </div>
        </div>

        <div id="filter-row" class="console-row" *ngIf="showPartFilter">
          <div id="filters">
            <div class="search-filter">
              <span id="all" selected class="filter-text">All</span>
            </div>
            <div class="search-filter">
              <span id="parts" class="filter-text">Parts</span>
            </div>
            <div class="search-filter">
              <span id="enclosures" class="filter-text">Enclosures</span>
            </div>
            <div class="search-filter">
              <span id="composites" class="filter-text">Composites</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <cdk-virtual-scroll-viewport itemSize="100"
      minBufferPx="500"
      maxBufferPx="900"
      id="part-selector" *ngIf="!fetching">
      <div class="part" *cdkVirtualFor="let part of viewportParts()">
        <div class="header">
          <span class="action" title="Add Part">
            <button (click)="selectPart($event, part)"
                  class="add"
                  [disabled]="!isPartActive(part)">+</button>
          </span>
          <h5 class="part-ref">
            <span *ngIf="part.part_is_enclosure">[E]&nbsp;</span>
            <span *ngIf="(part.type == 'composite')">[C]&nbsp;</span>
            {{ part.ref }}</h5>
          <div class="markup-selection" title="Markup">
            <span *ngFor="let markup of part.markups"
              class="markup-box"
              [ngClass]="part.selectedMarkup == (1+markup/100) ? 'active' : ''"
              (click)="updateSelectedMarkup(part, markup)">
              {{ markup }}
            </span>
          </div>
        </div>
        <span class="description">{{ part.description}}</span>
      </div>
    </cdk-virtual-scroll-viewport>
    <div class="part" *ngIf="fetching">
      <mat-spinner></mat-spinner>
      <p>Fetching data...</p>
    </div>
  </div>
</div>
