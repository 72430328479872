import { Component, inject, OnInit } from '@angular/core';
import { PartHeaderComponent } from '../../part-header/part-header.component';
import { PartService } from '../../shared/part.service';
import { Observable, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IConfigurationCategory } from '../shared/models';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-part-configuration-list',
  standalone: true,
  imports: [
    CommonModule,
    PartHeaderComponent,
    MatButtonModule,
    MatIconModule,
    RouterModule
  ],
  templateUrl: './part-configuration-list.component.html',
  styleUrl: './part-configuration-list.component.scss'
})
export class PartConfigurationListComponent {
  service: PartService = inject(PartService);
  _configurations: Observable<IConfigurationCategory[]>
    = this.service.getConfigurations();

  get configurationCategories(): Observable<IConfigurationCategory[]> {
    return this._configurations;
  }
}
