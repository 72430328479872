import { Component, inject, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { QuoteHeaderComponent } from '../../../quote-header/quote-header.component';
import { CommonModule } from '@angular/common';
import { make_document_template_section, make_document_template_section_formgroup, make_document_template_formgroup } from '../../factories';
import { IDocumentSectionTemplate, IDocumentSectionTemplateFormGroup, IDocumentTemplate, IDocumentTemplateFormGroup, IDocumentTemplateSectionFormGroup } from '../../interfaces';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TemplateService } from '../../template.service';
import { SelectDocumentCategoryComponent } from '../../../../../controls/select-document-category/select-document-category.component';

@Component({
  selector: 'app-quote-template-edit',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    QuoteHeaderComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SelectDocumentCategoryComponent
  ],
  templateUrl: './quote-template-edit.component.html',
  styleUrl: './quote-template-edit.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class QuoteTemplateEditComponent implements OnInit {
  service: TemplateService = inject(TemplateService);

  sections: Partial<IDocumentSectionTemplate>[] = [];
  form_quotetemplate: FormGroup<IDocumentTemplateFormGroup> =
    make_document_template_formgroup();

  get document_template_id(): string {
    return this.form_quotetemplate.controls.document_template_id.value;
  }

  set document_template_id(id: string) {
    this.form_quotetemplate.controls.document_template_id.setValue(id);
  }

  get farray_sections(): FormArray<FormGroup<IDocumentTemplateSectionFormGroup>> {
    return this.form_quotetemplate.controls.sections;
  }

  title: string = 'Document Template';

  constructor(private route: ActivatedRoute) {
    let id = route.snapshot.params['id'];
    this.document_template_id = id;
  }

  ngOnInit(): void {
    this.form_quotetemplate.controls.template_name.valueChanges.subscribe(name => {
      this.title = name;
    });

    this.service.loadSectionTemplates().subscribe(sections => {
      this.sections = sections;

      if (this.document_template_id) {
        this.load_by_id(this.document_template_id);
      }
    });
  }

  load_by_id(template_id: string) {
    this.service.load_document_template(template_id).subscribe(template => {
      this.fg_docuemnt_template(template);
    });
  }

  fg_docuemnt_template(template: IDocumentTemplate) {
    this.form_quotetemplate = make_document_template_formgroup(template);

    for (let section of template.sections) {
      this.add_section_template(section.section_template_id,
                               section.document_template_section_id || '');
    }
  }

  add_section_template(section_id: string|undefined,
    document_template_section_id: string|undefined = undefined) {
    let section = this.sections.find(x => {
      return x.section_template_id == section_id;
    });

    let section_template = make_document_template_section(section!);
    if (section) {
      section_template.document_template_id = this.document_template_id;
      section_template.document_template_section_id = document_template_section_id || '';
    }

    let fg = make_document_template_section_formgroup(section_template);

    this.farray_sections.push(fg);
    this.reorder();
  }

  reorder() {
    let section_number = 1;

    for (let control of this.farray_sections.controls) {
      control.controls.section_number.setValue(section_number++);
    }
  }

  delete(idx: number) {
    this.farray_sections.removeAt(idx);
    this.reorder();
  }

  save_template() {
    if (this.form_quotetemplate.valid) {
      this.service.edit_document_template(this.form_quotetemplate.value).subscribe(template => {
          this.fg_docuemnt_template(template);
      });
    }
  }
}
