import { Component, inject, OnInit } from '@angular/core';
import { QuoteHeaderComponent } from '../../../quote-header/quote-header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TemplateService } from '../../template.service';
import { IDocumentSectionTemplate } from '../../interfaces';

@Component({
  selector: 'app-section-template-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    QuoteHeaderComponent,
    MatIconModule
  ],
  templateUrl: './section-template-list.component.html',
  styleUrl: './section-template-list.component.scss'
})
export class SectionTemplateListComponent implements OnInit {
  service: TemplateService = inject(TemplateService);

  templates: Partial<IDocumentSectionTemplate>[] = [];

  ngOnInit() {
    this.service.loadSectionTemplates().subscribe(templates => {
      this.templates = templates;
    });
  }

  editLink(id: string|undefined): string {
    if (id) {
      return `/quotes/templates/section-templates/${id}/edit`;
    }

    return '';
  }
}
