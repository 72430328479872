import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, FormsModule, NgControl, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { UserService } from '../../core/user.service';
import { UserGroup } from '../../users/shared/models';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Subject } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

let nextUniqueId = 0;

/**
 * @TODO: Integrate MatFormFieldControl with submit
 *        event so it marked as touched [2].
 */
@Component({
  selector: 'app-select-user-group',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  providers: [
    {provide: MatFormFieldControl, useExisting: SelectUserGroupComponent}
  ],
  templateUrl: './select-user-group.component.html',
  styleUrl: './select-user-group.component.scss'
})
export class SelectUserGroupComponent
  implements
  OnDestroy,
  OnInit,
  ControlValueAccessor,
  MatFormFieldControl<any>
{
  service: UserService = inject(UserService);

  @ViewChild('userGroupSelect', {static: true})
  userGroupSelect!: MatSelect;

  @ViewChild('userGroupSelect', {read: ElementRef, static: true})
  select!: ElementRef;

  private _uid = `user-group-select-${nextUniqueId++}`;

  _value: string | null = "";
  set value(group: string | null) {
    this.ngControl.control?.setValue(group);
    this.stateChanges.next();
  }

  get value(): string | null {
    return this.ngControl.control?.value;
  }

  readonly stateChanges = new Subject<void>();

  @HostBinding() id = `user-group-select-${nextUniqueId++}`;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  focused: boolean = false;

  empty: boolean = true;

  get shouldLabelFloat(): boolean {
    if (this.ngControl.control && this.ngControl.valid) {
      return true;
    }

    return false;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required: boolean = false;

  disabled: boolean = false;

  get errorState(): boolean {
    var isErrorState: boolean = false;
    if (this.ngControl.control) {
      isErrorState = this.ngControl.control?.touched && this.ngControl.control?.invalid;
    }

    this.userGroupSelect.errorState = isErrorState;
    this.stateChanges.next();
    return isErrorState;
  }

  readonly disableAutomaticLabeling?: boolean | undefined = true;

  @Input('aria-describedby') userAriaDescribedBy?: string | undefined;

  setDescribedByIds(ids: string[]): void {}

  onContainerClick(event: MouseEvent): void {
      this.focusMonitor.focusVia(this.select, 'program');
  }

  _valueId = `user-group-select-value-${nextUniqueId++}`;

  controlType?: string | undefined = 'app-select-user-group';

  @Input() matcher!: ErrorStateMatcher;

  @Input() label: string = '';

  userGroups: UserGroup[] = [];

  constructor(
    private focusMonitor: FocusMonitor,
    private defaultErrorStateMAtcher: ErrorStateMatcher,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    @Optional() @Inject(MAT_FORM_FIELD) protected _paretnFormField: MatFormField,
    @Self() @Optional() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.service.getUserGroups().subscribe((groups) => {
      this.userGroups = groups;
    });

    this.userGroupSelect.selectionChange.subscribe((val: any) => {
      this.onTouched();
      this.onChange(val.value);
    });

    this.focusMonitor.monitor(this.select).subscribe(focused => {
      this.focused = !focused;
      this.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.select);
    this.stateChanges.complete();
  }

  onChange: (newvalue: string) => void = (value) => {};
  onTouched: () => void = () => {};

  writeValue(obj: any): void {
      this.userGroupSelect.writeValue(obj);
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }
}
