import { FormArray, FormControl, FormGroup } from "@angular/forms";

export interface IManufacturer {
  manufacturer_id: string;
  manufacturer_name: string;
}

export interface IPartGroup {
  group_name: string;
}

export interface IAccessory {
  accessory_id: string;
  part_id: string;
  accessory_ref: string;
  accessory_description: string;
}

export interface IMarkup {
  part_id: string;
  markup_number: number;
  markup: number;
}

export interface IPartNote {
  part_note_id: string;
  part_id: string;
  note: string;
}

export interface ISupplier {
  supplier_id: string;
  supplier_name: string;
}

export interface IPartSupplier {
  part_id: string;
  supplier_number: number;
  supplier_id: string;
}

export interface IPart {
  part_id?: string;
  part_ref: string;
  part_order_ref: string;
  part_description: string;
  part_price: number;
  part_is_panel_mount: boolean;
  part_is_enclosure: boolean;
  part_group_ref: string;
  part_manufacturer_id: string;
  part_manufacturer_ref: string;
  accessories: IAccessory[];
  markups: IMarkup[];
  notes: IPartNote[];
  suppliers: IPartSupplier[];
  configurations: IPartConfiguration[];
  part_last_modified: string;
}

export interface IPartConfiguration {
  category_name: string;
  category_description: string;
  configuration: IPartConfigurationItem[];
}

export interface IPartConfigurationItem {
  sequence_id?: string;
  part_id?: string;
  configuration_item_id: string;
  configuration_item_name: string;
  configuration_item_description: string;
  default_quantity: number;
  is_applicable: boolean;
};

export interface IPartConfigurationFormGroup {
  category_name: FormControl<string>;
  category_description: FormControl<string>;
  configuration: FormArray<FormGroup<IPartConfigurationItemFormGroup>>;
}

export interface IPartConfigurationItemFormGroup {
  sequence_id: FormControl<string>;
  configuration_item_name: FormControl<string>;
  configuration_item_description: FormControl<string>;
  configuration_item_id: FormControl<string>;
  default_quantity: FormControl<number>;
  is_applicable: FormControl<boolean>;
}

export interface IPartAccessoryFormGroup {
  accessory_id: FormControl<string>;
  part_id: FormControl<string>;
  accessory_ref: FormControl<string>;
  accessory_description: FormControl<string>;
}

export interface IPartMarkupFormGroup {
  part_id: FormControl<string>;
  markup_number: FormControl<number>;
  markup: FormControl<number>;
}

export interface IPartNoteFormGroup {
  part_note_id: FormControl<string>;
  part_id: FormControl<string>;
  note: FormControl<string>;
}

export interface IPartSupplierFormGroup {
  part_id: FormControl<string>;
  supplier_number: FormControl<number>;
  supplier_id: FormControl<string>;
}

export interface IPartFormGroup {
  part_id?: FormControl<string>;
  part_ref: FormControl<string>;
  part_order_ref: FormControl<string>;
  part_description: FormControl<string>;
  part_price: FormControl<number>;
  part_is_panel_mount: FormControl<boolean>;
  part_is_enclosure: FormControl<boolean>;
  part_group_ref: FormControl<string>;
  part_manufacturer_id: FormControl<string>;
  part_manufacturer_ref: FormControl<string>;
  accessories: FormArray<FormGroup<IPartAccessoryFormGroup>>;
  markups: FormArray<FormGroup<IPartMarkupFormGroup>>;
  notes: FormArray<FormGroup<IPartNoteFormGroup>>;
  suppliers: FormArray<FormGroup<IPartSupplierFormGroup>>;
  configurations: FormArray<FormGroup<IPartConfigurationFormGroup>>;
}

export interface IComposite {
  composite_id?: string;
  composite_reference: string;
  composite_description: string;
  composite_parts: ICompositePart[];
  composite_last_modified: string;
}

export interface ICompositePart {
  subassembly_sequence_id: string;
  composite_id: string;
  part_id: string;
  quantity: number;
  position: number;
  description?: string;
}

export interface ICompositeFormGroup {
  composite_id: FormControl<string>;
  composite_reference: FormControl<string>;
  composite_description: FormControl<string>;
  composite_parts: FormArray<FormGroup<ICompositePartFormGroup>>
}

export interface ICompositePartFormGroup {
  subassembly_sequence_id: FormControl<string>;
  composite_id: FormControl<string>;
  part_id: FormControl<string>;
  quantity: FormControl<number>;
  position: FormControl<number>;
}

export class Part {
  part_id: string;
  _formgroup: FormGroup<IPartFormGroup>;

  get markups(): FormArray<FormGroup<IPartMarkupFormGroup>> {
    return this._formgroup.controls.markups;
  }

  get accessories(): FormArray<FormGroup<IPartAccessoryFormGroup>> {
    return this._formgroup.controls.accessories;
  }

  get notes(): FormArray<FormGroup<IPartNoteFormGroup>> {
    return this._formgroup.controls.notes;
  }

  get suppliers(): FormArray<FormGroup<IPartSupplierFormGroup>> {
    return this._formgroup.controls.suppliers;
  }

  get configurations(): FormArray<FormGroup<IPartConfigurationFormGroup>> {
    return this._formgroup.controls.configurations;
  }

  constructor(part: IPart | undefined = undefined) {
    this.part_id = part?.part_id || '';

    this._formgroup = new FormGroup<IPartFormGroup>({
      part_id: new FormControl(part?.part_id || '', {nonNullable: true}),
      part_ref: new FormControl(part?.part_ref || '', {nonNullable: true}),
      part_order_ref: new FormControl(part?.part_order_ref || '', {nonNullable: true}),
      part_description: new FormControl(part?.part_description || '', {nonNullable: true}),
      part_price: new FormControl(part?.part_price || 0, {nonNullable: true}),
      part_is_panel_mount: new FormControl(part?.part_is_panel_mount || false, {nonNullable: true}),
      part_is_enclosure: new FormControl(part?.part_is_enclosure || false, {nonNullable: true}),
      part_group_ref: new FormControl(part?.part_group_ref || '', {nonNullable: true}),
      part_manufacturer_id: new FormControl(part?.part_manufacturer_id || '', {nonNullable: true}),
      part_manufacturer_ref: new FormControl(part?.part_manufacturer_ref || '', {nonNullable: true}),
      markups: new FormArray<FormGroup<IPartMarkupFormGroup>>([]),
      accessories: new FormArray<FormGroup<IPartAccessoryFormGroup>>([]),
      suppliers: new FormArray<FormGroup<IPartSupplierFormGroup>>([]),
      notes: new FormArray<FormGroup<IPartNoteFormGroup>>([]),
      configurations: new FormArray<FormGroup<IPartConfigurationFormGroup>>([])
    });

    if (part?.markups && part?.markups.length) {
      for (let markup of part.markups) {
        this.addMarkup(markup);
      }
    }

    if (part?.accessories && part?.accessories.length) {
      for (let accessory of part.accessories) {
        this.addAccessory(accessory);
      }
    }

    if (part?.notes && part?.notes.length) {
      for (let note of part.notes) {
        this.addNote(note);
      }
    }

    if (part?.suppliers && part?.suppliers.length) {
      for (let supplier of part.suppliers) {
        this.addSupplier(supplier);
      }
    }

    if (part?.configurations) {
      for (let configuration of part.configurations) {
        this.addConfiguration(configuration);
      }
    }
  }

  addMarkup(markup: IMarkup | undefined = undefined): void {
    this.markups.push(new PartMarkup(markup).formGroup);
  }

  addAccessory(accessory: IAccessory | undefined = undefined): void {
    this.accessories.push(new PartAccessory(accessory).formGroup);
  }

  addNote(note: IPartNote | undefined = undefined): void {
    this.notes.push(new PartNote(note).formGroup);
  }

  addSupplier(supplier: IPartSupplier | undefined = undefined):void {
    this.suppliers.push(new PartSupplier(supplier).formGroup);
  }

  addConfiguration(configuration: IPartConfiguration | undefined = undefined): void {
    this.configurations.push(new PartConfiguration(configuration).formGroup);
  }

  get formGroup(): FormGroup<IPartFormGroup> {
    return this._formgroup;
  }
}

export class PartMarkup implements IMarkup {
  part_id: string = '';
  markup_number: number = 0;
  markup: number = 0;

  constructor(markup: IMarkup | undefined = undefined) {
    this.part_id = markup?.part_id || '';
    this.markup_number = markup?.markup_number || 1;
    this.markup = markup?.markup || 0;
  }

  get formGroup(): FormGroup<IPartMarkupFormGroup> {
    return new FormGroup({
      'part_id': new FormControl(this.part_id, {nonNullable: true}),
      'markup_number': new FormControl<number>(this.markup_number, {nonNullable: true}),
      'markup': new FormControl<number>(this.markup, {nonNullable: true})
    });
  }
}

export class PartNote implements IPartNote {
  part_note_id: string = '';
  part_id: string = '';
  note: string = '';

  constructor(note: IPartNote | undefined = undefined) {
    this.part_note_id = note?.part_note_id || '';
    this.part_id = note?.part_id || '';
    this.note = note?.note || '';
  }

  get formGroup(): FormGroup<IPartNoteFormGroup> {
    return new FormGroup({
      part_note_id: new FormControl(this.part_note_id, {nonNullable: true}),
      part_id: new FormControl(this.part_id, {nonNullable: true}),
      note: new FormControl(this.note, {nonNullable: true})
    });
  }
}

export class PartAccessory implements IAccessory {
  accessory_id: string = '';
  part_id: string = '';
  accessory_ref: string = '';
  accessory_description: string = '';

  constructor(accessory: IAccessory | undefined = undefined) {
    this.accessory_id = accessory?.accessory_id || '';
    this.part_id = accessory?.part_id || '';
    this.accessory_ref = accessory?.accessory_ref || '';
    this.accessory_description = accessory?.accessory_description || '';
  }

  get formGroup(): FormGroup<IPartAccessoryFormGroup> {
    return new FormGroup({
      'accessory_id': new FormControl(this.accessory_id || '', {nonNullable: true}),
      'part_id': new FormControl(this.part_id || '', {nonNullable: true}),
      'accessory_ref': new FormControl(this.accessory_ref || '', {nonNullable: true}),
      'accessory_description': new FormControl(this.accessory_description || '', {nonNullable: true})
    });
  }
}

export class PartSupplier implements IPartSupplier {
  part_id: string = '';
  supplier_number: number = 1;
  supplier_id: string = '';

  constructor(partSupplier: IPartSupplier | undefined = undefined) {
    this.part_id = partSupplier?.part_id || '';
    this.supplier_number = partSupplier?.supplier_number || 1;
    this.supplier_id = partSupplier?.supplier_id || '';
  }

  get formGroup(): FormGroup<IPartSupplierFormGroup> {
    return new FormGroup({
      part_id: new FormControl(this.part_id, {nonNullable: true}),
      supplier_number: new FormControl(this.supplier_number, {nonNullable: true}),
      supplier_id: new FormControl(this.supplier_id, {nonNullable: true})
    });
  }
}

export class PartConfiguration {
  private _formGroup: FormGroup<IPartConfigurationFormGroup>;

  constructor(configuration?: Partial<IPartConfiguration>) {
    this._formGroup = new FormGroup<IPartConfigurationFormGroup>({
      'category_name': new FormControl<string>(configuration?.category_name || '', {nonNullable: true}),
      'category_description': new FormControl<string>(configuration?.category_description || '', {nonNullable: true}),
      'configuration': new FormArray<FormGroup<IPartConfigurationItemFormGroup>>([])
    });

    if (configuration?.configuration) {
      for (let item of configuration.configuration) {
        let p = new PartConfigurationItem(item);
        this._formGroup.controls.configuration.push(p.formGroup);
      }
    }
  }

  get formGroup(): FormGroup<IPartConfigurationFormGroup> {
    return this._formGroup;
  }
}

export class PartConfigurationItem {
  private _formGroup: FormGroup<IPartConfigurationItemFormGroup>;

  constructor(configuration?: IPartConfigurationItem | undefined) {
    this._formGroup = new FormGroup<IPartConfigurationItemFormGroup>({
      'sequence_id': new FormControl<string>(configuration?.sequence_id || '', {nonNullable: true}),
      'configuration_item_name': new FormControl<string>(configuration?.configuration_item_name || '', {nonNullable: true}),
      'configuration_item_description': new FormControl<string>(configuration?.configuration_item_description || '', {nonNullable: true}),
      'configuration_item_id': new FormControl<string>(configuration?.configuration_item_id || '', {nonNullable: true}),
      'default_quantity': new FormControl<number>({value: configuration?.default_quantity || 0, disabled: !configuration?.is_applicable},
                                                  {nonNullable: true}),
      'is_applicable': new FormControl<boolean>(configuration?.is_applicable || false, {nonNullable: true})
    });

    this._formGroup.controls.is_applicable.valueChanges.subscribe(newVal => {
      if (!newVal) {
        this._formGroup.controls.default_quantity.setValue(0);
        this._formGroup.controls.default_quantity.disable();
      } else {
        this._formGroup.controls.default_quantity.enable();
      }
    });
  }

  get formGroup(): FormGroup<IPartConfigurationItemFormGroup> {
    return this._formGroup;
  }
}

export class Composite implements IComposite {
  composite_id: string = '';
  composite_reference: string = '';
  composite_description: string = '';
  composite_parts: CompositePart[] = [];
  composite_last_modified: string = '';

  constructor(composite: IComposite | undefined = undefined) {
    this.composite_id = composite?.composite_id || '';
    this.composite_reference = composite?.composite_reference || '';
    this.composite_description = composite?.composite_description || '';
    this.composite_parts = [];
    this.composite_last_modified = composite?.composite_last_modified || '';

    if (composite?.composite_parts && composite.composite_parts.length) {
      for (let compositePart of composite.composite_parts) {
        this.composite_parts.push(new CompositePart(compositePart));
      }
    }
  }

  get formGroup(): FormGroup<ICompositeFormGroup> {
    let fg = new FormGroup<ICompositeFormGroup>({
      composite_id: new FormControl<string>(this.composite_id, {nonNullable: true}),
      composite_reference: new FormControl<string>(this.composite_reference, {nonNullable: true}),
      composite_description: new FormControl<string>(this.composite_description, {nonNullable: true}),
      composite_parts: new FormArray<FormGroup<ICompositePartFormGroup>>([])
    });

    /*if (this.parts && this.parts.length) {
      for (let comp_part of this.parts) {
        fg.controls.composite_parts.push(comp_part.formGroup);
      }
    }*/

    return fg;
  }
}

export class CompositePart implements ICompositePart {
  subassembly_sequence_id: string = '';
  composite_id: string = '';
  part_id: string = '';
  quantity: number = 0;
  position: number = 0;
  description?: string = '';

  constructor(compositePart: ICompositePart | undefined = undefined) {
    this.subassembly_sequence_id = compositePart?.subassembly_sequence_id || '';
    this.composite_id = compositePart?.composite_id || '';
    this.part_id = compositePart?.part_id || '';
    this.quantity = compositePart?.quantity || 0;
    this.position = compositePart?.position || 0;
    this.description = compositePart?.description || '';
  }

  get formGroup(): FormGroup<ICompositePartFormGroup> {
    return new FormGroup<ICompositePartFormGroup>({
      subassembly_sequence_id: new FormControl(this.subassembly_sequence_id, {nonNullable: true}),
      composite_id: new FormControl(this.composite_id, {nonNullable: true}),
      part_id: new FormControl(this.part_id, {nonNullable: true}),
      quantity: new FormControl<number>(this.quantity, {nonNullable: true}),
      position: new FormControl<number>(this.position, {nonNullable: true}),
    });
  }
}

