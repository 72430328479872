import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

export interface IConfigurationCategory {
  category_name: string;
  category_description: string;
  configuration?: IConfigurationItem[];
};

export interface IConfigurationItem {
  configuration_item_id?: string;
  configuration_category: string;
  configuration_item_name: string;
  configuration_item_description: string;
  has_quantity: boolean;
  is_flag: boolean;
  line_number: number;
};

export interface IConfigurationCategoryFormGroup {
  category_name: FormControl<string>;
  category_description: FormControl<string>;
  configuration: FormArray<FormGroup<IConfigurationItemFormGroup>>;
}

export interface IConfigurationItemFormGroup {
  configuration_item_id: FormControl<string|null>;
  configuration_item_name: FormControl<string>;
  configuration_item_description: FormControl<string|null>
  has_quantity: FormControl<boolean>;
  is_flag: FormControl<boolean>;
  line_number: FormControl<number>;
}

export class PartConfigurationCategory {
  private _formGroup: FormGroup<IConfigurationCategoryFormGroup>;

  constructor(category?: IConfigurationCategory) {
    this._formGroup = new FormGroup({
      'category_name': new FormControl<string>(
        category?.category_name || '',
        {nonNullable: true, validators: [Validators.required]}),
      'category_description': new FormControl<string>(
        category?.category_description || '',
        {nonNullable: true}),
      'configuration': new FormArray<FormGroup<IConfigurationItemFormGroup>>([])
    });

    if (category?.configuration?.length) {
      for (let option of category.configuration) {
        let _option = new PartConfigurationItem(option);
        this._formGroup.controls.configuration.push(_option.formGroup);
      }
    }
  }

  get formGroup(): FormGroup<IConfigurationCategoryFormGroup> {
    return this._formGroup;
  }
}

export class PartConfigurationItem {
  private _formGroup: FormGroup<IConfigurationItemFormGroup>;

  constructor(option?: IConfigurationItem) {
    this._formGroup = new FormGroup<IConfigurationItemFormGroup>({
      'configuration_item_id': new FormControl<string|null>(
        option?.configuration_item_id || ''),
      'configuration_item_name': new FormControl<string>(
        option?.configuration_item_name || '',
        {nonNullable: true, validators: [Validators.required]}),
      'configuration_item_description': new FormControl<string|null>(
        option?.configuration_item_description || ''),
      'has_quantity': new FormControl<boolean>(option?.has_quantity || false,
                                               {nonNullable: true}),
      'is_flag': new FormControl<boolean>(option?.is_flag || false,
                                          {nonNullable: true}),
      'line_number': new FormControl<number>(option?.line_number || 1,
                                             {nonNullable: true})
    });
  }

  get formGroup(): FormGroup<IConfigurationItemFormGroup> {
    return this._formGroup;
  }
}
