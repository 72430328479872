import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { QuoteHeaderComponent } from '../../../quote-header/quote-header.component';
import { MatIconModule } from '@angular/material/icon';
import { IDocumentTemplate } from '../../interfaces';
import { TemplateService } from '../../template.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-quote-template-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    QuoteHeaderComponent,
    MatIconModule
  ],
  templateUrl: './quote-template-list.component.html',
  styleUrl: './quote-template-list.component.scss'
})
export class QuoteTemplateListComponent implements OnInit {
  service: TemplateService = inject(TemplateService);

  templates: IDocumentTemplate[] = [];

  get no_templates(): boolean {
    return this.templates.length == 0;
  }

  ngOnInit(): void {
    this.service.list_document_templates().subscribe(templates => {
      this.templates = templates;
    });
  }

  getEditLink(id: string): string {
    return `/quotes/templates/quote-templates/${id}/edit`;
  }


}
