.export-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    flex-grow: 1;
  }

  & h4 {
    margin: 0;
  }
}

#app-content-inner.Quote-Editor {
  padding-right: 0 !important;
}

.section-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & .move {
    position: absolute;
    top: 0.25em;
    right: 0;
    display: inline-block;
    font-size: 0.8em;
    height: 24px;
  }

  & .section-number {
    display: inline-block;
    width: 24px;
    text-align: center;
  }
}
