import { Injectable } from "@angular/core";

@Injectable()
export class ReportConfigurationService<T> {
  reportConfiguration!: T;

  get configuration(): T {
    return this.reportConfiguration;
  }

  set configuration(config) {
    this.reportConfiguration = config;
  }

  constructor() {}
}
