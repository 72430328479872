<div class="app-section-header">
  <div class="manager-panel app-section-header-item">
    <a mat-fab extended routerLink="/quotes/add">
      <mat-icon>add</mat-icon>
      Add Quote
    </a>
    <ng-content></ng-content>
  </div>
  <div class="app-section-header-item app-category-container">
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/quotes/list">Quotes</a>
    </div>
    <div class="app-section-header-category" routerLinkActive="active">
      <a routerLink="/quotes/documents">Documents</a>
    </div>
    <div class="app-section-header-category" routerLinkActive="active">
      <span [matMenuTriggerFor]="templateMenu">Templates</span>
      <mat-menu #templateMenu>
        <a mat-menu-item routerLink="/quotes/templates/quote-templates">Quote Templates</a>
        <a mat-menu-item routerLink="/quotes/templates/section-templates">Section Templates</a>
      </mat-menu>
    </div>
  </div>
</div>
