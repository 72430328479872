<div class="app-editor">
  <div id="toolbar">

  </div>
  <div id="editor" #editor style="height: 50em;">

  </div>
  <div class="hints">
    <div *ngIf="disabled"><em>Editor is disabled</em></div>
  </div>
</div>

