export class ContextManager {
  private activeContextId: number;
  private _contextstack: any[] = [];
  private _basecontext: any;

  constructor(context?: any) {
    if (context) {
      this._basecontext = context;
      this.push(context);
    }

    this.activeContextId = 0;
  }

  get currentContextId(): number {
    return this.activeContextId;
  }

  get isBaseContext(): boolean {
    return this.activeContextId == 0;
  }

  get currentContext() {
    return this.peek();
  }

  acquire(newContext: any) {
    if (this.currentContextId == newContext.parent.id) {
      return this.currentContext;
    }

    if (this.exists(newContext.parent.id)) {
      this.rescindExcept(newContext.parent.id);
      return this.currentContext;
    }

    this.push(newContext);
    this.updateActiveContext();
    return this.currentContext;
  }

  relinquish(id: number) {
    if (this.currentContextId == id) {
      this.pop();
    }
  }

  rescind(id: number) {
    let stack = this._contextstack;
    this.clear();
    for (let ctx of stack) {
      if (!ctx.parent || ctx.parent.id != id) {
        this.push(ctx);
      }
    }
    this.updateActiveContext();
  }

  makeContextBase() {
    this.rescindExcept(0);
  }

  rescindExcept(id: number) {
    while (this.currentContextId != id) {
      this.pop();
    }
  }

  rescindAll() {
    while (this.currentContext.parent.id) {
      this.pop();
    }
  }

  clear() {
    this._contextstack = [];
  }

  private push(item: any) {
    this._contextstack.push(item);
  }

  private pop() {
    if (!this.isEmpty()) {
      this._contextstack.pop();
      this.updateActiveContext();
    }
  }

  private isEmpty() {
    return this._contextstack.length == 0;
  }

  private peek() {
    if (!this.isEmpty()) {
      return this._contextstack[this._contextstack.length - 1];
    }

    this.setbasecontext();
    return this._basecontext;
  }

  private setbasecontext() {
    this.push(this._basecontext);
    this.activeContextId = 0;
    this.updateActiveContext();
  }

  private updateActiveContext() {
      let newContext = this.peek();
      this.activeContextId = newContext?.parent.id || 0;
  }

  private exists(id: number) {
    for (let ctx of this._contextstack) {
      if (ctx.parent.id == id) {
        return true;
      }
    }

    return false;
  }
}
