import { Component, inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IHotJobState, IQuoteListView } from '../shared/interfaces';
import { CommonModule } from '@angular/common';
import { QuoteService } from '../../shared/quote.service';

@Component({
  selector: 'app-quote-hot-job',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './quote-hot-job.component.html',
  styleUrl: './quote-hot-job.component.scss'
})
export class QuoteHotJobComponent implements OnInit {
  private service: QuoteService = inject(QuoteService);

  private originalState: number = 0;
  private state: number = 0;
  @Input() quote!: IQuoteListView;

  private jobtypes: string[] = ['',
                                'cold',
                                'warm',
                                'hot']
  private suffix: string = '';
  private timeoutid: ReturnType<typeof setTimeout> | undefined = undefined;
  private _cssclass: string = '';

  get quote_id(): string {
    return this.quote.quote_id;
  }

  get jobtype(): string {
    return this.jobtypes[this.state];
  }

  get cssclass(): string {
    let _c = ''
    if (this.state) {
      _c = `hot-job hot-job-${this.jobtype}`;
    }

    if (this._cssclass) _c += ` ${this._cssclass}`;

    return _c;
  }

  set cssclass(newcssclass) {
    console.log("setting class: ", newcssclass);
    let _c = this.cssclass;
    _c += ` ${newcssclass}`;
    this._cssclass = _c;
  }

  ngOnInit() {
    this.originalState = this.quote.hot_job;
    this.state = this.quote.hot_job;
  }

  clearcssclass(): void {
    this._cssclass = '';
  }

  toggleHotJob() {
    if (this.timeoutid) {
      console.log("cacelling previous save");
      clearTimeout(this.timeoutid);
    }
    this.state = (++this.state)%this.jobtypes.length;
    this.timeoutid = setTimeout(
      () => {
        this.save();
      },
      500
    );
  }

  confirmChange() {
    this.cssclass = 'blink';
    setTimeout(() => {
      this.clearcssclass();
    },
    1000);
  }

  save() {
    if (this.state == this.originalState) return;

    this.service.updateHotJob(this.state, this.quote_id).subscribe({
      next: (newState: IHotJobState) => {
        this.originalState = newState.hot_job_state;
        this.confirmChange();
      },
      error: (_) => {
        this.state = this.originalState;
      }
    });
  }
}
