<form [formGroup]="fg"
  (submit)="update_document()"
  class="standard-editor-grid-container">
<div class="standard-editor-grid">
  <div class="sticky-column">
    <div id="documnet-details">
      <div class="section-header">
        <h4>Document Details</h4>
      </div>
      <div class="section-container">
        <mat-form-field class="full-width-form-control" appearance="outline">
          <mat-label>Document Name</mat-label>
          <input matInput type="text" formControlName="name" />
        </mat-form-field>
        <mat-form-field class="full-width-form-control" appearance="outline">
          <mat-label>Select Category</mat-label>
          <app-select-document-category formControlName="quote_document_type" [required]="true"/>
        </mat-form-field>
      </div>
    </div>

    <div id="quote-template-selector">
      <div class="section-header">
        <h4>Predefined Templates</h4>
      </div>
      <div class="section-container">
        @for (doc_template of document_templates; track doc_template.document_template_id) {
          <div class="export-section">
            <h4>{{ doc_template.template_name }}</h4>
            <button mat-icon-button type="button"
              (click)="add_document_template(doc_template.document_template_id)">
              <mat-icon>add</mat-icon></button>
          </div>
        }
      </div>
    </div>

    <div id="section-selector">
      <div class="section-header">
        <h4>Section Selector</h4>
      </div>
      <div class="section-container">
        @for (section of export_sections; track section.section_template_id) {
          <div class="export-section">
            <h4>{{ section.section_name }}</h4>
            <button mat-icon-button type="button"
              (click)="add_section_template(section.section_template_id)">
              <mat-icon>add</mat-icon></button>
          </div>
        }
      </div>
    </div>
  </div>

  <div class="editor-pane">
    <div class="section-header">
      <h4>Document Editor</h4>
      @if (show_create_new) {
      <button mat-icon-button type="button"
        (click)="new_document()">
        <mat-icon>note_add</mat-icon>
      </button>
      }
      @if(show_export) {
      <a mat-icon-button type="button"
        download
        [href]="get_export_link()">
        <mat-icon>picture_as_pdf</mat-icon>
      </a>
      }
      <button mat-icon-button type="button"
        [matMenuTriggerFor]="categories">
        <mat-icon>folder</mat-icon>
      </button>
      <mat-menu #categories="matMenu">
      @for (category of documents; track category.type_name) {
        <button type="button" mat-menu-item
          [matMenuTriggerFor]="submenu">{{ category.type_name }}</button>
          <mat-menu #submenu="matMenu">
          @for (document of category.documents; track document.quote_document_id) {
            <button mat-menu-item type="button"
              (click)="open_document(document.quote_document_id)">
              {{ document.name }}
            </button>
          }
          </mat-menu>
      }
      </mat-menu>
      <button mat-icon-button>
        <mat-icon>save</mat-icon>
      </button>
    </div>
    <div class="editor-container">
      <div style="display: flex; justify-content: flex-end; margin-bottom: 1em;">
        <button mat-raised-button type="button" (click)="addSection()">Add Section</button>
      </div>
      <fieldset *ngFor="let section of sections.controls" [formGroup]="section" style="margin-bottom: 0.5em;">
        <div class="section-details">
          <mat-form-field appearance="outline">
            <mat-label>Section name</mat-label>
            <input matInput formControlName="section_name" />
          </mat-form-field>
          <span class="section-number">{{ section.controls.section_number.value }}</span>
          <span class="move"><mat-icon>drag_indicator</mat-icon></span>
        </div>
        <app-rich-text-input formControlName="section_content"></app-rich-text-input>
      </fieldset>
      <div class="action" style="display: flex; justify-content: flex-end; margin-top: 1em;">
      </div>
    </div>
  </div>
</div>
</form>

