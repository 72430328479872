import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ClipboardService } from '../../../core/clipboard.service';
import { IPointSchedulePartConfigurationFormGroup,
         IPointSchedulePartFormGroup,
         IQuoteItemPointsListConfiguration } from '../models';
import { ReportConfigurationService } from '../../shared/report-configuration.service';
import { IReportConfigurationCategory } from '../../shared/interfaces';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: '[points-schedule-row]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    DragDropModule
  ],
  templateUrl: './points-schedule-row.component.html',
  styleUrl: './points-schedule-row.component.scss'
})
export class PointsScheduleRowComponent {
  clipboard: ClipboardService<IQuoteItemPointsListConfiguration> =
    inject(ClipboardService<IQuoteItemPointsListConfiguration>);
  configurationService: ReportConfigurationService<IReportConfigurationCategory> =
    inject(ReportConfigurationService);

  @Input() scheduleconfiguration!: any;
  @Input() fg!: FormGroup<IPointSchedulePartFormGroup>;

  @Output() reorder: EventEmitter<any> = new EventEmitter<any>();

  get configuration(): FormArray<FormGroup<IPointSchedulePartConfigurationFormGroup>> {
    return this.fg.controls.configuration;
  }

  get reportConfiguration() {
    return this.configurationService.configuration;
  }

  paste() {
    let item = this.clipboard.paste();
    if (!item) {
      return;
    }

    for (let control of this.configuration.controls) {
      let configName = control.controls['configuration_item_name'].value;
      let pastedconfig = item.configuration.find(x => {
        return x.configuration_item_name == configName;
      });

      control.controls.quantity.setValue(+(pastedconfig?.quantity || 0));
    }
  }

  delete() {
    const parent = this.fg.parent as FormArray<FormGroup<any>>;

    for (var i = 0; i < parent?.length; i++) {
      let control = parent.at(i);
      if (control.controls['line_number'].value == this.fg.controls['line_number'].value) {
        parent.removeAt(i);
      }
    }

    this.reorder.emit();
  }
}
