import { Component, inject } from '@angular/core';
import { PartHeaderComponent } from '../../part-header/part-header.component';
import { CommonModule } from '@angular/common';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IConfigurationCategory, IConfigurationCategoryFormGroup, IConfigurationItemFormGroup, PartConfigurationCategory, PartConfigurationItem } from '../shared/models';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PartService } from '../../shared/part.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IEnumerableFormGroup, LineNumberManager } from '../../../core/line-number-manager';

@Component({
  selector: 'app-part-configuration-edit',
  standalone: true,
  imports: [
    CommonModule,
    PartHeaderComponent,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    DragDropModule
  ],
  templateUrl: './part-configuration-edit.component.html',
  styleUrl: './part-configuration-edit.component.scss'
})
export class PartConfigurationEditComponent {
  service: PartService = inject(PartService);
  lineManager!: LineNumberManager;

  isEditMode: boolean = false;

  configuration;

  get buttonLabel(): string {
    if (this.isEditMode) {
      return "Update";
    }

    return "Add";
  }

  get formGroup(): FormGroup<IConfigurationCategoryFormGroup> {
   return this.configuration.formGroup;
  }

  get formGroupValue(): IConfigurationCategory {
    return this.formGroup.value as IConfigurationCategory;
  }

  get part_configuration_items(): FormArray<FormGroup<IConfigurationItemFormGroup>> {
    return this.formGroup.controls.configuration as FormArray<FormGroup<IConfigurationItemFormGroup>>;
  }

  constructor(private route: ActivatedRoute) {
    let category = this.route.snapshot.params['id'] || '';
    this.configuration = new PartConfigurationCategory();

    if (category) {
      this.isEditMode = true;
      this.service.loadConfigurationProfile(category).subscribe({
        next: (config) => {
          this.configuration = new PartConfigurationCategory(config);
          this.lineManager = new LineNumberManager(
            this.part_configuration_items as unknown as
            FormArray<FormGroup<IEnumerableFormGroup>>);
          this.lineManager.reorder()
        }
      })
    } else {
      this.lineManager = new LineNumberManager(
        this.part_configuration_items as unknown as
        FormArray<FormGroup<IEnumerableFormGroup>>);
    }
  }

  addOption(): void {
    let option = new PartConfigurationItem();
    let fg = option.formGroup;
    fg.controls.line_number.setValue(this.lineManager.curlinenumber);
    this.part_configuration_items.push(fg);
  }

  removeAt(idx: number) {
    this.part_configuration_items.removeAt(idx);
  }

  drop(e: any) {
    let item = this.part_configuration_items.at(e.previousIndex);
    this.part_configuration_items.removeAt(e.previousIndex);
    this.part_configuration_items.insert(e.currentIndex, item);
    this.lineManager.reorder();
  }

  save() {
    if (this.formGroup.valid) {
      let method = this.service.saveConfiguration.bind(this.service);
      if (this.isEditMode) {
        method = this.service.updateConfiguration.bind(this.service);
      }

      method(this.formGroupValue).subscribe({
        next: _ => {
          if (!this.isEditMode) {
            this.isEditMode = true;
          }
        },
        error: (e: HttpErrorResponse) => {
          this.isEditMode = true;
        }
      })
    }
  }
}
