import { inject, Injectable } from '@angular/core';
import { Delta } from 'quill/core';
import { ApiGatewayService } from '../../../core/api-gateway.service';
import { Observable, of } from 'rxjs';
import { IDocumentSectionTemplate, IDocumentTemplate } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  gateway: ApiGatewayService = inject(ApiGatewayService);

  constructor() { }

  list_document_templates(): Observable<IDocumentTemplate[]> {
    return this.gateway.get<IDocumentTemplate[]>('/templates/documents');
  }

  load_document_template(template_id: string): Observable<IDocumentTemplate> {
    return this.gateway.get<IDocumentTemplate>('/templates/documents/' + template_id);
  }

  load_document_template_content(template_id: string): Observable<IDocumentTemplate> {
    return this.gateway.get<IDocumentTemplate>('/templates/documents/' + template_id + '/contents');
  }

  loadSectionTemplate(section_id: string): Observable<IDocumentSectionTemplate> {
    return this.gateway.get<IDocumentSectionTemplate>('/templates/sections/' + section_id);
  }

  loadSectionTemplates(): Observable<Partial<IDocumentSectionTemplate>[]> {
    return this.gateway.get<Partial<IDocumentSectionTemplate>[]>('/templates/sections');
  }

  saveSectionTemplate(section_name: string, content: Delta):
    Observable<IDocumentSectionTemplate> {
    let data: IDocumentSectionTemplate = {
      'section_template_id': '',
      'section_name': section_name,
      'section_content': content
    }

    return this.gateway.post<IDocumentSectionTemplate,
      IDocumentSectionTemplate>('/templates/sections', data);
  }

  updateSectionTemplate(
    section_id: string,
    name: string,
    content: Delta): Observable<IDocumentSectionTemplate> {
    let data: IDocumentSectionTemplate = {
      'section_template_id': section_id,
      'section_name': name,
      'section_content': content
    }

    return this.gateway.post<IDocumentSectionTemplate,
      IDocumentSectionTemplate>('/templates/sections/update', data);
  }

  edit_document_template(template: Partial<IDocumentTemplate>) {
    return this.gateway.post<any, any>('/templates/documents', template);
  }
}
