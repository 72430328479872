import { Component, ElementRef, forwardRef, inject, Input, NgZone, OnChanges, ViewChild } from '@angular/core';
import Quill, { Delta } from 'quill/core';
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Header from 'quill/formats/header';
import List from 'quill/formats/list';
import Link from 'quill/formats/link';
import Script from 'quill/formats/script';
import * as _Align from 'quill/formats/align';
import Indent from 'quill/formats/indent';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TemplateService } from '../../plm/quotes/templates/template.service';

const Align = _Align.AlignClass;

Quill.register({
  "modules/toolbar": Toolbar,
  "themes/snow": Snow,
  "formats/bold": Bold,
  "formats/italic": Italic,
  "formats/header": Header,
  "formats/underline": Underline,
  "formats/script": Script,
  "formats/list": List,
  "formats/link": Link,
  "formats/align": Align,
  "formats/indent": Indent
}, true);

@Component({
  selector: 'app-rich-text-input',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './rich-text-input.component.html',
  styleUrl: './rich-text-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextInputComponent),
      multi: true
    }
  ]
})
export class RichTextInputComponent implements ControlValueAccessor {
  @ViewChild('editor', {static: true})
  editor!: ElementRef<HTMLElement>;
  service: TemplateService = inject(TemplateService);

  protected disabled: boolean = false;
  protected _onchange: (contents: Delta) => void = (_: Delta) => {};
  protected _ontouched: () => void = () => {};

  @Input() placeholder: string = 'Enter customer text here...';

  quill!: any;

  ngOnInit(): void {
    const toolbarOptions = [
      [{'header': []}],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'align': ''}, {'align': 'right'}],
      [{'script': 'super'}, {'script': 'sub'}]
    ]
    this.quill = new Quill(this.editor.nativeElement, {
      modules: {
        toolbar: toolbarOptions
      },
      theme: 'snow',
      placeholder: this.placeholder,
    });

    this.quill.on('text-change', (delta: Delta, oldContentx: Delta, source: string) => {
      this._onchange(this.quill.getContents());
    });
  }

  writeValue(obj: any): void {
    this.quill.setContents(obj);
  }

  registerOnChange(fn: any): void {
    this._onchange = fn;
  }

  registerOnTouched(fn: any): void {
    this._ontouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.quill.enable(!disabled);
  }
}
