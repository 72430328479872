import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { Observable } from 'rxjs';
import { IPointScheduleReport } from '../reports/points-schedule/models';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  gateway = inject(ApiGatewayService);

  constructor() { }

  configurations(): Observable<any> {
    return this.gateway.get<any>('/reports/configurations');
  }

  configuration(reportName: string) {
    return this.gateway.get<any>(`/reports/configurations/${reportName}`);
  }

  updateConfiguration(reportConfiguration: any) {
    return this.gateway.post<any, any>('/reports/configurations',
                                       reportConfiguration);
  }

  savepointslist(pointslist: IPointScheduleReport) {
    return this.gateway.post<IPointScheduleReport, IPointScheduleReport>(
      '/reports/pointslists', pointslist);
  }

  updatepointlist(pointlist: IPointScheduleReport) {
    return this.gateway.post<IPointScheduleReport, IPointScheduleReport>(
      '/reports/pointslists/update', pointlist
    );
  }

  loadforquote(quote_id: string): Observable<IPointScheduleReport> {
    return this.gateway.get<IPointScheduleReport>(`/quotes/${quote_id}/pointslist`);
  }
}
