<ng-container [formGroup]="fg">
  <td class="action-panel-container">
    <div class="action-panel">
      <div class="col">
        <mat-icon class="drag" cdkDragHandle>drag_indicator</mat-icon>
      </div>
      <div class="col">
        <div class="action-panel">
          <mat-icon (click)="paste()" class="icon">content_paste</mat-icon>
          <mat-icon (click)="delete()" class="icon">delete</mat-icon>
        </div>
        <span>
          {{ fg.controls.line_number.value }}
        </span>
      </div>
    </div>
  </td>
  <td>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>description</mat-label>
      <input matInput type="text"
        formControlName="description" />
    </mat-form-field>
  </td>
  <td class="table-center-content"
      style="width: 90px;"
      *ngFor="let config of scheduleconfiguration.controls"
      [formGroup]="config">
    <mat-form-field class="full-width" appearance="outline">
    <input matInput
      type="number"
      min="0"
      style="width: 100%"
      formControlName="quantity" />
    </mat-form-field>
  </td>
  <td class="table-center-content" style="border: 0; width: 200px;">
    <mat-form-field class="full-width" appearance="outline">
      <textarea matInput
        formControlName="note"
        style="width: 100%;"></textarea>
    </mat-form-field>
  </td>
</ng-container>
