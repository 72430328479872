<app-quote-header></app-quote-header>
<form [formGroup]="fg" (submit)="save()" class="data-form data-form-lg" *ngIf="fg">
  <app-messages [message]="system_message" style="display: inline-block; width: inherit;"></app-messages>
  <mat-form-field appearance="outline">
    <mat-label>Section Name</mat-label>
    <input matInput type="text" formControlName="section_name" />
  </mat-form-field>
  <app-rich-text-input formControlName="section_content"></app-rich-text-input>

  <div class="form-action-right">
    <button mat-raised-button>{{ txt_btn_submit }}</button>
  </div>
</form>
