<app-quote-header>
  <a mat-fab extended routerLink="/quotes/templates/section-templates/add">
    <mat-icon>add</mat-icon>
    Section Template
  </a>
</app-quote-header>
<table>
  <thead>
    <tr>
      <th>Name</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    @for (t of templates; track t.section_template_id) {
    <tr>
      <td>
        {{ t.section_name }}
      </td>
      <td>
        <a mat-icon-button
           [routerLink]="editLink(t.section_template_id)">
          <mat-icon>edit</mat-icon></a>
      </td>
    </tr>
    }
  </tbody>
</table>
