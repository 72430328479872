<div [formGroup]="fg">
  <mat-form-field>
    <mat-label>Part Accessory Reference</mat-label>
    <input matInput type="text" formControlName="accessory_ref" />
  </mat-form-field>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>Assign Part</mat-panel-title>
    </mat-expansion-panel-header>
    <app-configurator [showToolbar]="false"
      [showComposite]="false"
      [showPartFilter]="false"
      (partSelected)="onPartSelect($event)"></app-configurator>

  </mat-expansion-panel>

  <mat-form-field>
    <mat-label>Accessory Description</mat-label>
    <textarea matInput formControlName="accessory_description"></textarea>
  </mat-form-field>
</div>
