import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, effect, EventEmitter, inject, input, Input, model, Output, Signal, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StreamingService } from '../../core/streaming.service';
import { ItemType } from '../quotes/shared/models';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { IActiveAggregate } from './interfaces';
import { IConfiguratorAggContextLeaseRequest, IConfiguratorPart } from '../quotes/shared/interfaces';
import { MatTooltipModule } from '@angular/material/tooltip';


export interface IToolbarEvent {
  button: string;
  disable: boolean;
}

@Component({
  selector: 'app-configurator',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    ScrollingModule,
    MatFormFieldModule,
    MatProgressSpinner,
    FormsModule
  ],
  templateUrl: './configurator.component.html',
  styleUrl: './configurator.component.scss'
})
export class ConfiguratorComponent {
  private service: StreamingService = inject(StreamingService);
  private description_subscriptions: any[] = [];

  parts: any[] = [];
  viewportParts = signal<any[]>([]);
  partGroups = signal<string[]>([]);
  partManufacturers = signal<string[]>([]);

  filterGroup: string = "";
  filterManufacturer: string = "";
  searchQuery: string = "";
  queryParts: any[] = [];
  fetching: boolean = false;

  missingBuffer: string = '';
  reset = model(false);

  compositeName: string = 'Composite';

  toolbaraction = input<IToolbarEvent[]>([]);
  leaseContext = input<IConfiguratorAggContextLeaseRequest>();
  finishedGoodMode: boolean = false;
  compositeMode: boolean = false;

  activeAggregates: IActiveAggregate[] = [];

  @Input() showToolbar: boolean = true;
  @Input() showFinishedGood: boolean = true;
  @Input() showComposite: boolean = true;
  @Input() showPartSelector: boolean = true;
  @Input() showPartFilter: boolean = true;

  @Output() partSelected: EventEmitter<any> = new EventEmitter();
  @Output() stopComposite: EventEmitter<any> = new EventEmitter();

  toolbarButtonDisabledState: any = {
    'nett_price': false,
    'finished_good': false,
    'paste': false
  }

  get active_aggregate(): IActiveAggregate | undefined {
    return this.activeAggregates.slice(-1).pop();
  }

  constructor() {
    effect(() => {
      if (this.leaseContext()) {
        this.acquireContext(this.leaseContext());
      }
    });

    effect(() => {
      if (this.toolbaraction()) {
        for (let e of this.toolbaraction()) {
          this.toolbarButtonDisabledState[e.button] = e.disable;
        }
      }
    });

    effect(() => {
      if (this.reset()) {
        console.log("reseting");
        this.resetConfigurator();
      }

    }, {allowSignalWrites: true});
  }

  ngOnInit() {
    this.getParts();
  }

  clearall() {
    this.filterGroup = "";
    this.filterManufacturer = "";
    this.searchQuery = "";
    this.viewportParts.set(this.parts);
  }

  ngAfterViewInit(): void {
    window.document.getElementById("filters")?.addEventListener("click", (e) => {
      let filteredParts = []

      let element = e.target as HTMLSpanElement;
      window.document.getElementById(element.id);
      let filters = window.document.getElementsByClassName("filter-text");
      for (let i = 0; i < filters.length; i++) {
        let filter= filters[i];
        filter.removeAttribute("selected");
      }

      element.setAttribute("selected", "");

      if (element.textContent == 'Parts') {
        filteredParts = this.parts.filter(x => {
          return x.type == 'part';
        });
      }

      if (element.textContent == 'Enclosures') {
        filteredParts = this.parts.filter(x => {
          return x.part_is_enclosure;
        });
      }

      if (element.textContent == 'Composites') {
        filteredParts = this.parts.filter(x => {
          return x.type == 'composite';
        });
      }

      if (element.textContent == 'All') {
        this.viewportParts.set(this.parts);
        return;
      }

      this.viewportParts.set(filteredParts);
    });
  }

  search() {
    if (this.searchQuery == '') {
      return;
    }

    this.fetching = true;
    const url = this.service.makeRequest("search?q=" + encodeURIComponent(this.searchQuery));
    const req = new Request(url);
    fetch(req, {credentials: "include"})
    .then((response) => {
      const reader = response.body?.getReader();
      var that = this;
      return new ReadableStream({
        start(controller) {
          return readChunk();
          function readChunk(this: any): any {
            return reader?.read().then(({done, value}) => {
              if (done) {
                controller.close();
                that.viewportParts.set(that.queryParts);
                /*that.parts.map((x) => {
                  if (that.partGroups().indexOf(x.part_group_ref) < 0) {
                    that.partGroups().push(x.part_group_ref);
                  }

                  if (that.partManufacturers().indexOf(x.part_manufacturer) < 0) {
                    that.partManufacturers().push(x.part_manufacturer);
                  }
                });*/
                that.addPartDetails(that.queryParts);
                that.fetching = false;
                return;
              }

              that.queryParts = [];
              const decoder = new TextDecoder();
              let resp = decoder.decode(value);
              let partsChunk = that.appendParts(resp);
              that.queryParts = that.queryParts.concat(partsChunk);
              return readChunk();
            });
          }
        }
      });
    });
  }

  filterByGroup() {
    if (this.filterGroup == "") {
      this.viewportParts.set(this.parts);
      return;
    }

    this.viewportParts.set(this.parts.filter(part => {
      return part.part_group_ref == this.filterGroup;
    }));
  }

  filterByManufacturer() {
    if (this.filterManufacturer == "") {
      this.viewportParts.set(this.parts);
      return;
    }

    this.viewportParts.set(this.parts.filter(part => {
      return part.part_manufacturer == this.filterManufacturer;
    }));
  }

  getParts() {
    this.fetching = true;
    const url = this.service.makeRequest("stream");
    const req = new Request(url);
    fetch(req, {credentials: "include"})
    .then((response) => {
      let that = this;
      const reader = response.body?.getReader();
      return new ReadableStream({
        start(controller) {
          return readChunk();
          function readChunk(): any {
            return reader?.read().then(({done, value}) => {
              if (done) {
                controller.close();
                that.viewportParts.set(that.parts);
                that.parts.map((x) => {
                  if (that.partGroups().indexOf(that.capitalise(x.part_group_ref)) < 0) {
                    that.partGroups().push(that.capitalise(x.part_group_ref));
                  }

                  if (that.partManufacturers().indexOf(that.capitalise(x.part_manufacturer)) < 0) {
                    that.partManufacturers().push(that.capitalise(x.part_manufacturer));
                  }

                  x.selectedMarkup = 1+x.markups[0]/100 || 1.5;
                });
                that.fetching = false;
                return;
              }

              const decoder = new TextDecoder();
              let resp = decoder.decode(value);
              let partsChunk = that.appendParts(resp);
              if (partsChunk.length) {
                that.parts = that.parts.concat(partsChunk);
              }
              return readChunk();
            });
          }
        }
      })
    });
  }

  private _msgbuffer = "";
  appendParts(parts: any) {
    let container: any[] = [];
    let split_parts = parts.split("\r\n");

    if (split_parts.length > 0) {
      let num_chunks = split_parts.length;

      for (let i = 0; i < num_chunks; i++) {
        this._msgbuffer += split_parts[i];
        try {
          let data = JSON.parse(this._msgbuffer);
          container = container.concat(data);
          this._msgbuffer = '';
        } catch(err) {

        }
      }
    }

    return container;
  }

  makeFinishedGood(): Partial<IConfiguratorPart> {
    return {
      type: ItemType.FINISHEDGOOD,
      quantity: 1,
      markup: 1,
      parts: [],
      part_is_enclosure: false
    }
  }

  makeNettPrice(): Partial<IConfiguratorPart> {
    return {
      type: ItemType.NETTPRICE,
      quantity: 1,
      markup: 1,
      part_is_enclosure: false
    }
  }

  isPartActive(part: any): boolean {
    let currentActiveAgg = this.peekAggregateContext();
    let currentActiveType = currentActiveAgg?.type.toLowerCase();

    if (currentActiveType == ItemType.COMPOSITE) {
      if (part.type == ItemType.COMPOSITE) {
        return false;
      }

      if (part.part_is_enclosure) {
        return false;
      }
    }

    if (currentActiveType == ItemType.ENCLOSURE) {
      if (part.part_is_enclosure) {
        return false;
      }
    }

    return true;
  }

  selectPart(_: any, part: Partial<IConfiguratorPart>) {
    if (part.type == ItemType.PART) {
      if (!part.selectedMarkup) {
        if (part.markups && part.markups[0]) {
          part.selectedMarkup = 1+part.markups[0]/100;
        } else {
          part.selectedMarkup = 1.5;
        }
      }
    }

    if (part.parts && part.type == ItemType.COMPOSITE) {
      part.parts = this.addPartDetails(part.parts);
    }

    this.partSelected.emit(part);
  }

  updateSelectedMarkup(part: any, markup:number) {
    part.selectedMarkup = 1+markup/100;
  }

  get canAddFinishedGood(): boolean {
    if (this.activeAggregates.length) {
      return false;
    }

    return !this.finishedGoodMode;
  }

  get canEditFinishedGood(): boolean {
    let currentAggContext = this.peekAggregateContext();

    if (!currentAggContext) {
      return true;
    } else {
      return false;
    }
  }

  toggleAggEdit(e: MatSlideToggleChange | undefined = undefined) {
    if (e?.checked) {
      console.log("checked");
    } else {
      this.endAggEdit();
    }

    this.compositeMode = !this.compositeMode;
  }

  beginComposite() {
    return;
  }

  endAggEdit() {
    let removed = this.activeAggregates.pop();
    let sub = this.description_subscriptions.find(x => x.id == removed?.internal_id);
    if (sub) {
      console.log("unsubscribing");
      sub.sub.unsubscribe();
      this.description_subscriptions = this.description_subscriptions.filter(
        x => x.id != removed?.internal_id)
    }

    let currentActiveAgg = this.peekAggregateContext();
    if (currentActiveAgg) {
      currentActiveAgg.isActive = true;
    }
    this.stopComposite.emit(removed?.internal_id);
  }

  disableOtherAggregateContexts() {
    this.activeAggregates.map(agg => {
      agg.isActive = false;
    });
  }

  isAggregateContextEmpty(): boolean {
    if (this.activeAggregates.length) {
        return false;
    }

    return true;
  }

  resetAggregateContext() {
    this.activeAggregates = [];
  }

  peekAggregateContext(): IActiveAggregate | undefined {
    if (this.activeAggregates.length) {
      return this.activeAggregates[this.activeAggregates.length - 1];
    }

    return undefined;
  }

  getAggregateDescription(id: number) {
    let desc = this.description_subscriptions.find(x => x.id == id);
    if (desc) {
      return desc.desc;
    }
  }

  acquireContext(item: IConfiguratorAggContextLeaseRequest|undefined) {
    if (item) {
      if (this.active_aggregate?.internal_id == item.internal_id) {
        return;
      }

      if (this.aggregateInContextStack(item.internal_id)) {
        while(this.active_aggregate?.internal_id != item.internal_id) {
          this.endAggEdit();
        }

        return;
      };

      let context: IActiveAggregate = {
        internal_id: item?.internal_id,
        type: this.capitalise(item?.aggregate_type),
        isActive: true
      }

      this.disableOtherAggregateContexts();
      if (item.relinquish) {
        this.relinquishContext(item.internal_id);
        return;
      }

      if (item.subscription) {
        let sub = item.subscription.subscribe(newval => {
          let sub = this.description_subscriptions.find(x => x.id == item.internal_id);
          if (newval) {
            sub.desc = newval;
          }
        });

        this.description_subscriptions.push({
          'id': item.internal_id,
          'desc': context.type,
          'sub': sub
        });

      }

      this.activeAggregates.push(context);
    }
  }

  aggregateInContextStack(id: number):boolean {
    for (let item of this.activeAggregates) {
      if (item.internal_id == id) {
        return true;
      }
    }

    return false;
  }

  relinquishContext(id: number) {
    this.activeAggregates = this.activeAggregates.filter(x => {
      return x.internal_id != id;
    });
  }

  resetConfigurator() {
    this.compositeMode = false;
    this.finishedGoodMode = false;
    this.endAggEdit();
    this.reset.set(false);
  }

  capitalise(name: string) {
    if (!name) {
      return "None";
    }

    if (name && name == "") {
      return "None";
    }

    let nameparts: string[] = name.split('_');
    nameparts = nameparts.map(x => {
      return x[0].toUpperCase() + x.slice(1)
    });

    return nameparts.join(' ');
  }

  addPartDetails(parts: IConfiguratorPart[]) {
    parts.map(p => {
      let part = this.parts.find((part: IConfiguratorPart) => {
        return part.id == p.id;
      });

      if (part) {
        p.configurations = part.configurations;
        p.markups = part.markups;
        if (p.markups) {
          p.selectedMarkup = 1+p.markups[0]/100 || 1.5;
        }
      }
    });
    return parts;
  }
}
