.export-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .export-section:first-child {
    flex-grow: 1;
  }

  & h4 {
    margin: 0;
  }
}

#app-content-inner.Add-Quote {
  padding-right: 0 !important;
}

.template-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}


