<div id="quote-grid-container">
<div id="quote-grid">
  <div class="configurator-column">
    <app-configurator
      (partSelected)="handlePartSelect($event)"
      [leaseContext]="leaseContext"
      [toolbaraction]="toolbaraction"
      [(reset)]="resetConfigurator"
      (stopComposite)="destroyCompositeContext($event)" style="position: sticky"></app-configurator>
  </div>

  <div id="quote" class="quote-column">
    <form [formGroup]="quoteFormGroup" (ngSubmit)="onSubmit()" *ngIf="quoteFormGroup">
      <div class="quote-section">
        <div class="section-header">
          <h4>Quote <span *ngIf="modified" style="font-size: 0.8em; vertical-align: text-top;">*</span></h4>
          <a mat-icon-button
             download
             *ngIf="showSor"
             [href]="download_sor_link">
            <mat-icon>summarize</mat-icon>
          </a>
          <button mat-icon-button>
            <mat-icon>save</mat-icon>
          </button>
        </div>
        <div class="quote-header">
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Quote Reference</mat-label>
                <input matInput formControlName="quote_ref" (keydown)="checkQuoteRef($event)"/>
                <mat-spinner *ngIf="checkingRef" [diameter]="25" style="position: absolute; right: 0; top: 0.5em;"></mat-spinner>
                <mat-hint>Must start with 5 digits</mat-hint>
              </mat-form-field>
              <div class="error" *ngIf="quote_ref.errors?.['reference']">This reference already exists</div>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput formControlName="description" />
              </mat-form-field>
            </div>
          </div>
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Select Customer</mat-label>
                <app-selectcustomer formControlName="customer_id"
                  [required]="true"></app-selectcustomer>
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Paragraph</mat-label>
                <input matInput formControlName="paragraph" />
              </mat-form-field>
            </div>
          </div>
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Consultant</mat-label>
                <input matInput formControlName="consultant" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Attn</mat-label>
                <input matInput formControlName="attention" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Salutation</mat-label>
                <input matInput formControlName="salutation" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="dtm_quote" />
                <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="quote-section" *ngIf="has_price_updates">
        <div id="price-update-message">
          <span>Messages about items in quote</span>
          <button type="button"
            (click)="toggle_show_price_updates()"
            mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

        <div id="price-update-details" *ngIf="show_price_updates">
          @for(price_update of price_updates; track price_update.item_line_number) {
            <div class="detail">
              <span>Item {{ price_update.part_ref }} on line {{ price_update.item_line_number }} has changed price from {{ price_update.unit_price | currency: 'GBP' }}
                to {{ price_update.new_price | currency: 'GBP' }}</span>
              <button type="button"
                (click)="update_price(price_update.quote_item_id, price_update.new_price)"
                mat-raised-button>Update</button>
            </div>
          }
            <div class="detail">
              <span></span>
              <button type="button"
                (click)="update_all_prices()"
                mat-raised-button>Update All</button>
            </div>
        </div>
      </div>

      <div class="quote-section">
        <div class="section-header">
          <h4>Quote Items</h4>
        </div>
        <table class="border-table">
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Description</th>
            <th class="cell">Qty</th>
            <th class="cell">Unit Price</th>
            <th class="cell">Markup</th>
            <th class="cell">Total</th>
            <th class="cell">Total inc. Markup</th>
          </tr>
          <!--<div class="quote-item-style solid-underline"></div>-->
          <tbody #dragBoundary
            cdkDropList
            (cdkDropListDropped)="drop($event, quoteItems)"
            [cdkDropListData]="quoteItems"
            cdkDropListLockAxis="y"
            class="quote-drag-boundary">
            @for (item of quoteItems.controls; track item.controls.item_line_number; let idx = $index) {
            @if (item.value.item_part_type == 'nett_price') {
            <tr [item]="item"
                [quote]="quoteItems"
                quote-item-nett-price
                cdkDrag cdkDragBoundary=".quote-drag-boundary"
                class="quote-item-row quote-item nett-price-row">
            </tr>
            } @else {
            <tr [item]="item"
                quote-item-row
                cdkDrag cdkDragBoundary=".quote-drag-boundary"
                        class="quote-item-row quote-item"
                        [class]="getquoterowclass(item.value)">
            </tr>
            }}
          </tbody>

          <tr class="quote-item" *ngIf="grandTotal">
            <td class="cell"></td>
            <td class="cell"></td>
            <td class="cell"></td>
            <td class="cell"></td>
            <td class="cell"></td>
            <td class="cell right-align" style="font-weight: bold;">{{ grandTotal }}</td>
            <td class="cell right-align" style="font-weight: bold;">{{ grandTotalIncMarkup }}</td>
          </tr>
          <!--<div class="quote-item-style solid-underline"></div>-->
        </table>
      </div>

      <div class="quote-section">
        <div class="section-header">
          <h4>Price Adjustments</h4>
        </div>
        <p>Adjustments will be applied pro-rata to headline cost items</p>
        <table id="adjustment-table" class="border-table">
          <tr class="header">
            <th class="cell">Adjustment</th>
            <th class="cell">Type</th>
            <th class="cell">Value</th>
          </tr>
          <tr [formGroup]="fg_grossup">
            <td class="cell">Gross Up</td>
            <td class="cell">
              <app-price-adjustment-type-button
                formControlName="adjustment_type"
                (valueChanged)="gross_up_adjustment_changed($event)"></app-price-adjustment-type-button>
            </td>
            <td class="cell">
              <mat-form-field class="full-width-form-field" appearance="outline">
                <input matInput
                  type="number"
                  min="0"
                  step="0.25"
                  [placeholder]="gross_up_placeholder"
                  formControlName="adjustment_value" />
              </mat-form-field>
            </td>
          </tr>
          <tr [formGroup]="fg_discount">
            <td class="cell">Discount</td>
            <td class="cell">
              <app-price-adjustment-type-button
                formControlName="adjustment_type"
                (valueChanged)="discount_price_adjustment_changed($event)"></app-price-adjustment-type-button>
            </td>
            <td class="cell">
              <mat-form-field class="full-width-form-field" appearance="outline">
                <input matInput
                       type="number"
                       min="0"
                       step="0.25"
                      [placeholder]="discount_placeholder"
                      formControlName="adjustment_value" />
              </mat-form-field>
            </td>
          </tr>
        </table>
      </div>

      <div class="quote-section">
        <div class="section-header">
          <h4>Headline Costs</h4>
        </div>
        <table id="headline-cost-table" class="border-table">
          <tr class="header">
            <th class="cell">Headline Cost Item</th>
            <th class="cell">Total</th>
            <th class="cell">Total (inc markup)</th>
            <th class="cell">Total (inc markup, gross up)</th>
            <th class="cell">Total (inc markup, gross up, discount)</th>
          </tr>
          @for(headline_cost of headline_costs; track headline_cost.headline_cost) {
          <tr>
            <td class="cell">{{ headline_cost.headline_cost }}</td>
            <td class="cell">{{ headline_cost.total }}</td>
            <td class="cell">{{ headline_cost.total_inc_markup }}</td>
            <td class="cell">{{ headline_cost.total_inc_grossup.toFixed(3) }}</td>
            <td class="cell">{{ headline_cost.total_inc_discount.toFixed(3) }}</td>
          </tr>
          }
        </table>
      </div>

      <div class="quote-action-panel">
        <button mat-raised-button>{{ buttonText }}</button>
      </div>
    </form>
  </div>
</div>
</div>
