import { FormArray, FormControl, FormGroup } from "@angular/forms";
import Decimal from "decimal.js";
import { ItemType } from "./models";
import { Observable } from "rxjs";

export enum QuoteRowAction {
  DROP=0,
  DELETE
}

export enum InletType {
  INTO=0,
  ABOVE,
  BELOW,
  NONE
}

export interface IQuote {
  quote_id: string;
  quote_ref: string;
  customer_id: string;
  description: string;
  paragraph: string;
  consultant: string;
  attention: string;
  salutation: string;
  dtm_quote: string | Date;
  quote_items: IQuoteItem[];
  quote_item_hierarchy: IQuoteItem[];
}

export interface IQuoteItem {
  id?:number;
  quote_item_id?: string;
  quote_item_line_number: number;
  quote_item_part_number: number;
  item_line_number: number;
  item_part_number: number;
  item_part_type?: string;
  item_part_id?: string;
  quote_item_part_id?: string;
  item_composite_id?: string;
  description?: string;
  quote_item_description?: string;
  quantity?: number;
  price?: number;
  unit_price?: number;
  markup?: number;
  parts?: Partial<IQuoteItem>[];
  part_group_ref?: string;
  part_is_enclosure: boolean;
  configurations?: any;
  total: Decimal;
  totalWithMarkup: Decimal;
  selectedMarkup?: number;
  flg_price_override?: boolean;
}

export interface IQuoteItemWithId {
  internal_id: number;
  item_type: ItemType;
}

export interface IHotJobState {
  hot_job_state: number;
  quote_id: string;
}

export interface IConfiguratorPart {
  id: string;
  type: ItemType;
  description: string;
  part_group_ref: string;
  part_price: number;
  quantity: number;
  ref: string;
  markup: number;
  markups?: number[];
  parts?: IConfiguratorPart[];
  configurations?: any;
  part_is_enclosure: boolean;
  selectedMarkup?: number;
}

export interface IConfiguratorAggContextLeaseRequest {
  internal_id: number;
  aggregate_type: string;
  parent_id?: number;
  relinquish?: boolean;
  subscription?: Observable<any>;
}

export interface IQuoteListView {
  quote_id: string;
  quote_ref: string;
  customer_name: string;
  description: string;
  hot_job: number;
  dtm_quote: Date;
  summary: IQuoteSummary[];
  created_by: string;
  first_name: string;
  surname: string;
}

export interface IQuoteSummary {
  quote_id: string;
  part_group_ref: string;
  parts: IQuoteSummaryPart[],
  total: number,
  totalincmarkup: number;
}

export interface IQuoteSummaryPart {
  part_id: string;
  part_ref: string;
  quantity: number;
  unit_price: number;
  markup: number;
  total: number;
  totalincmarkup: number;
}

export interface IQuoteConfiguration {
  quote_id: string;
  configuration: IQuoteItemConfiguration[];
}

export interface IQuoteItemConfiguration {
  quote_item_configuration_sequence_id: string;
  quote_item_id: string;
  configuration_item_id: string;
  quote_item_quantity: number;
}

export interface IQuoteItemRowEvent {
  internal_id: number;
  line_number: number;
}

export interface IQuoteItemRowChange {
  action: QuoteRowAction;
  activeInlet: number;
  source: number;
  target?: number;
}

export interface IQuoteItemRowToggleEvent extends IQuoteItemRowEvent {
  checked: boolean;
}

export interface IQuoteItemRowInletEvent extends IQuoteItemRowToggleEvent {
  direction: InletType;
}

export interface IQuoteFormGroup {
  quote_id: FormControl<string|null>;
  quote_ref: FormControl<string|null>;
  description: FormControl<string|null>;
  paragraph: FormControl<string|null>;
  customer_id: FormControl<string|null>;
  consultant: FormControl<string|null>;
  attention: FormControl<string|null>;
  salutation: FormControl<string|null>;
  dtm_quote: FormControl<Date | string | null>;
  quote_items: FormArray<FormGroup<IQuoteItemFormGroup>>;
}

export interface IQuoteItemFormGroup {
  quote_item_id: FormControl<string>;
  item_part_type: FormControl<string>;
  item_part_id?: FormControl<string>;
  item_composite_id?: FormControl<string>;
  quote_item_description: FormControl<string>;
  quantity: FormControl<number>;
  unit_price?: FormControl<number>;
  markup?: FormControl<number>;
  parts?: FormArray<FormGroup<IQuoteItemFormGroup>>;
  item_line_number: FormControl<number>;
  item_part_number: FormControl<number>;
  configurations: FormArray<FormGroup<any>>;
  flg_price_override?: FormControl<boolean>;
}
