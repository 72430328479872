import { Injectable } from '@angular/core';
import { DataCollationEvent, Filter, FilterCollectionItem, ResetCollationEvent, Sorter, SortOrder } from '../controls/data-collation/shared/interfaces';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataCollationService {

  events: Subject<DataCollationEvent|ResetCollationEvent> = new Subject<DataCollationEvent|ResetCollationEvent>();
  sortlock: Subject<any> = new Subject<any>();

  filters: FilterCollectionItem[] = [];
  sorters: Sorter[] = [];

  private _reset: boolean = false;

  constructor() { }

  registerfilter(filtername: string, filtervalue: string[]) {
    let el = this.findfilter(filtername);
    let val = filtervalue.map((f) => encodeURIComponent(f));

    if (el) {
      el.value = val;
    } else {
      this.filters.push({
        'name': filtername,
        'value': val
      })
    }

    if (!filtervalue.length) {
      this.removefilter(filtername);
    }

    this.collate();
  }

  removefilter(filtername: string) {
    this.filters = this.filters.filter((filter: FilterCollectionItem) => {
      return filter.name != filtername;
    });

    if (!this._reset) {
      this.collate();
    }
  }

  registersorter(name: string, sortorder: SortOrder) {
    this.removesorter(name);
    this.sorters = [{'name': name,
      'order': sortorder}];
    this.sortlock.next({'name': name});

    this.collate();
  }

  removesorter(name: string) {
    this.sorters = this.sorters.filter((sorter: Sorter) => {
      return sorter.name != name;
    });
  }

  reset() {
    this._reset = true;
    this.collate();
  }

  private collate() {
    if (this._reset) {
      this.filters = [];
      this.sorters = [];
      this.events.next({collation: '', reset: true});
      this._reset = false;
    }
    this.events.next(this.makecollationevent())
  }

  private findfilter(filtername: string) {
    return this.filters.find((f: FilterCollectionItem) => {
      return f.name == filtername;
    });
  }

  private makecollationevent(): DataCollationEvent {
    let fparams = [];
    let sparams = [];
    let urlparams: string = '';

    for (let filter of this.filters) {
      fparams.push(`${filter.name}=${filter.value.join('|')}`);
    }

    for (let sorter of this.sorters) {
      sparams.push(`${sorter.name}-${sorter.order}`);
    }

    if (fparams.length) {
      const filterparams: string = `${fparams.join('&')}`;
      if (urlparams) {
        urlparams += `&${filterparams}`;
      } else {
        urlparams = filterparams;
      }
    }

    if (sparams.length) {
      const sortstring: string = `${sparams.join('|')}`
      if (urlparams) {
        urlparams += `&s=${sortstring}`;
      } else {
        urlparams = `s=${sortstring}`;
      }
    }

    return { collation: urlparams };
  }
}
