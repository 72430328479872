import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DataCollationService } from '../../../core/data-collation.service';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-date-filter',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormField,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule
  ],
  templateUrl: './date-filter.component.html',
  styleUrl: './date-filter.component.scss'
})
export class DateFilterComponent implements OnInit {
  private service = inject(DataCollationService);
  period: string = '';
  from: Date|null = null;
  to: Date|null = null;
  @Input() filtername!: string;

  isChecked(period: string) {
    if (this.period) {
      return this.period == period;
    }

    return false;
  }

  ngOnInit(): void {
      this.service.events.subscribe(e => {
        if ('reset' in e) {
          if (e.reset) {
            this.reset();
          }
        }
      });
  }

  updateDateFilter(e: MatCheckboxChange, period: string) {
    this.clear();
    if (!e.checked) {
      this.reset();
      return;
    }

    this.period = period;
    let to = new Date();
    this.to = to;

    if (period == 'WTD') {
      let dow = to.getDay();
      let offset = (6+dow)%7;
      let from = new Date();
      from.setDate(to.getDate() - offset);
      this.from = from;
    }

    if (period == 'MTD') {
      let from = new Date(to.getFullYear(), to.getMonth(), 1);
      this.from = from;
    }

    if (period == 'YTD') {
      let from = new Date(to.getFullYear(), 0, 1);
      this.from = from;
    }

    if (period == 'M3') {
      let fromMonth = (10 + to.getMonth())%12;
      let fromYear = this.to.getMonth() < 3 ? to.getFullYear() - 1 : to.getFullYear();
      this.from = new Date(fromYear, fromMonth, 0);
    }

    if (period == 'M6') {
      let fromMonth = (7 + to.getMonth())%12;
      let fromYear = this.to.getMonth() < 5 ? to.getFullYear() - 1 : to.getFullYear();
      this.from = new Date(fromYear, fromMonth, 0);
    }

    if (period == 'Y1') {
      let fromMonth = (13+this.to.getMonth())%12;
      this.from = new Date(this.to.getFullYear()-1, fromMonth, 1);
    }
    this.change();
  }

  change() {
    let start = this.getDateString(this.from);
    let end = this.getDateString(this.to);
    if (!start||!end) {
      return;
    }
    this.service.registerfilter(this.filtername, [start, end])
  }

  daysInLastNMonths(year: number, month: number, numMonths: number) {
    let thisMonth = month+1;
    let total = 0;

    for (let i=0; i < numMonths; i++) {
      total += new Date(year, thisMonth-i, 0).getDate();
    }

    return total;
  }

  clear() {
    this.period = '';
    this.from = null;
    this.to = null;
  }

  reset() {
    this.clear();
    this.service.removefilter(this.filtername);
  }

  getDateString(dt:Date|null) {
    let pad = function(c: string) {
      return c.padStart(2, '0');
    }

    if (dt) {
      return `${dt.getFullYear()}-${pad((dt.getMonth()+1).toString())}-${pad(dt.getDate().toString())}`;
    }

    return '';
  }
}
