import { FormArray, FormControl, FormGroup } from "@angular/forms";

export interface IEnumerableFormGroup {
  line_number: FormControl<number>;
  parts?: FormArray;
}

export class LineNumberManager {
  private linenumber: number = 1;
  private controls: FormArray<FormGroup<IEnumerableFormGroup>>;

  get curlinenumber(): number {
    return this.linenumber++;
  }

  constructor(controls: FormArray<FormGroup<IEnumerableFormGroup>>) {
    this.controls = controls;
  }

  reorder(curlinenumber= 1,
          container: FormArray<FormGroup<IEnumerableFormGroup>>|undefined= undefined) {
    if (!container) {
      this.linenumber = curlinenumber;
      container = this.controls;
    }

    for (let control of container.controls) {
      control.controls.line_number.setValue(curlinenumber++);

      if (control.controls.parts) {
        curlinenumber = this.reorder(curlinenumber, control.controls.parts);
        this.linenumber = curlinenumber
      } else {
        this.linenumber = curlinenumber;
      }
    }

    return curlinenumber
  }
}
