import { AbstractControl, FormGroup } from "@angular/forms";

abstract class FormGroupDecorator<T> extends FormGroup {
  constructor(formGroup: FormGroup) {
    super(formGroup.controls);
  }
}

export class FormGroupIdDecorator<T> extends FormGroupDecorator<T> {
  private __id: number;

  constructor(id: number, formGroup: FormGroup<any>) {
    super(formGroup);
    this.__id = id;
    this.controls = formGroup.controls;
  }

  get id(): number {
    return this.__id;
  }

  get parts(): any {
    return this.controls['parts'];
  }
}
