import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Quill from 'quill/core';
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Header from 'quill/formats/header';
import List from 'quill/formats/list';
import Link from 'quill/formats/link';

@Component({
  selector: 'app-quote-export',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './quote-export.component.html',
  styleUrl: './quote-export.component.scss'
})
export class QuoteExportComponent implements OnInit {
  @ViewChild('editor', {static: true})
  editor!: ElementRef<HTMLElement>;

  quill!: any;

  ngOnInit(): void {
    Quill.register({
      "modules/toolbar": Toolbar,
      "themes/snow": Snow,
      "formats/bold": Bold,
      "formats/italic": Italic,
      "formats/header": Header,
      "formats/underline": Underline,
      "formats/list": List,
      "formats/link": Link
    })
    this.quill = new Quill(this.editor.nativeElement, {
      theme: 'snow'
    });
  }

  showContents() {
    const contents = this.quill.getContents();
    console.log(JSON.stringify(contents));
  }
}
