import { Component, inject, OnInit } from '@angular/core';
import { QuoteService } from '../../plm/shared/quote.service';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { IPointSchedulePart, IPointSchedulePartConfiguration, IPointSchedulePartConfigurationFormGroup, IPointSchedulePartFormGroup, IPointScheduleReport, IPointScheduleReportFormGroup, IPointScheduleSectionFormGroup, IQuoteItemPointsListConfiguration, PointSchedulePart, PointSchedulePartConfiguration, PointScheduleReport, PointScheduleSection } from './models';
import { CommonModule } from '@angular/common';
import { ReportService } from '../../core/report.service';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { PartService } from '../../parts/shared/part.service';
import { IConfigurationCategory } from '../../parts/part-configuration/shared/models';
import { MatIconModule } from '@angular/material/icon';
import { PointsScheduleRowComponent } from './points-schedule-row/points-schedule-row.component';
import { IEnumerableFormGroup, LineNumberManager } from '../../core/line-number-manager';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardService } from '../../core/clipboard.service';
import { ReportConfigurationService } from '../shared/report-configuration.service';
import { IReportConfigurationCategory } from '../shared/interfaces';

@Component({
  selector: 'app-points-schedule',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    RouterModule,
    PointsScheduleRowComponent,
    DragDropModule
  ],
  providers: [
    ClipboardService,
    ReportConfigurationService
  ],
  templateUrl: './points-schedule.component.html',
  styleUrl: './points-schedule.component.scss'
})
export class PointsScheduleComponent implements OnInit {
  quoteService: QuoteService = inject(QuoteService);
  reportService: ReportService = inject(ReportService);
  partService = inject(PartService);
  clipboard: ClipboardService<IQuoteItemPointsListConfiguration> = inject(ClipboardService<IQuoteItemPointsListConfiguration>);
  confService: ReportConfigurationService<IReportConfigurationCategory> = inject(ReportConfigurationService);
  private lineManager!: LineNumberManager;

  private reportName = 'io';
  configurationCategories: IConfigurationCategory[] = [];
  configured: boolean = false;
  reportCategory: string = '';
  reportConfiguration: any = {};
  quote_id: string = '';
  modified: boolean = false;
  _editMode: boolean = false;
  buttonText: string = 'Save';

  categories: any = []
  loaded: boolean = false;
  quoteParts: IQuoteItemPointsListConfiguration[] = [];

  categoryTotals: number[] = [];

  _formGroup!: FormGroup<IPointScheduleReportFormGroup>;

  get sections(): FormArray<FormGroup<IPointScheduleSectionFormGroup>> {
    return this._formGroup.controls.sections as FormArray;
  }

  get colspan(): number {
    return 3 + this.categories.length;
  }

  get sparePercent(): number {
    return this._formGroup.controls.spare_percent.value;
  }

  get numCategories(): number {
    return this.categories.length;
  }

  get categorynames(): string[] {
    return this.categories.map((x: any) => { return x.configuration_item_name });
  }

  get editMode(): boolean {
    return this._editMode;
  }

  set editMode(on: boolean) {
    if (on) {
      this.buttonText = 'Update';
      this._editMode = true;
    }
  };

  constructor(route: ActivatedRoute) {
    this.quote_id = route.snapshot.params['quote_id'];
    this.reportConfiguration.report_name = 'io';
  }

  getquantityfor(category: string, partconfig: any) {
    const config = partconfig.find((x: any) => {
      return x.configuration_item_name == category;
    });

    if (config) {
      return config.quantity;
    }

    return '-';
  }

  ngOnInit(): void {
    this.reportService.configuration(this.reportName).subscribe({
      next: (config:any) => {

        if (config) {
          this.reportConfiguration = config;
          this.confService.configuration = config;
        }

        if (this.reportConfiguration.configuration_category_name) {
          this.reportCategory = this.reportConfiguration.configuration_category_name;
          this.configured = true;
          this.categories = config.configuration;

          this.loadReport();

          return;
        }

        this.partService.getConfigurations().subscribe(configurations => {
          this.configurationCategories = configurations;
          this.loaded = true;
        });
      },
    });
  }

  loadReport(report: IPointScheduleReport | undefined = undefined) {
    if (report) {
      this._makePointScheduleReport(report);
      this.editMode = true;
      this.prepare();
      return;
    }

    this.reportService.loadforquote(this.quote_id).subscribe(msg => {
      if (!msg.quote_id) {
        msg.quote_id = this.quote_id;
      }
      this._makePointScheduleReport(msg);
      if (msg.sequence_id) {
        this.editMode = true;
      }
      this.prepare();
    });
  }

  _makePointScheduleReport(plReport: IPointScheduleReport) {
    let report = new PointScheduleReport(plReport);
    this._formGroup = report.formGroup;
  }

  prepare() {
    this.loadQuoteConfiguration();

    for (var i = 0; i < this.categories.length; i++) {
      this.categoryTotals.push(0);
    }

    const sectionsFg = this._formGroup.controls.sections as unknown as FormArray<FormGroup<IEnumerableFormGroup>>;
    this.lineManager = new LineNumberManager(sectionsFg);
    this.updateCategoryTotals();
    this.reorder();

    this._formGroup.valueChanges.subscribe(_ => {
      this.updateCategoryTotals();
      this.modified = true;
    });
    this.loaded = true;
  }

  loadQuoteConfiguration() {
    if (this.quoteParts.length) {
      return;
    }

    this.quoteService.loadquoteconfiguration(
      this.quote_id,
      this.reportCategory).subscribe(
      items => {
        this.quoteParts = items;
      });
  }

  getcategoryname(index: number): string {
    return this.categories[index]['configuration_item_name'];
  }

  categorynamebyid(id: string): string {
    let category = this.categories.filter((c: any) => { return c.configuration_item_id == id });

    if (category.length == 1) {
      return category[0]['configuration_item_name'] as string;
    }

    return 'N/A';
  }

  getsectionfg(fg_index: number): FormGroup<IPointScheduleSectionFormGroup> {
    return this.sections.controls[fg_index];
  }

  getsectionparts(idx: number): FormArray<FormGroup<IPointSchedulePartFormGroup>> {
    return this.sections.at(idx).controls.parts;
  }

  getsectionpart(section_idx: number,
                 part_idx: number
  ): FormGroup<IPointSchedulePartFormGroup> {
    return this.getsectionparts(section_idx).at(part_idx);
  }

  getscheduleconfiguration(section_index: number,
                           part_index: number
  ): FormArray<FormGroup<IPointSchedulePartConfigurationFormGroup>> {
    return this.getsectionpart(section_index, part_index).controls.configuration as FormArray;
  }

  categorySpareTotal(categoryIdx: number) {
    if (this.categoryTotals[categoryIdx]) {
      return Math.ceil(
        this.categoryTotals[categoryIdx] * (1 + this.sparePercent/100));
    }

    return 0;
  }

  addSection() {
    let fg = this.makeSectionFormGroup();
    this.sections.push(fg);
  }

  addPartToSection(idx: number) {
    let part = new PointSchedulePart({
      line_number: this.lineManager.curlinenumber
    });
    for (let category of this.categories) {
      let partfg = part.formGroup;
      let configuration = this._makePointSchedulePartconfiguration({
        'configuration_item_id': category.configuration_item_id,
        'configuration_item_name': category.configuration_item_name,
      });
      let scheduleconfiguration = new PointSchedulePartConfiguration(configuration);

      partfg.controls.configuration.push(
        scheduleconfiguration.formGroup);
    }
    this.sections.at(idx).controls.parts.push(part.formGroup);
  }

  makeSectionFormGroup() {
      let newSection = new PointScheduleSection({
        'line_number': this.lineManager.curlinenumber
      });

      return newSection.formGroup;
  }

  _makePointSchedulePartconfiguration(
    part: Partial<IPointSchedulePartConfiguration>
  ): IPointSchedulePartConfiguration {
    return {
      'sequence_id': '',
      'configuration_item_id': part.configuration_item_id || '',
      'configuration_item_name': part.configuration_item_name || '',
      'quote_item_configuration_id': '',
      'quote_quantity': 0,
      'quantity': 0
    }
  }

  copypart(idx: number) {
    this.clipboard.copy(this.quoteParts[idx]);
  }

  updateCategoryTotals(): void {
    let totals = [];
    for (let section of this._formGroup.controls.sections.controls) {
      for (let part of section.controls.parts.controls) {
        for (var i = 0; i < part.controls.configuration.length; i++) {
          let configuration = part.controls.configuration.at(i);
          if (totals[i]) {
            totals[i] += configuration.controls.quantity.value;
          } else {
            totals[i] = configuration.controls.quantity.value;
          }
        }
      }
    }

    for (var i = 0; i < this.categoryTotals.length; i++) {
      this.categoryTotals[i] = totals[i] || 0;
    }
  }

  configureReport() {
    this.reportConfiguration.configuration_category_name =
      this.reportCategory;
    this.reportService.updateConfiguration(this.reportConfiguration).subscribe(
      (newConfig: any) => {
        this.categories = newConfig.configuration;
        this.loadReport();
        this.configured = true;
      })
  }

  deletesection(idx: number) {
    this._formGroup.controls.sections.removeAt(idx);
    this.reorder();
  }

  drop(sectionIdx: number, e: any) {
    let formArray = this.getsectionparts(sectionIdx);
    let item = formArray.at(e.previousIndex);
    formArray.removeAt(e.previousIndex);
    formArray.insert(e.currentIndex, item);
    this.reorder();
  }

  reorder() {
    this.lineManager.reorder();
  }

  save() {
    if (this._formGroup.valid) {
      if (this.editMode) {
        this.reportService.updatepointlist(
          this._formGroup.value as IPointScheduleReport
        ).subscribe({
          next: (report) => {
            this.loadReport(report);
            this.modified = false;
          }
        });
      } else {
        this.reportService.savepointslist(
          this._formGroup.value as IPointScheduleReport).subscribe({
          next: (report) => {
            this.loadReport(report);
            this.modified = false;
          }
        });
      }
    }
  }
}
