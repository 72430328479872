import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { QuoteHeaderComponent } from '../../../quote-header/quote-header.component';
import { TemplateService } from '../../template.service';
import { make_section_template_formgroup } from '../../factories';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RichTextInputComponent } from '../../../../../controls/rich-text-input/rich-text-input.component';
import { MessagesComponent } from '../../../../../notifications/messages/messages.component';
import { IDocumentSectionTemplateFormGroup } from '../../interfaces';

@Component({
  selector: 'app-section-template-edit',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    QuoteHeaderComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RichTextInputComponent,
    MessagesComponent
  ],
  templateUrl: './section-template-edit.component.html',
  styleUrl: './section-template-edit.component.scss'
})
export class SectionTemplateEditComponent implements OnInit {
  service: TemplateService = inject(TemplateService);
  route: ActivatedRoute = inject(ActivatedRoute);
  fg!: FormGroup<IDocumentSectionTemplateFormGroup>;
  edit_mode: boolean = false;
  system_message: string = '';

  get txt_btn_submit(): string {
    if (this.edit_mode) return 'Update';

    return 'Save';
  }

  get txt_system_msg(): string {
    if (this.edit_mode) return 'Updates successfully saved';

    return 'Template successfully created';
  }

  constructor() {
  }

  ngOnInit() {
    let template_id = this.route.snapshot.params['id'] || undefined;
    if (template_id) {
      this.service.loadSectionTemplate(template_id).subscribe(template => {
        this.fg = make_section_template_formgroup(template);
        this.edit_mode = true;
      });
    } else {
      this.fg = make_section_template_formgroup();
    }
  }

  save() {
    if (this.fg.valid) {
      let fg_value = this.fg.value;
      if (!this.edit_mode) {
        if (fg_value.section_name && fg_value.section_content) {
          this.service.saveSectionTemplate(
            fg_value.section_name,
            fg_value.section_content).subscribe(new_template => {
              this.fg.patchValue(new_template);
              this.system_message = this.txt_system_msg;
              this.edit_mode = true;
            });
        }
      } else {
        if (fg_value.section_template_id &&
            fg_value.section_name &&
            fg_value.section_content) {
          this.service.updateSectionTemplate(
            fg_value.section_template_id,
            fg_value.section_name,
            fg_value.section_content).subscribe(_ => {
              console.log("successfully updated!");
              this.system_message = this.txt_system_msg;
            });
        }
      }
    }
  }
}
