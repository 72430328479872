<div id="not-configured" *ngIf="loaded && !configured">
  <form id="report-configuration-form" class="data-form" (submit)="configureReport()">
    <fieldset>
      <legend><mat-icon style="vertical-align: text-top;">warning</mat-icon>Configure Report</legend>
      <p>
        This report must be associated with a configuration category.
        If you would like to create a new one, please click
        <a [routerLink]="['/parts/configurations/add']">here</a>
      </p>
      <mat-form-field>
        <mat-label>Select Category</mat-label>
        <mat-select [(ngModel)]="reportCategory" name="category">
          <mat-option *ngFor="let c of configurationCategories"
            [value]="c.category_name">{{ c.category_name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </fieldset>

    <div class="action-panel">
      <button mat-raised-button>Configure</button>
    </div>
  </form>
</div>

<div id="points-schedule-report" *ngIf="loaded && configured">
  <form id="point-schedule-form"
    class="two-column"
    [formGroup]="_formGroup"
    (submit)="save()">
    <fieldset>
      <legend>Report Details</legend>

      <mat-form-field class="full-width">
        <mat-label>Title</mat-label>
        <input matInput type="text"
          formControlName="title" />
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Spare Percentage</mat-label>
        <input matInput type="number"
          formControlName="spare_percent"/>
      </mat-form-field>

      <fieldset class="sub-section" style="margin-bottom: 1em;">
        <legend>Sections</legend>

        <button mat-raised-button
                type="button"
                (click)="addSection()">
          Add
        </button>
      </fieldset>

      <fieldset class="sub-section">
        <legend>Parts</legend>
        <div id="quote-parts" class="part-container">
        @for (part of quoteParts; track $index) {
          <div class="part" style="box-sizing: border-box; border: 1px solid #000; padding: 0.25em; margin-bottom: .5em;">
            <div class="part-header">
              <h5>{{ part.part_ref }}</h5>
              <button mat-icon-button
                type="button"
                (click)="copypart($index)">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
            <div class="part-body">
              <p class="description">
                {{ part.quote_item_description }}
              </p>
              <table class="data-table">
                <thead>
                  <tr>
                    <th [attr.colspan]="numCategories">
                      {{ this.reportCategory }}
                    </th>
                  </tr>
                  <tr>
                    <th *ngFor="let c of categorynames">
                      {{ c }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td *ngFor="let c of categorynames">
                      {{ getquantityfor(c, part.configuration) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
        </div>
      </fieldset>
    </fieldset>
    <fieldset>
      <legend>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <span>Report <span *ngIf="modified" style="font-size: 0.8em; vertical-align: text-top;">*</span></span>
          <button mat-icon-button>
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </legend>
      <table class="data-table">
        <thead>
          <tr>
            <th class="action-panel-container"></th>
            <th style="min-width: 300px"></th>
            <th style="width: 90px"
              *ngFor="let category of categories; let idx = index">
              {{ getcategoryname(idx) }}
            </th>
            <th style="width: 20%; max-width: 250px;">Notes</th>
          </tr>
        </thead>
        <tbody *ngFor="let section of sections.controls; index as i">
          <tr>
            <td [attr.colspan]="colspan"
                style="padding: 0;">
              <table class="data-table" style="border: 0;">
                <tr [formGroup]="getsectionfg(i)"
                  style="background: #CCC; border-bottom: 1px solid #000;">
                  <td class="action-panel-container">
                    <div class="action-panel">
                    <span>
                      {{ section.controls.line_number.value }}
                    </span>
                    <mat-icon (click)="deletesection(i)"
                      style="width: 1em; height: 1em; font-size: 1em; vertical-align: text-top;">
                      delete
                    </mat-icon>
                    <mat-icon (click)="addPartToSection(i)"
                      style="width: 1em; height: 1em; font-size: 1em; vertical-align: text-top;">
                      add
                    </mat-icon>
                    </div>
                  </td>
                  <td style="min-width: 300px;">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Section Name</mat-label>
                      <input matInput
                             type="text"
                             formControlName="name" />
                    </mat-form-field>
                  </td>
                  <td *ngFor="let category of categories"
                    style="width: 90px;"></td>
                  <td style="width: 20%; max-width: 200px;"></td>
                </tr>
                <tbody cdkDropList class="drag-boundary"
                       cdkDragLockAxis="y"
                       (cdkDropListDropped)="drop(i, $event)">
                  <tr points-schedule-row
                      cdkDrag
                      cdkDragBoundary=".drag-boundary"
                      *ngFor="let fg of getsectionparts(i).controls; let section_part_idx = index"
                      [scheduleconfiguration]="getscheduleconfiguration(i, section_part_idx)"
                      [fg]="fg"
                      (reorder)="reorder()">
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>Total: </td>
            <td class="table-center-content"
              *ngFor="let category of categories; let i = index">
              {{ categoryTotals[i] }}
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Spare: </td>
            <td class="table-center-content"
              *ngFor="let category of categories; let i = index">
              {{ categorySpareTotal(i) }}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      <div style="text-align: right; margin-top: 1em;">
        <button mat-raised-button>{{ buttonText }}</button>
      </div>
    </fieldset>
  </form>
</div>
