import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { RichTextInputComponent } from '../../../controls/rich-text-input/rich-text-input.component';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TemplateService } from '../templates/template.service';
import { IQuoteExportFormGroup, IQuoteExportSectionFormGroup, IDocumentSectionTemplate, IDocumentTemplate } from '../templates/interfaces';
import { Delta } from 'quill/core';
import { SelectDocumentCategoryComponent } from '../../../controls/select-document-category/select-document-category.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { DocumentService } from '../documents/document.service';
import { QuoteService } from '../../shared/quote.service';
import { IQuoteDocumentsByCategory } from '../shared/interfaces';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-quote-export',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RichTextInputComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    SelectDocumentCategoryComponent,
    RouterModule,
    MatMenuModule
  ],
  templateUrl: './quote-export.component.html',
  styleUrl: './quote-export.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class QuoteExportComponent implements OnInit {
  private doc_service: DocumentService = inject(DocumentService);
  private service: TemplateService = inject(TemplateService);
  private quote_service: QuoteService = inject(QuoteService);

  export_sections!: Partial<IDocumentSectionTemplate>[];
  document_templates!: Partial<IDocumentTemplate>[];
  documents: IQuoteDocumentsByCategory[] = [];

  show_export: boolean = false;
  show_create_new: boolean = false;
  _edit_mode: boolean = false;
  quote_id: string;

  set edit_mode(on: boolean) {
    if (on) {
      this.show_export = true;
    } else {
      this.show_export = false;
    }

    this._edit_mode = on;
  }

  get edit_mode() {
    return this._edit_mode;
  }

  get document_id() {
    return this.fg.controls.quote_document_id.value;
  }

  protected fg: FormGroup<IQuoteExportFormGroup> = new FormGroup<IQuoteExportFormGroup>({
    'quote_document_id': new FormControl<string>('', {nonNullable: true}),
    'quote_id': new FormControl<string>('', {nonNullable: true}),
    'name': new FormControl<string>('', {nonNullable: true,
                                            validators: [Validators.required]}),
    'quote_document_type': new FormControl<string>('', {nonNullable: true,
                                            validators: [Validators.required]}),
    'sections': new FormArray<FormGroup<IQuoteExportSectionFormGroup>>([])
  });

  get sections(): FormArray<FormGroup<IQuoteExportSectionFormGroup>> {
    return this.fg.controls.sections;
  }

  constructor(private activeRoute: ActivatedRoute) {
    this.quote_id = this.activeRoute.snapshot.params['id'];
    this.fg.controls.quote_id.setValue(this.quote_id);
  }

  ngOnInit(): void {
    this.service.loadSectionTemplates().subscribe(sections => {
      this.export_sections = sections;
    });

    this.service.list_document_templates().subscribe(templates => {
      this.document_templates = templates;
    });

    this.quote_service.load_quote_documents(this.quote_id).subscribe(docs => {
      this.documents = docs;
    });
  }

  addSection(section: any=undefined): void {
    this.sections.push(this.make_section_fg(section));
    this.reorder();
  }

  add_section_template(id: string|undefined) {
    if (id) {
      this.service.loadSectionTemplate(id).subscribe(section => {
        this.addSection(section);
      });
    }
  }

  add_document_template(id: string|undefined) {
    if (id) {
      this.service.load_document_template_content(id).subscribe(template => {
        this.fg.controls.quote_document_type.setValue(template.document_type);
        for (let section of template.sections) {
          this.addSection(section);
        }
      });
    }

    this.reorder();
  }

  reorder() {
    let _section = 1;
    for (let section of this.sections.controls) {
      section.controls.section_number.setValue(_section++);
    }
  }

  make_section_fg(section: any): FormGroup<IQuoteExportSectionFormGroup> {
    return new FormGroup<IQuoteExportSectionFormGroup>({
      'quote_document_section_id': new FormControl<string>(section?.section_id || '', {nonNullable: true}),
      'quote_document_id': new FormControl<string>(section?.quote_document_id || '', {nonNullable: true}),
      'section_number': new FormControl<number>(section?.section_number || 0, {nonNullable: true}),
      'section_name': new FormControl<string>(section?.section_name || '', {nonNullable: true}),
      'section_content': new FormControl<Delta>(
        section?.section_content || new Delta(), {nonNullable: true})
    });
  }

  update_document() {
    if (this.fg.valid) {
      this.doc_service.create_document(this.fg.value).subscribe(new_document => {
        console.log("created");
        if (!this.edit_mode) {
          this.edit_mode = true;
        }
      });
    }
  }

  new_document() {
    console.log("adding new document");
    this.edit_mode = false;
  }

  open_document(id: string) {
    this.fg.reset();
    this.sections.clear();

    this.quote_service.load_quote_document(this.quote_id, id).subscribe(doc => {
      this.fg.controls.quote_document_id.setValue(doc.quote_document_id),
      this.fg.controls.name.setValue(doc.name);
      this.fg.controls.quote_document_type.setValue(doc.quote_document_type);
      for (let section of doc.sections) {
        this.addSection(section);
      }
      this.reorder();
      this.edit_mode = true;
    });
  }

  get_export_link() {
    return this.doc_service.get_print_link(this.document_id);
  }
}
