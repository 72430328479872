<app-quote-header></app-quote-header>

<div class="filter-panel-container">
  <form>
    <div class="filter-panel">
      <div style="height: 58px; display: flex; align-items: center;">
        <mat-icon>filter_list</mat-icon>
      </div>
      <app-dropdown-filter *ngFor="let filter of filters" [filterName]="filter.filtername"
         [label]="filter.label"
         [data]="filter.filter">
      </app-dropdown-filter>
      <app-date-filter [filtername]="'d'"></app-date-filter>
      <div style="height: 58px; display: flex; align-items: center;">
        <button type="button"
                color="primary"
                (click)="resetfilters()"
                mat-flat-button>
          Reset
        </button>
      </div>
    </div>
  </form>
</div>

<div class="hyp-list" *ngIf="quotes">
  <div id="user-header" class="hyp-list-row">
    <h4 class="hyp-action hyp-list-item small-action" style="margin-right: 1em; vertical-align: middle; position: relative;">
      <mat-icon class="material-symbols-outlined" style="position: absolute; top: 10%; left: 20%;">mode_heat</mat-icon>
      <app-sorter [name]="'h'" style="position: absolute; right: -0.5em;"></app-sorter>
    </h4>
    <h4 class="hyp-list-item">Quote Reference <app-sorter [name]="'ref'"></app-sorter></h4>
    <h4 class="hyp-list-item">Customer <app-sorter [name]="'cust'"></app-sorter></h4>
    <h4 class="hyp-list-item">Sales Rep <app-sorter [name]="'rep'"></app-sorter></h4>
    <h4 class="hyp-list-item">Date <app-sorter [name]="'dt'"></app-sorter></h4>
    <span class="hyp-action hyp-list-item full-panel"></span>
  </div>

  <div *ngFor="let quote of quotes" class="hyp-list-column">
    <div class="hyp-list-row">
      <span class="hyp-action hyp-list-item small-action" style="margin-right: 1em">
        <app-quote-hot-job [quote]="quote"></app-quote-hot-job>
      </span>
      <span class="hyp-list-item">{{ quote.quote_ref }}</span>
      <span class="hyp-list-item">{{ quote.customer_name }}</span>
      <span class="hyp-list-item">{{ quote.first_name }} {{ quote.surname }}</span>
      <span class="hyp-list-item">{{ quote.dtm_quote | date: 'dd/MM/YYYY' }}</span>
      <div class="hyp-action hyp-list-item full-panel">
        <div class="panel">
          <div class="action">
            <button mat-icon-button
              type="button"
              matTooltip="Quote Summary"
              (click)="toggleSummary(quote.quote_id)">
              <mat-icon class="material-symbols-outlined">read_more</mat-icon>
            </button>
          </div>
          <div class="action">
            <button mat-icon-button
              matTooltip="Detailed Breakdown"
              type="button">
              <mat-icon class="material-symbols-outlined">summarize</mat-icon>
            </button>
          </div>
          <div class="action">
            <a mat-icon-button
              matTooltip="Edit Printed Quote"
              [routerLink]="['/quotes', quote.quote_id, 'export']">
              <mat-icon class="material-symbols-outlined">contract_edit</mat-icon>
            </a>
          </div>
          <div class="action">
            <a mat-icon-button
              matTooltip="Schedule"
              [routerLink]="['/reports/io', quote.quote_id]">
              <svg width="24" hieght="24">
                <image xlink:href="chip.svg" />
              </svg>
            </a>
          </div>
          <div class="action">
            <a mat-icon-button
              matTooltip="Edit"
              [routerLink]="['/quotes', quote.quote_id]"><mat-icon>edit</mat-icon></a>
          </div>
          <div class="action">
            <button mat-icon-button type="button" [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu>
              <menu mat-menu-item>
                <a [href]="downloadlink(quote.quote_id)" style="text-decoration: none; font-weight: bold; color: #000;" download>Download Points List</a>
              </menu>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="hyp-list-row"
      *ngIf="showSummary(quote.quote_id)"
      [ngClass]="showSummary(quote.quote_id) ? 'row-active': 'row-hidden'">
      <div class="hyp-list-item">
        <app-summary-chart [summary]="quote.summary"></app-summary-chart>
      </div>
      <div class="hyp-list-item">
        <app-summary-table [summary]="quote.summary"></app-summary-table>
      </div>
    </div>
  </div>
</div>

