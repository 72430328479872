import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { InletType, IQuoteItem, IQuoteItemFormGroup, IQuoteItemRecordStatus, IQuoteItemRowChange, IQuoteItemRowInletEvent, IQuoteItemRowToggleEvent, QuoteItemRecStatus, QuoteRowAction } from '../../../shared/interfaces';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import Decimal from 'decimal.js';
import { QuoteService } from '../../../../shared/quote.service';
import { Subscription } from 'rxjs';
import { FormGroupIdDecorator } from '../../../../../core/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ItemType } from '../../../shared/models';

@Component({
  selector: '[quote-item-row]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    DragDropModule,
    MatButtonModule
  ],
  templateUrl: './quote-item-row.component.html',
  styleUrl: './quote-item-row.component.scss'
})
export class QuoteItemRowComponent implements OnInit, OnDestroy {
  quoteService: QuoteService = inject(QuoteService);

  markup_step = 0.05;

  @Input() item!: FormGroup<IQuoteItemFormGroup>;

  activeInlet: InletType = InletType.NONE;
  activeInletSubscription: Subscription;
  rowChangeSubscription!: Subscription;
  inFinishedGoodContext: boolean = false;
  lineNumberSubscription!: Subscription|undefined;
  parentFgSubscription: Subscription|undefined;
  unitPriceSubscription: Subscription|undefined;
  lineNumberChanged: boolean = false;

  get configurations(): FormArray<FormGroup<any>> {
    return this.item.controls.configurations;
  }

  get line_number(): number {
    return this.item.value.item_line_number || 0;
  }

  get parent_container(): FormArray {
    return this.item.parent as FormArray;
  }

  get my_index(): number {
    let parent = this.item.parent?.value;
    let myidx: number = -1;
    if (parent) {
      myidx = parent.findIndex((item: IQuoteItem) => {
        return item.item_line_number == this.line_number;
      });
    }

    return myidx;
  }

  get quote_item_id(): string {
    return this.item.controls.quote_item_id.value;
  }

  get parts(): FormArray<FormGroupIdDecorator<IQuoteItemFormGroup>> {
    return this.item.controls.parts as FormArray;
  }

  get itemType(): ItemType {
    return this.item.value.item_part_type as ItemType || ItemType.PART;
  }

  get internal_id(): number {
    let fg = this.item as unknown as FormGroupIdDecorator<IQuoteItemFormGroup>;
    return fg.id;
  }

  get price_modified(): boolean {
    return this.item.value.flg_price_override || false;
  }

  get InletType(): typeof InletType {
    return InletType;
  }

  inletOpen(inlet_type: InletType) {
    return this.activeInlet == inlet_type;
  }

  constructor() {
    this.activeInletSubscription =
      this.quoteService.activeInlet.subscribe(
        (toggleEvent: IQuoteItemRowInletEvent) => {
          if (toggleEvent.checked && this.line_number != toggleEvent.line_number) {
            this.activeInlet = InletType.NONE;
          }
        }
    );
  }

  ngOnInit(): void {
    this.rowChangeSubscription =
      this.quoteService.quoteRowChange.subscribe(
        (event: IQuoteItemRowChange) => {
          if (event.action == QuoteRowAction.DELETE) {
            console.log(`row ${event.source} was deleted`);
          }

          if (event.action == QuoteRowAction.DROP) {
            console.log(`Moved row ${event.source} to ${event.target}.`)
          }
        }
      )

    this.lineNumberSubscription =
      this.item.controls.item_line_number.valueChanges.subscribe(_ => {
      if (this.activeInlet != InletType.NONE) {
        this.lineNumberChanged = true;
      }
    });

    this.parentFgSubscription =
      this.item.parent?.valueChanges.subscribe(_ => {
      if (this.activeInlet != InletType.NONE) {
        if (this.lineNumberChanged) {

          let _parent: any = this.item;
          if (this.activeInlet != InletType.INTO) {
            if (this.item.parent) {
              _parent = this.getParent(this.item.parent);
            }
          }

          let internal_id = 0;
          if (_parent instanceof FormGroupIdDecorator) {
            internal_id = _parent.id;
          }

          this.quoteService.openInlet({
            internal_id: internal_id,
            line_number: this.line_number,
            checked: true,
            direction: this.activeInlet,
          });

          this.lineNumberChanged = false;
        }
      };
    });

    if ([ItemType.PART, ItemType.ENCLOSURE].includes(this.itemType)) {
      this.unitPriceSubscription = this.item.controls.unit_price?.valueChanges.subscribe(_ => {
        this.item.controls.flg_price_override?.setValue(true);
        this.item.controls.markup?.setValue(1);
      });
    }
  }

  configurationItems(idx: number): FormArray<FormGroup<any>> {
    return this.configurations.at(idx).controls['configuration'] as FormArray;
  }

  toggleInlet(inlet_type: InletType) {
    if (inlet_type == InletType.NONE) {
      return;
    }

    if (this.activeInlet == inlet_type) {
      this.activeInlet = InletType.NONE;
    } else {
      this.activeInlet = inlet_type;
    }

    let _parent: any = this.item;
    if (inlet_type != InletType.INTO) {
      if (this.item.parent) {
        _parent = this.getParent(this.item.parent);
      }
    }

    let internal_id = 0;
    if (_parent instanceof FormGroupIdDecorator) {
      internal_id = _parent.id;
    }

    this.quoteService.openInlet({
      internal_id: internal_id,
      line_number: this.line_number,
      checked: this.activeInlet != InletType.NONE,
      direction: inlet_type
    });
  }

  editFinishedGood() {
    return false;
  }

  pasteItem() {
    return;
  }

  canPaste() {
    return false;
  }

  canCopy() {
    return this.item.value.item_part_number;
  }

  copyItem() {
    return;
  }

  deleteItem() {
    this.quoteService.delete_item(this.item.value).subscribe({
      next: (rec_status: IQuoteItemRecordStatus) => {
        if (rec_status.record_status == QuoteItemRecStatus.DELETED) {
          this.parent_container.removeAt(this.my_index);

          this.quoteService.rowChange({
            action: QuoteRowAction.DELETE,
            source: this.line_number,
            activeInlet: this.activeInlet
          });
        }
      },
      error: (_) => {
        console.log("there was an error processing your request");
      }
    });
  }

  quoteItemTotal(item: FormGroup<IQuoteItemFormGroup> | undefined): Decimal {
    if (item) {
      return this.quoteService.quotePartTotal(item);
    }

    return new Decimal(0);
  }

  quoteItemTotalIncMarkup(item: FormGroup<IQuoteItemFormGroup>): Decimal {
    if (item) {
      return this.quoteService.quotePartTotalIncMarkup(item);
    }

    return new Decimal(0);
  }

  quoteItemAggTotal(item: FormGroup<IQuoteItemFormGroup>): Decimal {
    return this.quoteService.aggregatePartTotal(item);
  }

  quoteItemAggTotalIncMarkup(
    item: FormGroup<IQuoteItemFormGroup>): Decimal {
    return this.quoteService.aggregatePartTotalIncMarkup(item);
  }

  drop(e: CdkDragDrop<string[]>) {
    if (this.parts.length) {
      let item = this.parts.at(e.previousIndex);
      this.parts.removeAt(e.previousIndex);
      this.parts.insert(e.currentIndex, item);
    }
  }

  getcontrols(items: FormArray<FormGroup<IQuoteItemFormGroup>>|undefined) {
    if (items) {
      return items.controls;
    }

    return [];
  }

  getParent(container: FormGroup<any>|FormArray<any>): AbstractControl | null {
    if (!container) {
      container = this.item;
    }

    if (container instanceof FormGroup) {
      return container;
    }

    if (container.parent) {
      return this.getParent(container.parent);
    }

    return null;
  }

  ngOnDestroy(): void {
      this.activeInletSubscription.unsubscribe();
      if (this.lineNumberSubscription) {
        this.lineNumberSubscription.unsubscribe();
      }
      if (this.parentFgSubscription) {
        this.parentFgSubscription.unsubscribe();
      }
  }
}
