import { Component, computed, inject, Input, OnDestroy, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InletType, IQuoteItem, IQuoteItemFormGroup, IQuoteItemRecordStatus, IQuoteItemRowToggleEvent, QuoteItemRecStatus, QuoteRowAction } from '../../../shared/interfaces';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ItemType } from '../../../shared/models';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuoteService } from '../../../../shared/quote.service';
import { Subscription } from 'rxjs';
import { FormGroupIdDecorator } from '../../../../../core/forms';

@Component({
  selector: '[quote-item-nett-price]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './quote-nett-price-row.component.html',
  styleUrl: './quote-nett-price-row.component.scss'
})
export class QuoteNettPriceRowComponent implements OnInit, OnDestroy {
  quoteService = inject(QuoteService);

  activeInlet: InletType = InletType.NONE;
  activeInletSubscription: Subscription;
  lineNumberSubscription!: Subscription | undefined;
  parentFgSubscription!: Subscription | undefined;
  line_number_changed: boolean = false;

  get InletType(): typeof InletType {
    return InletType;
  }

  @Input() item!: FormGroup<IQuoteItemFormGroup>;
  @Input() quote!: FormArray<FormGroup<IQuoteItemFormGroup>>;

  parts: WritableSignal<number[]> = signal([]);
  strParts: Signal<string> = computed(() => {
    if (this.parts().length == 1) {
      return `part ${this.parts()[0]}`;
    }
    return `parts ${this.parts()[0]} - ${this.parts().slice(-1)[0]}`;
  });
  total: WritableSignal<number> = signal(0);
  errorState: boolean = false;

  get internal_id(): number {
    let fg = this.item as unknown as FormGroupIdDecorator<IQuoteItemFormGroup>;
    return fg.id;
  }

  get parent_container(): FormArray {
    return this.item.parent as FormArray;
  }

  get my_index(): number {
    let parent = this.item.parent?.value;
    let myidx: number = -1;
    if (parent) {
      myidx = parent.findIndex((item: IQuoteItem) => {
        return item.item_line_number == this.line_number;
      });
    }

    return myidx;
  }

  get quote_item_id(): string {
    return this.item.controls.quote_item_id.value;
  }

  get line_number(): number {
    return this.item.value.item_line_number || 0;
  }

  inletOpen(inlet_type: InletType) {
    return this.activeInlet == inlet_type;
  }

  constructor() {
    this.activeInletSubscription =
      this.quoteService.activeInlet.subscribe(
        (toggleEvent: IQuoteItemRowToggleEvent) => {
          if (toggleEvent.checked && this.line_number != toggleEvent.line_number) {
            this.activeInlet = InletType.NONE;
          }
        }
    );
  }

  ngOnInit() {
    this.calcTotal();
    this.quote.valueChanges.subscribe(_ => {
      this.calcTotal();
    });

    this.lineNumberSubscription =
      this.item.controls.item_line_number.valueChanges.subscribe(_ => {
      if (this.activeInlet != InletType.NONE) {
        this.line_number_changed = true;
      }
    });

    this.parentFgSubscription =
      this.item.parent?.valueChanges.subscribe(_ => {
      if (this.activeInlet != InletType.NONE) {
        if (this.line_number_changed) {
          this.quoteService.openInlet({
            internal_id: 0,
            line_number: this.line_number,
            checked: true,
            direction: this.activeInlet,
          });

          this.line_number_changed = false;
        }
      };
    });
  }

  ngOnDestroy() {
    this.activeInletSubscription.unsubscribe();
    if (this.lineNumberSubscription) {
      this.lineNumberSubscription.unsubscribe();
    }
    if (this.parentFgSubscription) {
      this.parentFgSubscription.unsubscribe();
    }
  }

  calcTotal() {
    let total = 0;
    let _parts = [];
    let myidx = 0;

    for (let _item of this.quote.controls) {
      if (_item.value.item_line_number &&
          _item.value.item_line_number < this.linenumber) {
        myidx++;
      } else {
        break;
      }
    }

    if (myidx < 1) {
      this.errorState = true;
      return;
    }

    if (this.errorState) {
      this.errorState = false;
    }

    for (let i = myidx-1; i >= 0; i--) {
      let qitem = this.quote.at(i);
      if (qitem) {
        let item = qitem.value;
        if (item.item_part_type == ItemType.NETTPRICE) {
          break;
        }

        if (item.item_part_number) {
          _parts.push(item.item_part_number);
        }

        total += this.getItemTotal(item);
      }
    }

    if (!_parts[0]) {
      this.errorState = true;
      return;
    }

    _parts.sort();
    this.parts.set(_parts);
    this.total.set(total);
  }

  getItemTotal(item: Partial<IQuoteItem>, total: number = 0): number {
    if (item.parts) {
      for (let _item of item.parts) {
        total = this.getItemTotal(_item, total);
      }
    }

    if (item.quantity && item.unit_price) {
      total += (item.quantity * item.unit_price);
    }

    return total;
  }


  get linenumber(): number {
    return this.item.value.item_line_number || 0;
  }

  deleteItemAt() {
    this.quoteService.delete_item(this.item.value).subscribe({
      next: (rec_status: IQuoteItemRecordStatus) => {
        if (rec_status.record_status == QuoteItemRecStatus.DELETED) {
          this.parent_container.removeAt(this.my_index);

          this.quoteService.rowChange({
            action: QuoteRowAction.DELETE,
            source: this.linenumber,
            activeInlet: this.activeInlet
          })
        }
      },
      error: (_) => {
        console.log("there was an error processing your request");
      }
    })
  }

  toggleInlet(inlet_type: InletType) {
    if (inlet_type == InletType.NONE) {
      return;
    }

    if (this.activeInlet == inlet_type) {
      this.activeInlet = InletType.NONE;
    } else {
      this.activeInlet = inlet_type;
    }

    this.quoteService.openInlet({
      internal_id: 0,
      line_number: this.line_number,
      checked: this.activeInlet != InletType.NONE,
      direction: inlet_type
    });
  }
}
