import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

export interface IPointScheduleReport {
  sequence_id: string;
  quote_id: string;
  title: string;
  spare_percent: number;
  sections: IPointScheduleSection[];
}

export interface IPointScheduleSection {
  sequence_id: string;
  line_number: number;
  name: string;
  parts: IPointSchedulePart[];
}

export interface IPointSchedulePart {
  sequence_id: string;
  line_number: number;
  description: string;
  note: string;
  configuration: IPointSchedulePartConfiguration[];
}

export interface IPointSchedulePartConfiguration {
  sequence_id: string;
  configuration_item_id: string;
  configuration_item_name: string;
  quote_item_configuration_id: string;
  quote_quantity: number;
  quantity: number;
}

export interface IPointScheduleReportFormGroup {
  sequence_id: FormControl<string>;
  title: FormControl<string>;
  quote_id: FormControl<string>;
  spare_percent: FormControl<number>;
  sections: FormArray<FormGroup<IPointScheduleSectionFormGroup>>;
}

export interface IPointScheduleSectionFormGroup {
  sequence_id: FormControl<string>;
  line_number: FormControl<number>;
  name: FormControl<string>;
  parts: FormArray<FormGroup<IPointSchedulePartFormGroup>>;
}

export interface IPointSchedulePartFormGroup {
  sequence_id: FormControl<string>;
  line_number: FormControl<number>;
  description: FormControl<string>;
  note: FormControl<string>;
  configuration: FormArray<FormGroup<IPointSchedulePartConfigurationFormGroup>>;
}

export interface IPointSchedulePartConfigurationFormGroup {
  sequence_id: FormControl<string>;
  configuration_item_id: FormControl<string>;
  configuration_item_name: FormControl<string>;
  quote_item_configuration_id: FormControl<string>;
  quantity: FormControl<number>;
}

export interface IQuoteItemPointsListConfiguration {
  item_part_list: string;
  part_ref: string;
  quote_item_description: string;
  quote_item_id: string;
  configuration: IPointListPartConfiguration[];
}

export interface IPointListPartConfiguration {
  configuration_item_name: string;
  quantity: string;
}

export class PointScheduleReport
{
  private _formGroup: FormGroup<IPointScheduleReportFormGroup>;

  get sections(): FormArray<FormGroup<IPointScheduleSectionFormGroup>> {
    return this._formGroup.controls.sections;
  }

  constructor(report: Partial<IPointScheduleReport>) {
    this._formGroup = new FormGroup<IPointScheduleReportFormGroup>({
      'sequence_id': new FormControl<string>(report.sequence_id || '',
                                             {nonNullable: true}),
      'title': new FormControl<string>(report.title || '',
                                       {nonNullable: true}),
      'quote_id': new FormControl<string>(report.quote_id || '',
                                          {nonNullable: true}),
      'spare_percent': new FormControl<number>(report.spare_percent || 25,
                                               {nonNullable: true}),
      'sections': new FormArray<FormGroup<IPointScheduleSectionFormGroup>>([])
    });

    if (report.sections) {
      for (let section of report.sections) {
        let pointschedulesection = new PointScheduleSection(section);
        this.sections.push(pointschedulesection.formGroup);
      }
    }
  }

  get formGroup(): FormGroup<IPointScheduleReportFormGroup> {
    return this._formGroup;
  }
}

export class PointScheduleSection {
  private _formGroup: FormGroup<IPointScheduleSectionFormGroup>;

  get parts(): FormArray<FormGroup<IPointSchedulePartFormGroup>> {
    return this._formGroup.controls.parts;
  }

  constructor(section: Partial<IPointScheduleSection>) {
    this._formGroup = new FormGroup<IPointScheduleSectionFormGroup>({
      'sequence_id': new FormControl<string>(section.sequence_id || '',
                                             {nonNullable: true}),
      'line_number': new FormControl<number>(section?.line_number || 1,
                                             {nonNullable: true}),
      'name': new FormControl<string>(section?.name || '',
                                      {nonNullable: true}),
      'parts': new FormArray<FormGroup<IPointSchedulePartFormGroup>>([])
    });

    if (section?.parts) {
      for (let part of section.parts) {
        let schedulepart = new PointSchedulePart(part);
        this.parts.push(schedulepart.formGroup);
      }
    }
  }

  get formGroup(): FormGroup<IPointScheduleSectionFormGroup> {
    return this._formGroup;
  }
}

export class PointSchedulePart {
  private _formGroup: FormGroup<IPointSchedulePartFormGroup>;

  constructor(part: Partial<IPointSchedulePart>) {
    this._formGroup = new FormGroup<IPointSchedulePartFormGroup>({
      'sequence_id': new FormControl<string>(part.sequence_id || '',
                                             {nonNullable: true}),
      'line_number': new FormControl<number>(part?.line_number || 1,
                                             {nonNullable: true}),
      'note': new FormControl<string>(part?.note || '',
                                      {nonNullable: true}),
      'description': new FormControl<string>(part?.description || '',
                                             {nonNullable: true}),
      'configuration': new FormArray<FormGroup<IPointSchedulePartConfigurationFormGroup>>([])
    });

    if (part?.configuration) {
      for (let ps_configuration of part.configuration) {
        let config = new PointSchedulePartConfiguration(ps_configuration);
        this._formGroup.controls.configuration.push(config.formGroup);
      }
    }
  }

  get formGroup(): FormGroup<IPointSchedulePartFormGroup> {
    return this._formGroup;
  }
}

export class PointSchedulePartConfiguration {
  private _formGroup: FormGroup<IPointSchedulePartConfigurationFormGroup>;

  constructor(configuration: IPointSchedulePartConfiguration) {
    this._formGroup = new FormGroup<IPointSchedulePartConfigurationFormGroup>({
      'sequence_id': new FormControl<string>(configuration.sequence_id || '',
                                             {nonNullable: true}),
      'configuration_item_id': new FormControl<string>(
        configuration.configuration_item_id,
        {nonNullable: true}),
      'configuration_item_name': new FormControl<string>(
        configuration.configuration_item_name,
        {nonNullable: true}),
      'quote_item_configuration_id': new FormControl<string>(
        configuration.quote_item_configuration_id, {nonNullable: true}),
      'quantity': new FormControl<number>(
        configuration.quantity || configuration.quote_quantity || 0,
        {
          nonNullable: true,
          validators: [Validators.min(0)]
        })
    });
  }

  get formGroup(): FormGroup<IPointSchedulePartConfigurationFormGroup> {
    return this._formGroup;
  }
}
