import { Component, inject, Input, OnInit } from '@angular/core';
import { DataCollationService } from '../../../core/data-collation.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dropdown-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  templateUrl: './dropdown-filter.component.html',
  styleUrl: './dropdown-filter.component.scss'
})
export class DropdownFilterComponent implements OnInit {
  private datacollationservice = inject(DataCollationService);

  model: any[] = [];

  @Input() label: string = 'Please select ...';
  @Input() filterName: string = '';
  @Input() dataFetcher!: <T>() => Observable<T>;
  @Input() valueFieldName: string = '';
  @Input() displayFieldName!: (row: any) => string;
  @Input() data!: any;

  ngOnInit(): void {
    if (this.dataFetcher) {
      this.dataFetcher().subscribe((data) => {
        this.data = data;
      });
    }

    this.datacollationservice.events.subscribe(e => {
      if ('reset' in e) {
        if (e.reset) {
          this.model = [];
        }
      }
    });
  }

  change(_: MatSelectChange) {
    this.datacollationservice.registerfilter(this.filterName,
                                             this.model)
  }
}
