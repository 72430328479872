import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SortOrder, ResetCollationEvent } from '../shared/interfaces';
import { CommonModule } from '@angular/common';
import { DataCollationService } from '../../../core/data-collation.service';

@Component({
  selector: 'app-sorter',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  templateUrl: './sorter.component.html',
  styleUrl: './sorter.component.scss'
})
export class SorterComponent implements OnInit {
  private collationservice = inject(DataCollationService);
  @Input() name!: string;

  private count: number = 0;
  private _order: SortOrder = SortOrder.OFF;

  get active(): boolean {
    return this._order != SortOrder.OFF;
  }

  get desc(): boolean {
    return this._order == SortOrder.DESC;
  }

  get asc(): boolean {
    return this._order == SortOrder.ASC
  }

  ngOnInit(): void {
      this.collationservice.events.subscribe(e => {
        if ('reset' in e) {
          if (e.reset) {
            this._reset();
          }
        }
      });

      this.collationservice.sortlock.subscribe(e => {
        if (e.name != this.name) {
          this._reset();
        }
      });
  }

  toggle() {
    this.count = ++this.count%2;
    const ord = [SortOrder.DESC, SortOrder.ASC];
    this._order = ord[this.count];
    if (this._order != SortOrder.OFF) {
      this.collationservice.registersorter(this.name,
                                           this._order)
    } else {
      this.collationservice.removesorter(this.name);
    }
  }

  _reset() {
    this._order = SortOrder.OFF;
    this.count = 0;
  }
}
