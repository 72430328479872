<div id="date-filter" class="date-filter-row">
  <div class="date-filter-col">
    <mat-form-field appearance="outline">
      <mat-label>From</mat-label>
      <input matInput [matDatepicker]="fromPicker"
        (dateChange)="change()"
        [(ngModel)]="from" />
      <mat-datepicker-toggle matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
      <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>To</mat-label>
      <input matInput [matDatepicker]="toPicker"
        (dateChange)="change()"
        [(ngModel)]="to" />
      <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
      <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="date-filter-col">
    <div class="date-filter-row">
      <div class="date-filter-col">
        <mat-checkbox
          [checked]="isChecked('WTD')"
          (change)="updateDateFilter($event, 'WTD')"
          matTooltip="Week to Date">WTD</mat-checkbox>
        <mat-checkbox
          [checked]="isChecked('MTD')"
          (change)="updateDateFilter($event, 'MTD')"
          matTooltip="Month to Date">MTD</mat-checkbox>
        <mat-checkbox
          [checked]="isChecked('YTD')"
          (change)="updateDateFilter($event, 'YTD')"
          matTooltip="Year to Date">YTD</mat-checkbox>
      </div>
      <div class="date-filter-col">
        <mat-checkbox
          [checked]="isChecked('M3')"
          (change)="updateDateFilter($event, 'M3')"
          matTooltip="Last 3 Months">M3</mat-checkbox>
        <mat-checkbox
          [checked]="isChecked('M6')"
          (change)="updateDateFilter($event, 'M6')"
          matTooltip="Last 6 Months">M6</mat-checkbox>
        <mat-checkbox
          [checked]="isChecked('Y1')"
          (change)="updateDateFilter($event, 'Y1')"
          matTooltip="Last Year">Y1</mat-checkbox>
      </div>
    </div>
  </div>
</div>
