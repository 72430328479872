<app-part-header></app-part-header>
<form [formGroup]="formGroup" class="data-form" (submit)="save()">
  <fieldset>
    <legend>Category</legend>
    @if (isEditMode) {
      <mat-form-field>
        <mat-label>Category Name</mat-label>
        <input matInput type="text" formControlName="category_name" readonly/>
      </mat-form-field>
    } @else {
      <mat-form-field>
        <mat-label>Category Name</mat-label>
        <input matInput type="text" formControlName="category_name" />
      </mat-form-field>
    }
    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="category_description"></textarea>
    </mat-form-field>
  </fieldset>
  <fieldset>
    <legend>Options</legend>
    <div class="action-panel" style="text-align: right">
      <button mat-fab extended
              type="button"
              (click)="addOption()">
        <mat-icon>add</mat-icon>
        Add
    </button>
    </div>
    <fieldset formArrayName="configuration"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      class="item-drag-boundary"
      style="border: 0;">
    @for (configItem of part_configuration_items.controls; track configItem) {
    <fieldset cdkDrag
              cdkDragLockAxis="y"
              cdkDragBoundary=".item-drag-boundary"
              formGroupName="{{$index}}"
              style="display: flex;">
      <legend>{{ configItem.controls.configuration_item_name.value }}</legend>
      <div class="configuration-item-form">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="configuration_item_name" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="configuration_item_description"></textarea>
      </mat-form-field>
      <mat-checkbox formControlName="has_quantity"></mat-checkbox>Has quantity?
      <mat-checkbox formControlName="is_flag"></mat-checkbox>Available on Part Flag?
      </div>
      <div class="configuration-item-action" style="margin-left: 0.75em">
        <span class="line-number" style="display: inline-block; border: 1px solid #000; padding: .5em; border-radius: 1em; width: 1em; height: 1em; text-align: center;">
          {{ configItem.controls.line_number.value }}
        </span>

        <div class="example-handle" cdkDragHandle style="margin-top: 0.5em; text-align: center;">
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
    </fieldset>
    }
    </fieldset>
  </fieldset>
  <div class="form-action-right">
    <button mat-raised-button>{{ buttonLabel }}</button>
  </div>
</form>
