<app-quote-header></app-quote-header>
<div id="doc-type-create" style="margin-bottom: 1em;">
  <h4>Document Categories</h4>
  <p>Define the types of documents that the system supports here</p>

  <form [formGroup]="fg_document_type" class="data-form" (ngSubmit)="add_document_type()" #typeForm>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Document Type Name</mat-label>
      <input matInput type="text" formControlName="type_name" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

    <div class="action-panel">
      <button mat-raised-button>Add</button>
    </div>
  </form>
</div>

<div class="category-list">
<table class="data-table" style="max-width: 500px;">
  <thead>
  <tr>
    <th>Category Name</th>
    <th>Description</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let category of categories">
      <td>{{ category.type_name }}</td>
      <td>{{ category.description }}</td>
      <td></td>
    </tr>
  </tbody>
</table>
</div>
