import { FormControl, FormGroup, Validators } from "@angular/forms";

export interface User {
  [key: string]: any;
  username: string;
  first_name: string;
  surname: string;
  password: string;
  job_title: string;
  email: string;
  group: string;
  phone_numbers?: PhoneNumber[];
  mobile: PhoneNumber;
  landline: PhoneNumber;
  signature_image: string;
}

export interface PhoneNumber {
  phone_id: number|null;
  phone_type: string;
  phone_number: string;
}

export interface UserGroup {
  group_name: string;
  description: string;
}

export interface UserFormGroup {
  username: FormControl<string>;
  first_name: FormControl<string>;
  surname: FormControl<string>;
  password: FormControl<string>;
  job_title: FormControl<string>;
  email: FormControl<string>;
  group: FormControl<string>;
  mobile: FormGroup<PhoneNumberFormGroup>;
  landline: FormGroup<PhoneNumberFormGroup>;
  signature_image: FormControl<string>;
}

export interface PhoneNumberFormGroup {
  phone_id: FormControl<number|null>;
  phone_type: FormControl<string>;
  phone_number: FormControl<string>;
}

export class User implements User {
  constructor(user?: User) {
    this.username = user?.username || '';
    this.first_name = user?.first_name || '';
    this.surname = user?.surname || '';
    this.password = user?.password || '';
    this.job_title = user?.job_title || '';
    this.email = user?.email || '';
    this.mobile = user?.mobile || {} as PhoneNumber;
    this.landline = user?.landline || {} as PhoneNumber;
    this.group = user?.group || '';

    if (user?.phone_numbers) {
      for (let number of user.phone_numbers) {
        this[number.phone_type] = number;
      }
    }
  }

  get formGroup(): FormGroup<UserFormGroup> {
    return new FormGroup<UserFormGroup>({
      username: new FormControl(this.username,
                                { nonNullable: true,
                                  validators: [Validators.required]}),
      first_name: new FormControl(this.first_name,
                                  { nonNullable: true,
                                    validators: [Validators.required]}),
      surname: new FormControl(this.surname,
                               { nonNullable: true,
                                 validators: [Validators.required]}),
      password: new FormControl(this.password,
                                { nonNullable: true,
                                  validators: [Validators.required]}),
      job_title: new FormControl(this.job_title,
                                 { nonNullable: true,
                                   validators: [Validators.required]
                                 }),
      email: new FormControl(this.email,
                             { nonNullable: true,
                               validators: [Validators.required, Validators.email]}),
      group: new FormControl(this.group,
                             { nonNullable: true,
                               validators: [Validators.required]}),
      mobile: this.makePhoneNumberFormGroup('mobile', this.mobile),
      landline: this.makePhoneNumberFormGroup('landline', this.landline),
      signature_image: new FormControl('', {nonNullable: true}),
    })
  }

  makePhoneNumberFormGroup(number_type: string, number: PhoneNumber): FormGroup<PhoneNumberFormGroup> {
    return new FormGroup<PhoneNumberFormGroup>({
      phone_id: new FormControl<number|null>(number.phone_id || null),
      phone_type: new FormControl<string>(number_type, {nonNullable: true}),
      phone_number: new FormControl<string>(
        number.phone_number || '', { nonNullable: true,
                                     validators: [Validators.required]}),
    })
  }
}
