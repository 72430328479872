import { AbstractControl, FormGroup } from "@angular/forms";

abstract class FormGroupDecorator<T> extends FormGroup {
  constructor(formGroup: FormGroup) {
    super(formGroup.controls);
  }
}

export class FormGroupIdDecorator<T> extends FormGroupDecorator<T> {
  private __id: number;
  private __group_ref: string | undefined;

  constructor(id: number,
              formGroup: FormGroup<any>,
              group_ref?: string) {
    super(formGroup);
    this.__id = id;
    this.__group_ref = group_ref;
    this.controls = formGroup.controls;
  }

  get id(): number {
    return this.__id;
  }

  get group_ref(): string | undefined {
    return this.__group_ref;
  }

  get parts(): any {
    return this.controls['parts'];
  }
}
