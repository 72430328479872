import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { UserProfile } from './interfaces';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private gateway: ApiGatewayService = inject(ApiGatewayService);
  private router: Router = inject(Router)
  private authService: AuthService = inject(AuthService);
  private _profile!: UserProfile;
  userprofile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>({} as UserProfile);

  constructor() {
    this.loadProfile();
  }

  get username(): string {
    return this._profile.username || '';
  }

  get group(): string {
    return this._profile.group || '';
  }

  loadProfile() {
    this.gateway.get<UserProfile>('/me').subscribe({
      next: (profile) => {
        this._profile = profile;
        this.userprofile$.next(profile);
      },
      error: (e: HttpErrorResponse) => {
        if (e.status == 401) {
          this.authService.logout().subscribe(_ => {
            this.router.navigate(['/login']);
          })
        }
      }
    });
  }
}
