<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-select multiple
    [(ngModel)]="model"
    (selectionChange)="change($event)">
    <mat-option *ngFor="let filterRow of data"
      [value]="filterRow.value"
      [disabled]="filterRow.count == 0">
      <span style="display: flex; justify-content: space-between; width: 150px;">
        <span>{{ filterRow.display }}</span>
        <span>[{{ filterRow.count }}]</span>
      </span>
    </mat-option>
  </mat-select>
</mat-form-field>
