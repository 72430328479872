import { Injectable } from "@angular/core";

@Injectable()
export class ClipboardService<T> {
  private _item!: T;

  copy(item: T) {
    this._item = item;
  }

  paste(): T {
    return this._item;
  }
}
