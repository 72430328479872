.rows {
  box-sizing: border-box;
}

.inner-col {
    /*min-width: calc(100% - 180px);
    max-width: calc(100% - 110px);*/
    position: relative;
}

#app-content {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    height: calc(100% - var(--mat-toolbar-standard-height));
    overflow-x: auto;
    overflow-y: auto;

    &-inner {
      padding-right: 16px;
      padding-left: 16px;
      padding-top: 1rem;
      box-sizing: border-box;
      height: 100%;
      background: #F2F5F7;
    }
}

.toolbar-spacer {
    flex: 1 1 auto;
}

