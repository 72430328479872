<span [ngClass]="active ? 'active' : ''" class="sorter" (click)="toggle()">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="sorter" *ngIf="desc">
  <title>Artboard-51</title>
  <g id="Down-2" data-name="Down" transform="translate(-1.6444844e-4,9.0002135)">
    <path
       d="M 16.924,9.617 A 1,1 0 0 0 16,9 H 8 a 1,1 0 0 0 -0.707,1.707 l 4,4 a 1,1 0 0 0 1.414,0 l 4,-4 a 1,1 0 0 0 0.217,-1.09 z"/>
  </g>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="sorter" *ngIf="asc || !active">
  <title>Artboard-52</title><g id="Up-2" data-name="Up" transform="translate(-1.6444844e-4,9.0002135)">
  <path d="M16.707,13.293l-4-4a1,1,0,0,0-1.414,0l-4,4A1,1,0,0,0,8,15h8a1,1,0,0,0,.707-1.707Z" /></g></svg>
</span>

