import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhoneNumber, PhoneNumberFormGroup, User, UserFormGroup } from '../shared/models';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../core/user.service';
import { MatIconModule } from '@angular/material/icon';
import { SelectUserGroupComponent } from '../../controls/select-user-group/select-user-group.component';
import { debounceTime, distinctUntilChanged, of, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'app-user-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    SelectUserGroupComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.scss'
})
export class UserEditComponent implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private service: UserService = inject(UserService);
  private emailText$ = new Subject<string>();
  checkingEmail: boolean = false;

  user!: User;
  userFormGroup!: FormGroup<UserFormGroup>;
  successMessage = 'User successfully created.';
  buttonText: string = 'Add';
  showMessage: boolean = false;
  editMode: boolean = false;

  get username(): FormControl {
    return this.userFormGroup.controls.username;
  }

  get password(): FormControl {
    return this.userFormGroup.controls.password;
  }

  get job_title(): FormControl {
    return this.userFormGroup.controls.job_title;
  }

  get email(): FormControl {
    return this.userFormGroup.controls.email;
  }

  get first_name(): FormControl {
    return this.userFormGroup.controls.first_name;
  }

  get surname(): FormControl {
    return this.userFormGroup.controls.surname;
  }

  get group(): FormControl {
    return this.userFormGroup.controls.group;
  }

  get mobile(): FormGroup<PhoneNumberFormGroup> {
    return this.userFormGroup.controls.mobile;
  }

  get landline(): FormGroup<PhoneNumberFormGroup> {
    return this.userFormGroup.controls.landline;
  }

  @ViewChild('signature')
  signature_file!: ElementRef<HTMLElement>;

  get signature_file_input(): HTMLInputElement {
    return this.signature_file.nativeElement as HTMLInputElement;
  }

  get files(): FileList|null {
    return this.signature_file_input.files;
  }

  signature_image: any = "";

  get signature_url(): string {
    if (!(this.signature_image.includes('fakeroot'))) {
      return this.signature_image;
    }

    return this.service.signature_url(this.username.value);
  }

  constructor() {}

  ngOnInit(): void {
    let username = this.route.snapshot.params['id'] || undefined;

    if (username) {
      this.service.getUser(username).subscribe(user => {
        this.create_backing_object(user);
        this.userFormGroup.controls.password.removeValidators(Validators.required);
        this.signature_image = user.signature_image;

        this.setEditMode();
      });
    } else {
      this.create_backing_object();
    }

    this.emailText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((emailText: string, index: number) => {
        this.checkingEmail = true;
        return this.service.checkEmail(emailText);
      })
    ).subscribe(resp => {
      if (resp.email_exists == 1) {
        this.email.setErrors({...(this.email.errors || {}),
                                 'emailNotAvailable': 'This email is already registered'});
      }
      this.checkingEmail = false;
    });
  }

  create_backing_object(user_to_edit?: User) {
    this.user = new User(user_to_edit);
    this.userFormGroup = this.user.formGroup;
  }


  signature_preview(e: any) {
    this.signature_image = "";
    let files = e.target.files;
    let signature = files[0];
    if (signature) {
      this.signature_image = URL.createObjectURL(signature);
    }
  }

  editUser() {
    this.userFormGroup.markAllAsTouched();
    let form_data = this.make_form_data();
    if (this.userFormGroup.valid) {
      if (this.editMode) {
        this.service.updateUser(
          form_data
        ).subscribe(msg => {
          this.successMessage = 'User successfully updated';
          this.showMessage = true;
        });
      } else {
        this.service.createUser(
          form_data
        ).subscribe(msg => {
          this.showMessage = true;
          this.setEditMode();
        });

      }
    }
  }

  checkEmail(event: any) {
    if (this.email.valid) {
      this.emailText$.next(this.email.value);
    }
  }

  setEditMode() {
    this.editMode = true;
    this.buttonText = 'Update';
  }

  hideMessage() {
    this.showMessage = false;
  }

  make_form_data() {
    let fd = new FormData();
    fd.append('username', this.username.value);
    fd.append('password', this.password.value);
    fd.append('first_name', this.first_name.value);
    fd.append('surname', this.surname.value);
    fd.append('job_title', this.job_title.value);
    fd.append('email', this.email.value);
    fd.append('group', this.group.value);
    fd.append('mobile', this.marshall_phone_number(this.mobile.value));
    fd.append('landline', this.marshall_phone_number(this.landline.value));
    if (this.files) {
      fd.append('signature_image', this.files[0]);
    }
    return fd;
  }

  marshall_phone_number(number: Partial<PhoneNumber>) {
    return Object.keys(number).map((key: string) => {
      if ((number as any)[key]) {
        return key+'='+(number as any)[key];
      }
      return key+'='+null;
    }).join('|');
  }
}
