<div class="app-editor">
  <div id="toolbar">

  </div>
  <div id="editor" #editor style="height: 15em;">

  </div>
</div>

<div class="action" style="margin-top: 1.5em">
  <button type="button" (click)="showContents()">Save</button>
</div>
