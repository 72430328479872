import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { IDocumentTemplate, IDocumentTemplateSection, IDocumentTemplateFormGroup, IDocumentSectionTemplateFormGroup, IDocumentSectionTemplate, IDocumentTemplateSectionFormGroup } from "./interfaces";
import { Delta } from "quill/core";

export function make_document_template_formgroup(
  quoteTemplate?: IDocumentTemplate): FormGroup<IDocumentTemplateFormGroup> {
  let fg = new FormGroup<IDocumentTemplateFormGroup>({
    document_template_id: new FormControl<string>(
      quoteTemplate?.document_template_id || '', {nonNullable: true}),
    template_name: new FormControl<string>(
      quoteTemplate?.template_name || '',
      {nonNullable: true, validators: [Validators.required]}),
    document_type: new FormControl<string>(
      quoteTemplate?.document_type || '',
      {nonNullable: true, validators: [Validators.required]}),
    sections: new FormArray<FormGroup<IDocumentTemplateSectionFormGroup>>([])
  });

  return fg;
}

export function make_section_template_formgroup(
  sectionTemplate?: IDocumentSectionTemplate): FormGroup<IDocumentSectionTemplateFormGroup> {
  let fg = new FormGroup<IDocumentSectionTemplateFormGroup>({
    section_template_id: new FormControl<string>(
      sectionTemplate?.section_template_id || '', {nonNullable: true}),
    section_name: new FormControl<string>(
      sectionTemplate?.section_name || '', {nonNullable: true,
      validators: Validators.required}),
    section_content: new FormControl<Delta>(
      sectionTemplate?.section_content || new Delta(),
      {nonNullable: true, validators: Validators.required})
  });

  return fg;
}

export function make_document_template_section_formgroup(
  documentSection: IDocumentTemplateSection) {

  return new FormGroup<IDocumentTemplateSectionFormGroup>({
    document_template_section_id: new FormControl<string>(
      documentSection.document_template_section_id || '',
      {nonNullable: true}),
    document_template_id: new FormControl<string>(
      documentSection.document_template_id || '',
      {nonNullable: true}),
    section_template_id: new FormControl<string>(
      documentSection.section_template_id,
      {
        nonNullable: true,
        validators: [Validators.required]
      }
    ),
    section_name: new FormControl<string>(
      documentSection.section_name,
      {
        nonNullable: true,
        validators: [Validators.required]
      }
    ),
    section_number: new FormControl<number>(
      documentSection.section_number,
      {
        nonNullable: true,
        validators: [Validators.required]
      }
    )
  });
}

export function make_document_template_section(
  document_section: Partial<IDocumentSectionTemplate>): IDocumentTemplateSection {
  return {
    document_template_section_id: '',
    document_template_id: '',
    section_template_id: document_section.section_template_id!,
    section_name: document_section.section_name!,
    section_number: 0
  }
}
