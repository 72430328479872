<app-part-header>
    <a mat-fab extended routerLink="add">
      <mat-icon>add</mat-icon>
      New Configuration
    </a>
</app-part-header>

<table>
  <thead>
    <th>Category</th>
    <th>Description</th>
    <th></th>
  </thead>
  <tbody>
  @for (configuration of configurationCategories | async; track configuration.category_name) {
    <tr>
      <td>{{ configuration.category_name }}</td>
      <td>{{ configuration.category_description }}</td>
      <td>
        <a mat-icon-button
          [routerLink]="['/parts/configurations', configuration.category_name]">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </tr>
  }
  </tbody>
</table>
