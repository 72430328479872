import { Component, inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SideMenuComponent } from '../../nav/side-menu/side-menu.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TopNavComponent } from '../../nav/top-nav/top-nav.component';
import { filter, map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UserProfile } from '../../core/interfaces';
import { ProfileService } from '../../core/profile.service';

@Component({
  selector: 'app-standard-layout',
  standalone: true,
  imports: [RouterOutlet, SideMenuComponent, MatToolbarModule, TopNavComponent, CommonModule],
  templateUrl: './standard-layout.component.html',
  styleUrl: './standard-layout.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [ProfileService]
})
export class StandardLayoutComponent {
  profileService: ProfileService = inject(ProfileService);
  title:string = "";
  loadApplication: boolean = false;
  userprofile!: UserProfile;
  viewTitle: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {
    this.profileService.userprofile$.subscribe({
      next: (userprofile) => {
        if (userprofile.username) {
          this.loadApplication = true;
          this.userprofile = userprofile;
        }
      },
      error: (_) => {
        console.log("here");
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }

          if (route.snapshot.title) {
            routeTitle = route!.snapshot.title;
          }

          return routeTitle;
        })
      ).subscribe((title: string) => {
        if (title) {
          this.title = title;
          this.viewTitle = title.replace(' ', '-');
        }
      });


    while (this.route!.firstChild) {
      this.route = this.route.firstChild;
    }

    if (this.route.snapshot.title) {
       this.title = this.route!.snapshot.title;
       this.viewTitle = this.title.replace(' ', '-');
    }
  }
}
