import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.scss'
})
export class MessagesComponent implements OnChanges, AfterViewInit {
  renderer: Renderer2 = inject(Renderer2);

  @Input() message: string = '';
  @ViewChild('alertMsg', {static: true}) alert!:ElementRef<any>;

  constructor() {
  }

  ngAfterViewInit(): void {
    console.log(this.alert);
    // this.renderer.setStyle(this.alert.nativeElement, "display", "none");
  }

  ngOnChanges(_: SimpleChanges): void {
      if (this.message) {
        this.renderer.removeClass(this.alert.nativeElement, "hide");
        this.renderer.addClass(this.alert.nativeElement, "show");
        this.renderer.addClass(this.alert.nativeElement, "fade-out");

        setTimeout(() => {
          this.renderer.removeClass(this.alert.nativeElement, "show");
          this.renderer.removeClass(this.alert.nativeElement, "fade-out");
          this.message = '';
          this.renderer.addClass(this.alert.nativeElement, "hide");
        }, 2500);
      }
  }
}
