import { Form, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Decimal } from 'decimal.js'
import { IQuote, IQuoteFormGroup, IQuoteItem, IQuoteItemFormGroup, IQuotePriceAdjustmentFormGroup, PriceAdjustmentType } from "./interfaces";
import { FormGroupIdDecorator } from "../../../core/forms";
import { PriceAdjustmentTypeButtonComponent } from "../../../controls/price-adjustment-type-button/price-adjustment-type-button.component";

export enum SepratorStyle {
  SOLID=1,
  DOUBLELINE,
  DASHED,
  DOTTED,
}

export enum ItemType {
  PART='part',
  FINISHEDGOOD='finished_good',
  COMPOSITE='composite',
  ENCLOSURE='enclosure',
  NETTPRICE='nett_price'
}

export class IQuoteItemWithContextDecorator implements Partial<IQuoteItem> {
  private _item: Partial<IQuoteItem> | undefined;
  private _context: number[] = [];
  private _insertat: number = 0;

  constructor(item?: Partial<IQuoteItem>,
              context?: number[],
              insertAt: number = 0) {
    this._item = item;
    this._context = context || [];
    this._insertat = insertAt;
  }

  get id(): number {
    return this._item?.id || 0;
  }

  get item_part_id(): string {
    return this._item?.item_part_id || '';
  }

  get item_part_type() {
    return this._item?.item_part_type;
  }

  get context(): number[] {
    return this._context;
  }

  get parentcontext(): number {
    if (this._context) {
      return this._context[this._context.length - 1];
    }

    return 0;
  }

  get insertAt(): number {
    return this._insertat;
  }
}

/**
 * !FIXME: All quote_items throughout the system need to be of
 * the FormGroupIdDecorator type.
 */
export class Quote {
  quote_id: string | undefined;

  quote_item_hierarchy: IQuoteItem[];
  private _formgroup: FormGroup<IQuoteFormGroup>;

  private quoteCurrentLineNumber: number = 1;

  get quote_items(): FormArray<FormGroupIdDecorator<IQuoteItemFormGroup>> {
    return this._formgroup.controls.quote_items as unknown as FormArray<FormGroupIdDecorator<IQuoteItemFormGroup>>;
  }

  constructor(quote?: IQuote) {
    this.quote_id = quote?.quote_id;

    this._formgroup = new FormGroup<IQuoteFormGroup>({
      'quote_id': new FormControl<string|null>(quote?.quote_id || ''),
      'quote_ref': new FormControl<string|null>(quote?.quote_ref || '',
                                                {nonNullable: true,
                                                 validators: [
                                                   Validators.required,
                                                   Validators.pattern('^[0-9]{5}.*')
                                                 ]}),
      'description': new FormControl<string|null>(quote?.description || '',
                                                  {nonNullable: true,
                                                   validators: [
                                                     Validators.required
                                                   ]}),
      'paragraph': new FormControl<string|null>(quote?.paragraph || ''),
      'customer_id': new FormControl<string>(quote?.customer_id || '',
                                                  { nonNullable: true,
                                                    validators: [Validators.required]
                                                  }),
      'consultant': new FormControl<string|null>(quote?.consultant || ''),
      'attention': new FormControl<string|null>(quote?.attention || ''),
      'salutation': new FormControl<string|null>(quote?.salutation || ''),
      'dtm_quote': new FormControl<Date | string | null>(new Date(quote?.dtm_quote || 'now') || null),
      'quote_items': new FormArray<FormGroup<IQuoteItemFormGroup>>([]),
      'price_adjustment': new FormArray<FormGroup<IQuotePriceAdjustmentFormGroup>>([])
    });

    this.addDefaultQuoteAdjustments();
    if (quote?.price_adjustment && quote.price_adjustment.length) {
      for (let adjustment of quote.price_adjustment) {
        for (let fg_adjustment of this._formgroup.controls.price_adjustment.controls) {
          if (fg_adjustment.controls.adjustment_code.value == adjustment.adjustment_code) {
            fg_adjustment.controls.adjustment_type.setValue(adjustment.adjustment_type);
            fg_adjustment.controls.adjustment_value.setValue(adjustment.adjustment_value);
          }
        }
      }
    }

    this.quote_item_hierarchy = quote?.quote_item_hierarchy || [];
  }

  setLineNumber(lineNumber: number = 1) {
    this.quoteCurrentLineNumber = lineNumber;
  }

  public getQuoteItemAtLine(linenumber: number,
    container: FormArray<FormGroup<IQuoteItemFormGroup>>
      | undefined = undefined,
    match: Partial<IQuoteItem> | undefined = undefined,
    context: number[] = []):
    IQuoteItemWithContextDecorator | undefined {
    if (!container) {
      container = this._formgroup.controls.quote_items;
    }

    var insertAt: number = 0;
    for (let item of container.controls) {
      if (item.controls.item_line_number.value == linenumber) {
        if (item.controls.item_part_type.value != ItemType.PART) {
          let _item = item as unknown as FormGroupIdDecorator<IQuoteItem>;
          context.push(_item.id);
          return new IQuoteItemWithContextDecorator(
            {..._item.value, 'id': _item.id},
            context,
            0);
        } else {
          let _item = item as unknown as FormGroupIdDecorator<IQuoteItem>;
          return new IQuoteItemWithContextDecorator({..._item.value, 'id': _item.id},
                                                    context,
                                                    ++insertAt);
        }
      }

      if (!match && item.controls.parts?.length) {
        let _item = item as unknown as FormGroupIdDecorator<IQuoteItemWithContextDecorator>;
        context.push(_item.id);
        match = this.getQuoteItemAtLine(linenumber,
                                        item.controls.parts,
                                        match,
                                        context);
        if (!match) {
          context.pop();
        } else {
          if (match instanceof IQuoteItemWithContextDecorator) {
            return match;
          }
        }
      }

      if (match instanceof IQuoteItemWithContextDecorator) {
        return match;
      }
      ++insertAt;
    }

    if (!match) {
      return match;
    }

    if (match instanceof IQuoteItemWithContextDecorator) {
      return match;
    }

    return new IQuoteItemWithContextDecorator(match, context, ++insertAt);
  }

  public getQuoteItemById(id: string,
    container: FormArray<FormGroupIdDecorator<IQuoteItemFormGroup>>
      | undefined = undefined): FormGroup<IQuoteItemFormGroup> | undefined {
    if (!container) {
      container = this.quote_items;
    }

    for (let item of container.controls) {
      if (item.controls['quote_item_id'].value == id) {
        return item as unknown as FormGroup<IQuoteItemFormGroup>;
      }

      if (item.parts) {
        let match = this.getQuoteItemById(id,
                                          item.parts);

        if (match) {
          return match;
        }
      }
    }

    return undefined;
  }

  public getQuoteItemByInternalId(id: number,
    container: FormArray<FormGroupIdDecorator<IQuoteItemFormGroup>>
      | undefined = undefined): FormGroup<IQuoteItemFormGroup> | undefined {
    if (!container) {
      container = this.quote_items;
    }

    for (let item of container.controls) {
      if (item.id == id) {
        return item as unknown as FormGroup<IQuoteItemFormGroup>;
      }

      if (item.parts) {
        let match = this.getQuoteItemByInternalId(id,
                                              item.parts);

        if (match) {
          return match;
        }
      }
    }

    return undefined;
  }

  get formgroup(): FormGroup<IQuoteFormGroup> {
    return this._formgroup;
  }

  get total(): Decimal {
    return this.getTotal(this.partTotal);
  }

  get totalIncMarkup(): Decimal {
    return this.getTotal(this.partTotalIncMarkup.bind(this));
  }

  getTotal(partTotalCalulation: (item: FormGroup<IQuoteItemFormGroup>) => Decimal,
           items: FormArray<FormGroup<IQuoteItemFormGroup>> | undefined = undefined,
           total: Decimal = new Decimal(0)): Decimal {
    if (!items) {
      items = this._formgroup.controls.quote_items as FormArray<FormGroup<IQuoteItemFormGroup>>;
    }

    for (let item of items.controls) {
      // !FIXME: convert this to a behavior, include_in_total: boolean
      if (item.controls.item_part_type.value == ItemType.PART ||
          item.controls.item_part_type.value == ItemType.ENCLOSURE) {
        total = total.add(partTotalCalulation(item));
      }

      if (item.controls.parts) {
        total = this.getTotal(partTotalCalulation, item.controls.parts, total);
      }
    }

    return total;
  }

  gross_up(total: Decimal) {
    let gross_up_fixed = new Decimal(0);

    for (let adj of this._formgroup.controls.price_adjustment.controls) {
      if (adj.controls.adjustment_code.value == 'GROSS') {
        let val = adj.controls.adjustment_value.value || 0;

        if (adj.controls.adjustment_type.value == PriceAdjustmentType.PERCENTAGE) {
          // gross up can be rounded up here, round(1/(1-%age))

          gross_up_fixed = total.div(1 - val/100);
        } else {
          gross_up_fixed = new Decimal(val)
          gross_up_fixed = gross_up_fixed.add(total);
        }
      }
    }

    return gross_up_fixed;
  }

  discount(total: Decimal) {
    let discount_fixed = new Decimal(0);

    for (let adj of this._formgroup.controls.price_adjustment.controls) {
      if (adj.controls.adjustment_code.value == 'DISC') {
        let val = adj.controls.adjustment_value.value || 0;

        if (adj.controls.adjustment_type.value == PriceAdjustmentType.PERCENTAGE) {
          discount_fixed = total.mul(val/100);
        } else {
          discount_fixed = new Decimal(val);
        }
      }
    }

    return discount_fixed;
  }

  get headline_costs(): any {
    let acc = this.calculate_headline_costs(
      this._formgroup.controls.quote_items);

    let costs = [];
    for (let item of acc) {
      let headline_cost = costs.find((_item) => _item.headline_cost == item.headline_cost);
      if (headline_cost) {
        headline_cost.total = headline_cost.total.add(item.total);
        headline_cost.total_inc_markup = headline_cost.total_inc_markup.add(
          item.total_inc_markup);
      } else {
        costs.push(item);
      }
    }

    costs.sort(
      (_item1, _item2) => {
        if (_item1.headline_cost < _item2.headline_cost) {
          return -1;
        }

        if (_item1.headline_cost > _item2.headline_cost) {
          return 1;
        }

        return 0;
    });

    let init_val = new Decimal(0);
    let total = costs.reduce(
      (acc: Decimal, curVal) => acc.add(curVal.total_inc_markup),
      init_val
    );


    /**@TODO: apply gross up, discounts here*/
    let total_inc_grossup = this.gross_up(total);
    let gross_up_amount = total_inc_grossup.sub(total);
    let discount_amount = this.discount(total_inc_grossup);

    for (let headline_cost of costs) {
      let proportion = headline_cost.total_inc_markup.div(total);
      headline_cost.total_inc_grossup = headline_cost.total_inc_markup.add(gross_up_amount.mul(proportion));
      headline_cost.total_inc_discount = headline_cost.total_inc_grossup.sub(discount_amount.mul(proportion));
    }

    return costs;
  }

  private calculate_headline_costs(container: any, acc: any = []): any {
    for (let item of container.controls) {
      if (item.group_ref) {
        acc.push({
          headline_cost: item.group_ref,
          total: this.partTotal(item),
          total_inc_markup: this.partTotalIncMarkup(item)
        });
      }

      if (item.parts) {
        acc = this.calculate_headline_costs(item.parts, acc);
      }
    }

    return acc;
  }

  partTotal(item: FormGroup<IQuoteItemFormGroup>): Decimal {
    let price = new Decimal(item.controls.unit_price?.value || 0);
    let qty = new Decimal(item.controls.quantity?.value || 0);

    return price.mul(qty);
  }

  partTotalIncMarkup(item: FormGroup<IQuoteItemFormGroup>): Decimal {
    let total = this.partTotal(item);
    let markup = new Decimal(item.controls.markup?.value || 0);

    return total.mul(markup);
  }

  private addDefaultQuoteAdjustments() {
    let pj1 = (code: string) => {
      return new FormGroup<IQuotePriceAdjustmentFormGroup>({
        'adjustment_code': new FormControl<string>(code, {nonNullable: true}),
        'adjustment_type': new FormControl<PriceAdjustmentType>(PriceAdjustmentType.PERCENTAGE, {nonNullable: true}),
        'adjustment_value': new FormControl<number|null>(null)
      });
    }

    this._formgroup.controls.price_adjustment.push(pj1('GROSS'));
    this._formgroup.controls.price_adjustment.push(pj1('DISC'));
  }
}

export abstract class QuoteItem {
  private static internal_id = 1;
  private __id: number;
  item_line_number: number = 0;
  item_part_number: number = 0;
  protected _formGroup!: FormGroupIdDecorator<IQuoteItemFormGroup>;
  protected _parts: Partial<IQuoteItem>[] = [];

  constructor() {
      this.__id = QuoteItem.internal_id++;
  }

  get internalId(): number {
    return this.__id;
  }

  abstract get itemType(): ItemType;

  abstract get formGroup(): any;
}

export abstract class AggregateQuoteItem extends QuoteItem {
  constructor(part: Partial<IQuoteItem>) {
    super();
    if (part.parts?.length) {
      part.parts.map((p) => {
        if (!p.item_part_type) {
          p.item_part_type = ItemType.PART;
        }
      });

      this._parts = part.parts;
    }
  }

  get parts(): Partial<IQuoteItem>[] {
    return this._parts;
  }
}

export class PartQuoteItem extends QuoteItem {
  protected type: ItemType = ItemType.PART;

  get itemType(): ItemType {
    return this.type;
  }

  constructor(part: Partial<IQuoteItem>) {
    super();
    let fg = new FormGroup<IQuoteItemFormGroup>({
      'quote_item_id': new FormControl<string>(
        part.quote_item_id || '',
        {nonNullable: true}),
      'item_part_id': new FormControl<string>(
        part.item_part_id || part.quote_item_part_id || '',
        {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(
        ItemType.PART,
        {nonNullable: true}),
      'quote_item_description': new FormControl<string>(
        part.description || part.quote_item_description || '',
        {nonNullable: true}),
      'quantity': new FormControl<number>(
        part.quantity || 0, {nonNullable: true}),
      'unit_price': new FormControl<number>(
        part.price || part.unit_price || 0,
        {nonNullable: true}),
      'markup': new FormControl<number>(
        part.selectedMarkup || part.markup || 0,
        {nonNullable: true}),
      'item_line_number': new FormControl<number>(
        part.item_line_number || 1, {nonNullable: true}),
      'item_part_number': new FormControl<number>(
        part.item_part_number || 0, {nonNullable: true}),
      'configurations': new FormArray<FormGroup<any>>([]),
      'flg_price_override': new FormControl<boolean>(
        part.flg_price_override || false,
        {nonNullable: true})
    });

    if (part.configurations) {
      for (let configuration of part.configurations) {
        let cfg = this.makeConfigurationFormGroup(configuration);
        let configuration_items = cfg.controls['configuration'] as FormArray<FormGroup<any>>;
        for (let item of configuration.configuration) {
          if (item.is_applicable) {
            configuration_items.push(this.makeConfigurationItemFormGroup(item));
          }
        }

        fg.controls.configurations.push(cfg);
      }
    }

    this._formGroup = new FormGroupIdDecorator<IQuoteItemFormGroup>(this.internalId,
                                                                    fg,
                                                                   part.part_group_ref);
  }

  makeConfigurationFormGroup(configuration: any): FormGroup<any> {
      return new FormGroup<any>({
        'category_name': new FormControl<string>(configuration.category_name),
        'category_description': new FormControl<string>(configuration.category_description),
        'configuration': new FormArray<FormGroup<any>>([])
      });
  }

  makeConfigurationItemFormGroup(configuration: any) {
    return new FormGroup<any>({
      'quote_item_configuration_sequence_id': new FormControl<string>(
        configuration.quote_item_configuration_sequence_id || '',
        {nonNullable: true}),
      'configuration_item_id': new FormControl<string>(
        configuration.configuration_item_id,
        {nonNullable: true}),
      'configuration_item_name': new FormControl<string>(configuration.configuration_item_name),
      'configuration_item_description': new FormControl<string>(configuration.configuration_item_description),
      'quantity': new FormControl<number>(configuration.quantity || configuration.default_quantity || 0, {nonNullable: true})
    });
  }

  get formGroup(): FormGroupIdDecorator<IQuoteItemFormGroup> {
    return this._formGroup;
  }
}

export class EnclosureQuoteItem extends AggregateQuoteItem {
  protected type;

  get itemType(): ItemType {
    return this.type;
  }

  _formgroup: FormGroupIdDecorator<IQuoteItemFormGroup>;

  constructor(part: Partial<IQuoteItem>,
              type: ItemType = ItemType.ENCLOSURE) {
    super(part);
    this.type = type;
    let fg = new FormGroup<IQuoteItemFormGroup>({
      'quote_item_id': new FormControl<string>(part.quote_item_id || '',
                                               {nonNullable: true}),
      'item_part_id': new FormControl<string>(part.item_part_id || '',
                                                   {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(
        this.type || ItemType.ENCLOSURE,
        {nonNullable: true}),
      'quote_item_description': new FormControl<string>(
        part.description || part.quote_item_description || '',
        {nonNullable: true}),
      'quantity': new FormControl<number>(part.quantity || 1,
                                          {nonNullable: true}),
      'unit_price': new FormControl<number>(part.price || part.unit_price || 0,
                                            {nonNullable: true}),
      'flg_price_override': new FormControl<boolean>(
        part.flg_price_override || false,
        {nonNullable: true}),
      'markup': new FormControl<number>(part.markup || 0, {nonNullable: true}),
      'parts': new FormArray<FormGroup<IQuoteItemFormGroup>>([]),
      'item_line_number': new FormControl<number>(part.item_line_number || 1, {nonNullable: true}),
      'item_part_number': new FormControl<number>(part.item_part_number || 0, {nonNullable: true}),
      'configurations': new FormArray<FormGroup<any>>([])
    });

    fg.controls.parts?.setParent(fg);
    this._formgroup = new FormGroupIdDecorator(this.internalId,
                                               fg, part.part_group_ref);
  }

  get formGroup(): FormGroupIdDecorator<IQuoteItemFormGroup> {
    return this._formgroup;
  }
}

export class CompositeQuoteItem extends AggregateQuoteItem {
  protected type;

  get itemType(): ItemType {
    return this.type;
  }

  _formgroup: FormGroupIdDecorator<IQuoteItemFormGroup>;

  constructor(part: Partial<IQuoteItem>,
              type: ItemType = ItemType.COMPOSITE) {
    super(part);
    this.type = type;
    let fg = new FormGroup<IQuoteItemFormGroup>({
      'quote_item_id': new FormControl<string>(part.quote_item_id || '',
                                               {nonNullable: true}),
      'item_composite_id': new FormControl<string>(part.item_composite_id ||
                                                   part.item_part_id || '',
                                                   {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(
        this.type || ItemType.COMPOSITE,
        {nonNullable: true}),
      'quote_item_description': new FormControl<string>(
        part.description || part.quote_item_description || '',
        {nonNullable: true}),
      'quantity': new FormControl<number>(part.quantity || 1,
                                          {nonNullable: true}),
      'unit_price': new FormControl<number>(part.price || part.unit_price || 0,
                                            {nonNullable: true}),
      'markup': new FormControl<number>(part.markup || 0, {nonNullable: true}),
      'parts': new FormArray<FormGroup<IQuoteItemFormGroup>>([]),
      'item_line_number': new FormControl<number>(part.item_line_number || 1, {nonNullable: true}),
      'item_part_number': new FormControl<number>(part.item_part_number || 0, {nonNullable: true}),
      'configurations': new FormArray<FormGroup<any>>([])
    });

    fg.controls.parts?.setParent(fg);
    this._formgroup = new FormGroupIdDecorator(this.internalId, fg);
  }

  get formGroup(): FormGroupIdDecorator<IQuoteItemFormGroup> {
    return this._formgroup;
  }
}

export class FinishedGoodQuoteItem extends AggregateQuoteItem {
  protected type = ItemType.FINISHEDGOOD;
  get itemType(): ItemType {
    return this.type;
  }

  private _formgroup: FormGroupIdDecorator<IQuoteItemFormGroup>;

  constructor(part: Partial<IQuoteItem>) {
    super(part);
    let fg = new FormGroup<IQuoteItemFormGroup>({
      'quote_item_id': new FormControl<string>(part.quote_item_id || '',
                                               {nonNullable: true}),
      'item_part_id': new FormControl<string>(
        this.internalId.toString() || '',
        {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(ItemType.FINISHEDGOOD,
                                                  {nonNullable: true}),
      'quote_item_description': new FormControl<string>(
        part.description || part.quote_item_description || '',
        {nonNullable: true}),
      'quantity': new FormControl<number>(part.quantity || 1,
                                          {nonNullable: true}),
      'parts': new FormArray<FormGroup<IQuoteItemFormGroup>>([]),
      'item_line_number': new FormControl<number>(
        part.item_line_number || 1, {nonNullable: true}),
      'item_part_number': new FormControl<number>(part.item_part_number || 0, {nonNullable: true}),
      'configurations': new FormArray<FormGroup<any>>([])
    });

    fg.controls.parts?.setParent(fg);
    this._formgroup = new FormGroupIdDecorator(this.internalId, fg);
  }

  get formGroup(): FormGroupIdDecorator<IQuoteItemFormGroup> {
    return this._formgroup;
  }
}

export class TextQuoteItem extends QuoteItem {
  get itemType() {
    return ItemType.PART;
  }

  get formGroup() {
    return new FormGroup({});
  }
}

export class NettPriceItem extends QuoteItem {
  get itemType() {
    return ItemType.NETTPRICE;
  }

  private _formgroup: FormGroupIdDecorator<IQuoteItemFormGroup>;

  constructor(part: Partial<IQuoteItem>) {
    super();

    let fg = new FormGroup({
      'quote_item_id': new FormControl<string>(part.quote_item_id || '',
                                               {nonNullable: true}),
      'item_part_type': new FormControl<ItemType>(ItemType.NETTPRICE,
                                                  {nonNullable: true}),
      'quote_item_description': new FormControl<string>(
        part.description || part.quote_item_description || 'nett price',
      ),
      'quantity': new FormControl<number>(0),
      'item_line_number': new FormControl<number>(part.item_line_number || 1,
                                                  {nonNullable: true}),
      'item_part_number': new FormControl<number>(0, {nonNullable: true})
    });

    this._formgroup = new FormGroupIdDecorator(this.internalId, fg);
  }

  get formGroup() {
    return this._formgroup;
  }
}

export class SeparatorQuoteItem extends QuoteItem {
  private _style: SepratorStyle = SepratorStyle.SOLID;

  get itemType() {
    return ItemType.PART;
  }

  get style(): SepratorStyle {
    return this._style;
  }

  set style(newStyle: SepratorStyle) {
    this._style = newStyle
  }

  get formGroup() {
    return new FormGroup({});
  }
}

