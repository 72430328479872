$table-cell-border: rgb(142, 142, 147);

h3, h4, h6 {
    padding: 0;
    margin: 0;
}

#app-content-inner.Quotes {
  padding-right: 0 !important;
}

#quote-grid-container {
  padding-right: 16px;
  height: 100%;
  overflow-y: scroll;
}

#quote-grid {
  display: grid;
  grid-template-columns: minmax(200px, 400px) 1fr;
  gap: 1em;
}

.configurator-column {
  position: sticky;
  top: 0;
  align-self: start;
}

.solid-underline {
  border-bottom: 1px solid #000;
}

.double-underline {
  border-top: 3px double #000;
}

.dashed-underline {
  border-top: 1px dashed #000;
}

.dotted-underline {
  border-top: 2px dotted #000;
}

#quote {
  .quote-header {
    display: flex;
    flex-direction: column;

    & .quote-header-row {
      display: flex;
      gap: 0.5em;

      & .quote-header-item {
        flex-grow: 1;
        flex-basis: 0;

        & mat-form-field {
          width: 100%;
        }
      }
    }
  }

  & .quote-section {
    margin-bottom: 1em;
  }


  & table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  & table td,
  & table th {
    box-sizing: border-box;
  }

  & table.border-table {
    border: 1px solid #000;

    & tr.header {
      background: rgb(216,216,220);
    }
  }

  & table#headline-cost-table {
    & tr th:first-child,
    & tr td:first-child {
      width: 30% !important;
    }

    & tr td:first-child {
      text-align: right;
      padding-right: 0.5em;
      font-weight: bold;
    }

    & tr th,
    & tr td {
      width: 16.66665%;
      text-align: center;

      &:nth-child(2) input {
        width: auto !important;
      }

      & input {
        width: 90%;
      }

      & label {
        display: inline-block;
        width: 50%;
      }
    }
  }

  & table#adjustment-table {
    & tr th:first-child,
    & tr td:first-child {
      width: 50% !important;
    }

    & tr td:first-child {
      text-align: right;
      padding-right: 0.5em;
      font-weight: bold;
    }

    & tr th,
    & tr td {
      width: 25%;
      text-align: center;

      &:nth-child(2) input {
        width: auto !important;
      }

      & input {
        width: 90%;
      }

      & label {
        display: inline-block;
        width: 50%;
      }
    }
  }

  & tr td:first-child,
  & tr th:first-child {
    width: 70px !important;
  }

  & tr td:nth-child(2),
  & tr th:nth-child(2) {
    width: 100%;
  }

  & tr td:nth-child(3),
  & tr th:nth-child(3) {
    width: 120px;
  }

  & tr td:nth-child(4),
  & th:nth-child(4) {
    width: 120px;
  }

  & tr td:nth-child(5),
  & th:nth-child(5) {
    width: 90px;
  }

  & tr td:nth-child(6),
  & th:nth-child(6) {
    width: 120px;
  }

  & tr td:nth-child(7),
  & th:nth-child(7) {
    width: 120px;
  }

  & .quote-item-row {
    display: table-row;
  }

  & .cell {
    display: table-cell;
    padding: 0.25em;
    border-right: 1px solid $table-cell-border;
  }

  & .cell:last-child {
    border-right: 0;
  }

  & h4.cell {
    font-size: 12px;
    font-weight: bold;
  }


  & .quote-item-style {
    box-sizing: border-box;
    grid-column: 1/8;
    max-height: 0;
    height: 0;
    overflow: hidden;
  }

  & input {
    width: 98%;
  }

  & .finished-good {
    &.active {
      background: #E0E0E0;
    }
  }

  & .quote-item {
    & .right-align {
      text-align: right;
      justify-content: flex-end;

      & .total {
        padding-right: 0.5em;
      }

      & input {
        text-align: right;
      }
    }

    & .action {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      & .action-panel {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .mat-icon {
          width: 18px;
          height: 18px;
          font-size: 18px;
          line-height: 18px;
        }

        .placeholder {
            display: inline-block;
            width: 18px;
            height: 18px;
          }

          & .drag-handle {
            cursor: move;
            cursor: grab;
          }

          & .drag-handle:active {
            cursor: grabbing;
          }

          & button {
            width: 18px;
            height: 18px;
            font-size: 18px;
            line-height: 18px;
            padding: 0px;
            display: inline-flex !important;
            justify-content: center;

            & > *[role=image] {
              width: 18px;
              height: 18px;
              font-size: 18px;
            }

            & .mat-mdc-button-touch-target {
              width: 24px;
              height: 24px;
            }
          }
        }

        & .line-number {
          font-weight: bold;

          & .number {
            display: inline-block;
            width: 18px;
            font-size: 18px;
            line-height: 18px;
            text-align: center;
          }
        }

      }

      & p, h4, div.cell {
        box-sizing: border-box;
        padding: 0.5em 0.25em;
        margin: 0;
        position: relative;
      }

      & h3.cell {
        border-right: 1px solid #CCC;
        padding-left: 0.25em;
      }
    }
}

.mat-mdc-icon-button {
  & .inlet {
    height: 18px;
    width: 18px;
    color: rgb(142, 142, 147);

    &.insert-at {
      &:hover {
        color: #000;
      }

      &-active, &-active:hover {
        color: rgb(255, 69, 58);
      }
    }
  }
}

.full-width-form-field {
  width: 100%;
}

.quote-action-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1em;
}

.composite-row {
  background: rgb(204,255,255);
}

.finished_good-row {
  background: rgb(255,255,204);
}

.enclosure-row {
  background: rgb(204,204,255);
}

.aggregate tbody .quote-item-row .description .mat-mdc-text-field-wrapper {
  padding-left: 32px;
}

.aggregate tbody .aggregate tbody .quote-item-row .description .mat-mdc-text-field-wrapper {
  padding-left: 64px;
}

.aggregate tbody .aggregate tbody .aggregate tbody .quote-item-row .description .mat-mdc-text-field-wrapper {
  padding-left: 96px;
}

.aggregate-row > td:first-child > table.aggregate:first-child {
  border-bottom: 2px dashed rgb(86,39,40);
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
  overflow: hidden;
}

.mdc-text-field--outlined {
  background: #FFF;
}

.inlet svg {
  width: 18px !important;
  height: 18px !important;
}

.nett-price-row {
  background: rgb(204,255,204);
}

#price-update-message {
  box-sizing: border-box;
  border: 2px solid #006ce0;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-weight: bold;
  padding: .25em .25em .25em 0;

  & span {
    display: inline-block;
    padding-left: 1em;
  }
}

#price-update-details {
  margin-top: 0.5em;
  padding-left: 1em;

  & .detail {
    box-sizing: border-box;
    padding: .25em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: rgb(255, 255, 186);
    }
  }
}

#price-update-message::before {
  content: ' ';
  position: absolute;
  top: 0;
  width: 0.666em;
  height: 100%;
  background-color: #006ce0;
}
