import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from '../../../core/api-gateway.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IQuoteExport } from '../templates/interfaces';

export interface IDocumentType {
  quote_document_type_id: string;
  type_name: string;
  description: string;
}

export interface IDocumentTypeFormGroup {
  type_name: FormControl<string>;
  description: FormControl<string>;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  gateway = inject(ApiGatewayService);

  constructor() { }

  add_document_type(document: Partial<IDocumentType>): Observable<IDocumentType> {
    return this.gateway.post<Partial<IDocumentType>, IDocumentType>('/documents/types', document);
  }

  list_doc_types(): Observable<IDocumentType[]> {
    return this.gateway.get<IDocumentType[]>('/documents/types');
  }

  create_document(new_document: Partial<IQuoteExport>): Observable<IQuoteExport> {
    return this.gateway.post<Partial<IQuoteExport>, IQuoteExport>('/documents', new_document);
  }

  get_print_link(doc_id: string): string {
    return this.gateway.makeurl(`/documents/${doc_id}/print`);
  }
}
