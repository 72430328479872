<div id="quote-grid-container">
<div id="quote-grid">
  <div class="configurator-column">
    <app-configurator
      (partSelected)="handlePartSelect($event)"
      [leaseContext]="leaseContext"
      [toolbaraction]="toolbaraction"
      [(reset)]="resetConfigurator"
      (stopComposite)="destroyCompositeContext($event)" style="position: sticky"></app-configurator>
  </div>

  <div id="quote" class="quote-column">
    <form [formGroup]="quoteFormGroup" (ngSubmit)="onSubmit()" *ngIf="quoteFormGroup">
      <div class="quote-details">
        <div class="section-header">
          <h4>Quote <span *ngIf="modified" style="font-size: 0.8em; vertical-align: text-top;">*</span></h4>
          <button mat-icon-button>
            <mat-icon>save</mat-icon>
          </button>
        </div>
        <div class="quote-header">
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Quote Reference</mat-label>
                <input matInput formControlName="quote_ref" (keydown)="checkQuoteRef($event)"/>
                <mat-spinner *ngIf="checkingRef" [diameter]="25" style="position: absolute; right: 0; top: 0.5em;"></mat-spinner>
                <mat-hint>Must start with 5 digits</mat-hint>
              </mat-form-field>
              <div class="error" *ngIf="quote_ref.errors?.['reference']">This reference already exists</div>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput formControlName="description" />
              </mat-form-field>
            </div>
          </div>
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Select Customer</mat-label>
                <app-selectcustomer formControlName="customer_id"
                  [required]="true"></app-selectcustomer>
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Paragraph</mat-label>
                <input matInput formControlName="paragraph" />
              </mat-form-field>
            </div>
          </div>
          <div class="quote-header-row">
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Consultant</mat-label>
                <input matInput formControlName="consultant" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Attn</mat-label>
                <input matInput formControlName="attention" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Salutation</mat-label>
                <input matInput formControlName="salutation" />
              </mat-form-field>
            </div>
            <div class="quote-header-item">
              <mat-form-field>
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="dtm_quote" />
                <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <table class="border-table">
        <tr class="header">
          <th class="cell"></th>
          <th class="cell">Description</th>
          <th class="cell">Qty</th>
          <th class="cell">Unit Price</th>
          <th class="cell">Markup</th>
          <th class="cell">Total</th>
          <th class="cell">Total inc. Markup</th>
        </tr>
        <!--<div class="quote-item-style solid-underline"></div>-->
        <tbody #dragBoundary
          cdkDropList
          (cdkDropListDropped)="drop($event, quoteItems)"
          [cdkDropListData]="quoteItems"
          cdkDropListLockAxis="y"
          class="quote-drag-boundary">
          @for (item of quoteItems.controls; track item; let idx = $index) {
          @if (item.value.item_part_type == 'nett_price') {
					<tr [item]="item"
              [quote]="quoteItems"
              quote-item-nett-price
              cdkDrag cdkDragBoundary=".quote-drag-boundary"
              class="quote-item-row quote-item nett-price-row">
          </tr>
          } @else {
					<tr [item]="item"
              quote-item-row
              cdkDrag cdkDragBoundary=".quote-drag-boundary"
                      class="quote-item-row quote-item"
                      [class]="getquoterowclass(item.value)">
          </tr>
          }}
        </tbody>

        <tr class="quote-item" *ngIf="grandTotal">
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell"></td>
          <td class="cell right-align" style="font-weight: bold;">{{ grandTotal }}</td>
          <td class="cell right-align" style="font-weight: bold;">{{ grandTotalIncMarkup }}</td>
        </tr>
        <!--<div class="quote-item-style solid-underline"></div>-->
      </table>

      <div class="quote-action-panel">
        <button mat-raised-button>{{ buttonText }}</button>
      </div>
    </form>
  </div>
</div>
</div>
