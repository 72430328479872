import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from '../../core/api-gateway.service';
import { Observable, of } from 'rxjs';
import { IManufacturer, Part, IPartGroup, ISupplier, IPart, IPartFormGroup, IComposite, IPartConfiguration } from './models';
import { IConfigurationCategory } from '../part-configuration/shared/models';

@Injectable({
  providedIn: 'root'
})
export class PartService {
  private gateway: ApiGatewayService = inject(ApiGatewayService);

  constructor() { }

  getManufacturers(): Observable<IManufacturer[]> {
    return this.gateway.get<IManufacturer[]>('/manufacturers');
  }

  getPartGroups(): Observable<IPartGroup[]> {
    return this.gateway.get<IPartGroup[]>('/parts/groups');
  }

  getPart(part_id: string): Observable<IPart> {
    return this.gateway.get<IPart>('/parts/' + part_id);
  }

  getComposite(composite_id: string): Observable<IComposite> {
    return this.gateway.get<IComposite>('/composites/' + composite_id);
  }

  getSuppliers(): Observable<ISupplier[]> {
    return this.gateway.get<ISupplier[]>('/suppliers');
  }

  getConfigurations(): Observable<IConfigurationCategory[]> {
    return this.gateway.get<IConfigurationCategory[]>(
      '/parts/configurations');
  }

  saveConfiguration(configuration: IConfigurationCategory) {
    return this.gateway.post<IConfigurationCategory, any>(
      '/parts/configurations',
      configuration
    );
  }

  updateConfiguration(configuration: IConfigurationCategory) {
    return this.gateway.post<IConfigurationCategory, any>(
      '/parts/configurations/update',
      configuration
    );
  }

  loadConfigurationProfile(category: string):
    Observable<IConfigurationCategory> {

    return this.gateway.get<IConfigurationCategory>(
      '/parts/configurations?c=' + category);
  }

  save(part: IPart) {
    let action = '/parts';
    if (part.part_id) {
      action = `${action}/edit`;
    }

    return this.gateway.post<IPart, any>(action, part);
  }

  saveComposite(composite: IComposite) {
    let action = '/composites';
    if (composite.composite_id) {
      action = `${action}/edit`;
    }

    return this.gateway.post<IComposite, any>(action, composite);
  }
}
