import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { QuoteHeaderComponent } from '../../quote-header/quote-header.component';
import { MatInputModule } from '@angular/material/input';
import { DocumentService, IDocumentType, IDocumentTypeFormGroup } from '../document.service';

@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    QuoteHeaderComponent
  ],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss'
})
export class DocumentsComponent implements OnInit {
  service: DocumentService = inject(DocumentService);

  fg_document_type: FormGroup<IDocumentTypeFormGroup>;
  categories: IDocumentType[] = [];

  constructor() {
    this.fg_document_type = this.make_document_type_formgroup();
  }

  ngOnInit() {
    this.service.list_doc_types().subscribe(documents => {
      this.categories = documents;
    });

  }

  make_document_type_formgroup(): FormGroup<IDocumentTypeFormGroup> {
    return new FormGroup({
      'type_name': new FormControl<string>('', {nonNullable: true,
                                           validators: [Validators.required]}),
      'description': new FormControl<string>('', {nonNullable: true,
                                             validators: [Validators.required]})
    });
  }

  add_document_type() {
    if (this.fg_document_type.valid) {
      let new_category = this.fg_document_type.value;
      this.fg_document_type = this.make_document_type_formgroup();

      this.service.add_document_type(new_category).subscribe(new_document => {
        this.categories.push(new_document);
      });
    }
  }

}
