import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { QuoteService } from '../../shared/quote.service';
import { IQuoteListView } from '../shared/interfaces';
import { CommonModule } from '@angular/common';
import { SummaryChartComponent } from '../../../views/summary-chart/summary-chart.component';
import { SummaryTableComponent } from '../../../views/summary-table/summary-table.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { QuoteHotJobComponent } from '../quote-hot-job/quote-hot-job.component';
import { DropdownFilterComponent } from '../../../controls/data-collation/dropdown-filter/dropdown-filter.component';
import { DataCollationService } from '../../../core/data-collation.service';
import { DataCollationEvent } from '../../../controls/data-collation/shared/interfaces';
import { SorterComponent } from '../../../controls/data-collation/sorter/sorter.component';
import { DateFilterComponent } from '../../../controls/data-collation/date-filter/date-filter.component';
import { QuoteHeaderComponent } from '../quote-header/quote-header.component';

@Component({
  selector: 'app-quote-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    SummaryChartComponent,
    SummaryTableComponent,
    MatTooltipModule,
    MatMenuModule,
    QuoteHotJobComponent,
    DropdownFilterComponent,
    SorterComponent,
    DateFilterComponent,
    QuoteHeaderComponent
  ],
  providers: [
    DataCollationService
  ],
  templateUrl: './quote-list.component.html',
  styleUrl: './quote-list.component.scss'
})
export class QuoteListComponent implements OnInit {
  private service: QuoteService = inject(QuoteService);
  private collationservice = inject(DataCollationService);
  filters: any = [];

  quotes!: IQuoteListView[];
  summaries_to_show: string[] = [];

  ngOnInit(): void {
      this.service.loadquotes().subscribe(quotes => {
        this.quotes = quotes;
      });

      this.collationservice.events.subscribe((e: DataCollationEvent) => {
        this.service.loadquotes(e.collation).subscribe(quotes => {
          this.quotes = quotes;
        });
      });

      this.service.loadFilters().subscribe(filters => {
        console.log(filters);
        this.filters = filters;
      });
  }

  toggleSummary(quote_id: string) {
    if (this.summaries_to_show.includes(quote_id)) {
      this.summaries_to_show = this.summaries_to_show.filter(idtocheck => {
        return idtocheck != quote_id;
      });
    } else {
      this.summaries_to_show.push(quote_id);
    }
  }

  showSummary(quote_id: string) {
    return this.summaries_to_show.includes(quote_id);
  }

  resetfilters() {
    this.collationservice.reset();
  }

  downloadlink(quoteid: string) {
    return this.service.downloadlink(quoteid);
  }

}
