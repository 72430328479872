import { inject, Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  gateway: ApiGatewayService = inject(ApiGatewayService);
  partialJSONBuffer: string = "";

  constructor() { }

  makeRequest(action: string) {
    return this.gateway.endpoint + '/stream/' + action;
  }

  search(searchQuery: string): Subject<any|null> | undefined {
    if (searchQuery == '') {
      return;
    }

    const url = this.makeRequest("search?q=" + encodeURIComponent(searchQuery));
    let searchSubject = new Subject<any>();

    const req = new Request(url);
    fetch(req, {credentials: "include"})
    .then((response) => {
      const reader = response.body?.getReader();
      var that = this;
      return new ReadableStream({
        start(controller) {
          return readChunk();
          function readChunk(this: any): any {
            return reader?.read().then(({done, value}) => {
              if (done) {
                controller.close();
                searchSubject.next(null);
                return;
              }

              const decoder = new TextDecoder();
              let resp = decoder.decode(value);
              let partsChunk = that.appendParts(resp);
              searchSubject.next(partsChunk);
              return readChunk();
            });
          }
        }
      });
    });

    return searchSubject;
  }

  appendParts(parts: any) {
    let container: any[] = [];
    let split_parts = parts.split("\r\n");

    if (split_parts.length > 0) {
      let num_chunks = split_parts.length;

      for (let i = 0; i < num_chunks; i++) {
        if (split_parts[i] == "") continue;

        if (split_parts[i].at(-1) != "]") {
          this.partialJSONBuffer += split_parts[i];
          continue;
        }

        if (split_parts[i].charAt(0) != "[") {
          if (this.partialJSONBuffer != "") {
            if (split_parts[i].at(-1) == "]") {
              this.partialJSONBuffer += split_parts[i];
              container = container.concat(JSON.parse(this.partialJSONBuffer));
              this.partialJSONBuffer = "";
              continue;
            }
          }

          this.partialJSONBuffer += split_parts[i];
          continue;
        }

        if (split_parts[i] != "") {
          container = container.concat(JSON.parse(split_parts[i]));
        }
      }
    }

    return container;
  }

}
