<app-quote-header>
  <a mat-fab extended routerLink="/quotes/templates/quote-templates/add">
    <mat-icon>add</mat-icon>
    Document Template
  </a>
</app-quote-header>
<table>
  <thead>
    <tr>
      <th>Name</th>
      <th></th>
    </tr>
  </thead>
  <tbody *ngIf="no_templates">
    <tr>
      <td colspan="2" align="center">
        <p>No Document Templates Defined</p>
        <a mat-fab extended routerLink="/quotes/templates/quote-templates/add">
          <mat-icon>add</mat-icon>
          Document Template
        </a>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="!no_templates">
  @for (template of templates; track template.document_template_id) {
    <tr>
      <td>{{ template.template_name }}</td>
      <td>
        <a mat-icon-button [routerLink]="getEditLink(template.document_template_id)">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </tr>
  }
  </tbody>
</table>
