<div class="two-column">
  <div class="column-shrink" style="min-width: 400px;">
    <app-configurator [showToolbar]="false"
      [showComposite]="false"
      [showPartFilter]="false"
      (partSelected)="onPartSelect($event)"></app-configurator>
  </div>
  <div class="column">
    <form [formGroup]="compositeFormGroup"
      (submit)="editComposite()"
      *ngIf="compositeFormGroup"
      class="data-form data-form-full-width">
      <mat-form-field>
        <mat-label>Composite Reference</mat-label>
        <input matInput formControlName="composite_reference" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="composite_description"></textarea>
      </mat-form-field>
      <div class="data-form-section-header">
        <h4>Composite Parts</h4>
      </div>
      <div class="composite-part multi-field">
        <div class="multi-field-row multi-field-row-vcenter" *ngFor="let part of compositeParts.controls" [formGroup]="part">
          <div class="multi-field-row-item-item-shrink">
            <mat-icon>drag_indicator</mat-icon>
          </div>
          <div class="multi-field-row-item-shrink" style="padding: 0.5em;">
            <span class="number-circle">{{ part.controls.position.value }}</span>
          </div>
          <div class="multi-field-row-item" style="padding: 0.5em">
            {{ descriptions[part.controls.part_id.value] }}
          </div>
          <div class="multi-field-row-item-shrink">
            <mat-form-field appearance="outline">
              <mat-label>Quantity</mat-label>
              <input matInput type="number" formControlName="quantity" />
            </mat-form-field>
          </div>
          <div class="multi-field-row-item-item-shrink" style="padding: 0.5em">
            <mat-icon>delete</mat-icon>
          </div>
        </div>
      </div>

      <div class="form-action-right">
        <button mat-raised-button>{{ buttonText }}</button>
      </div>
    </form>
  </div>
</div>
