<button id="button-on"
  type="button"
  class="button"
  *ngIf="lump_sum"
  (click)="toggle()">
    <span class="overlay shine">
        <span class="shine-blotch"></span>
    </span>
    <span class="overlay shade top-shade"></span>
    £
    <span class="overlay shade bottom-shade"></span>
    <span class="black-dot tl"></span>
    <span class="black-dot tr"></span>
    <span class="black-dot bl"></span>
    <span class="black-dot br"></span>
</button>

<button id="button-off"
  type="button"
  class="button"
  *ngIf="!lump_sum"
  (click)="toggle()">
    <span class="overlay shine">
        <span class="shine-blotch"></span>
    </span>
    <span class="overlay shade top-shade"></span>
    <span class="overlay shade right-shade"></span>
    %
    <span class="overlay shade bottom-shade"></span>
    <span class="black-dot tl"></span>
    <span class="black-dot tr"></span>
    <span class="black-dot bl"></span>
    <span class="black-dot br"></span>
</button>

