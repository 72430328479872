<app-part-header></app-part-header>
<table id="product-manager">
  <colgroup>
    <col style="width: 200px;" />
    <col style="width: 100%;" />
    <col style="width: 150px;" />
    <col style="width: 100px;" />
    <col style="width: 80px;" />
  </colgroup>
  <thead>
    <tr>
      <td colspan="5">
        <div class="filter-console">
          <div class="header-item search-container">
            <input type="text" [(ngModel)]="partSearchQuery" (keyup.enter)="searchEnterPressed()" />
            <button type="button" (click)="search()">
              <mat-icon class="icon">search</mat-icon>
            </button>
          </div>
          <div class="header-item">
            <select>
              <option value="">Select Group ...</option>
            </select>
          </div>
          <div class="header-item">
            <select>
              <option value="">Select Manufacturer ...</option>
            </select>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>Reference</th>
      <th>Description</th>
      <th>Group</th>
      <th>Price</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody *ngIf="!this.searching">
    @for (part of dataSource(); track part.id) {
      <tr>
        <td>{{ part.ref }}</td>
        <td>{{ part.description }}</td>
        <td>{{ part.part_group_ref }}</td>
        <td class="right-align">{{ part.part_price }}</td>
        <td class="action-panel-container">
          <div class="action-panel">
            @if (part.type == 'composite') {
              <a mat-icon-button type="button" [routerLink]="['/parts/composites', part.id]"><mat-icon>edit</mat-icon></a>
            } @else {
              <a mat-icon-button type="button" [routerLink]="['/parts', part.id]"><mat-icon>edit</mat-icon></a>
            }
            <button mat-icon-button type="button" (click)="delete(part.id)"><mat-icon>delete</mat-icon></button>
          </div>
        </td>
      </tr>
    }
  </tbody>
  <tbody *ngIf="this.searching">
    <tr>
      <td colspan="5">
        <mat-spinner></mat-spinner>
      </td>
    </tr>
  </tbody>
  <tfoot *ngIf="!this.searching">
    <td colspan="5">
      <div class="footer-row">
        <div class="item showing-message">
          {{ showingMessage }}
        </div>
        <div class="item paginator">
          <div class="paginator">
            <select (change)="setPageSize($event)">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <div class="pages">
              <button type="button" class="previous-page" (click)="prevPage()" [disabled]="isFirstPage">Previous</button>
              <span class="page" (click)="setPage(1)" [ngClass]="isActivePage(1) ? 'active-page' : ''">1</span>
              <span *ngIf="curPage >= 5" class="ellipses">...</span>
              <span *ngFor="let page of paginationPages" class="page" (click)="setPage(page)" [ngClass]="isActivePage(page) ? 'active-page' : ''">{{ page }}</span>
              <span *ngIf="curPage <= (totalPages-5)" class="ellipses">...</span>
              <span class="page" (click)="setPage(totalPages)" [ngClass]="isActivePage(totalPages) ? 'active-page' : ''">{{ totalPages }}</span>
              <button type="button" class="next-page" (click)="nextPage()" [disabled]="isLastPage">Next</button>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tfoot>
</table>

